import { useEffect } from "react";
import { useState } from "react";
import { API_BASE } from "../../constants/global";
import { Link, useLocation, useParams } from "react-router-dom";
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, PinterestShareButton, PinterestIcon } from 'react-share';

const CourseCertificate = () => {
    const _token = localStorage.getItem('lmstoken');
    const { _slug } = useParams();

    const [certificate, setCertificate] = useState('');
    const [share_text, setShareText] = useState('');

    useEffect(() => {
        fetchCertificate();
    }, []);

    const fetchCertificate = async () => {
        const response = await fetch(`${API_BASE}get-course-certificate/${_slug}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`

            },
        });

        const data = await response.json();
        if (data.status) {
            setCertificate(data.data.certificate);
            setShareText(`Hello Friends, Checkout this course "${data.data.certificate.course_name}" I've just completed from EduKula.`);
        } else {
            setCertificate();
        }
    }

    return (
        <>
            {
                certificate !== ''
                &&
                <div className="col-lg-9 home-section">
                    <div className="custom-user-profile-content custom-learning-circle-content">
                        <div className="certificate_section">
                            <div className="certi_img">
                                {
                                    certificate?.image_file !== ''
                                        ?
                                        <img src={`${certificate.image_file}`} alt="" srcset="" />
                                        :
                                        <embed src={`${certificate.pdf_file}?zoom=50`} width="100%" height="650" contr type="application/pdf" />
                                }
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mb-3">
                                    <p>This certificate above verifies that <strong>{certificate.student}</strong> successfully completed the course <a className="link">{certificate.course_name}</a> on {certificate.created_at} as taught by <a className="link">{certificate.instructor}</a> on EduKula. The certificate indicates the entire course was completed as validated by the student. The course duration represents the total video hours of the course at time of most recent completion.</p>
                                </div>
                                <div className="col-lg-6">
                                    <h5>About the Course</h5>
                                    <div className="blog-card-detail">
                                        <div className="custom-card-img">
                                            <img src={certificate.course_image} className="card-img-top" alt="Blog image" />
                                        </div>
                                        <div className="blog-card-inner">
                                            <h5>{certificate.course_name}</h5>
                                            <ul className="course_tabs_sub">
                                                <li><a><i className="fas fa-list"></i><span>{certificate.category}</span></a></li>
                                                <li><a><i className="far fa-user"></i><span>{certificate.mode}</span></a></li>
                                                <li><a><i className="far fa-play-circle"></i><span>Completed</span></a></li>
                                            </ul>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <h5>Certificate Recipient</h5>
                                    <div className="user-header">
                                        <div className="user-profile-img">
                                            <img src={certificate.profile} className="rounded-circle" alt="Profile Image" />
                                        </div>
                                        <div className="user-details-title">
                                            <h6>{certificate.student}</h6>
                                            {/* <p>Quinton Batchelor</p> */}
                                        </div>
                                    </div>
                                    <div className="ml-3">
                                        <h6>Share Via : </h6>
                                        <FacebookShareButton url={certificate?.image_file !== '' ? certificate.image_file : certificate.pdf_file} quote={share_text} hashtag="#edukula">
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                        <LinkedinShareButton url={certificate?.image_file !== '' ? certificate.image_file : certificate.pdf_file} quote={share_text} hashtag="#edukula" className="ml-1">
                                            <LinkedinIcon size={32} round />
                                        </LinkedinShareButton>
                                        {/* <PinterestShareButton url={certificate?.image_file !== '' ? certificate.image_file : certificate.pdf_file} quote={share_text} hashtag="#edukula" className="ml-1">
                                            <PinterestIcon size={32} round />
                                        </PinterestShareButton> */}
                                    </div>
                                    <br />

                                    <div className="dropdown">
                                        <button className="download_btn dropdown-toggle" type="button" data-toggle="dropdown"><i className="fas fa-download"></i> Download <span className="caret"></span></button>
                                        <ul className="dropdown-menu pl-3">
                                            {certificate?.image_file !== '' ? <li><a href={certificate?.image_file} className="download pr-5" target="_blank">JPEG</a></li> : null}
                                            {certificate?.pdf_file !== '' ? <li><a href={certificate?.pdf_file} className="download pr-5" target="_blank">PDF</a></li> : null}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix"></div>

                    </div>
                </div>
            }
        </>
    );
}

export default CourseCertificate;
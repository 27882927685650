import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useEffectOnce from "../../chat/useEffectOnce";
import { socket } from "../../constants/global";
import authContext from "../../context/auth/authContext";
import { Toaster } from "../../entity/GlobalJS";
import Cookies from "js-cookie";
const Logout = () => {
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    if(user){
        socket.emit('logout',{userId:user.id});
    }
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    localStorage.setItem('lmsuser', JSON.stringify({}));
    localStorage.removeItem('lmstoken');
    localStorage.removeItem('lmsuser');
    localStorage.removeItem('lrsuser');
    localStorage.removeItem('profile');
    localStorage.removeItem('learningCircleUniqueId');
    localStorage.removeItem('enrolling');
    localStorage.removeItem('couid');
    localStorage.removeItem('uc');
    localStorage.removeItem('no_of_time_login');
    localStorage.removeItem('has_auto_password');
    useEffect(() => {
        cntxAuth.updateLogin(false);
        navigate("/login");    
        //eslint-disable-next-line
    }, []);
    useEffectOnce(() => {
        Cookies.set('show_tour', '--');
        Toaster.success("You are successfully logged out.");
    })
    
    return (
        <></>
    );
}

export default Logout;
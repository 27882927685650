import { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Links = (props) => {
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const is_tutor = props?.is_tutor;

    return (
        <>
            <NavLink className={'nav-link '} id="v-pills-general-tab" to={'/profile/general'} role="tab" aria-controls="v-pills-general" aria-selected="true"><i className="far fa-user"></i> General Profile</NavLink>

            {
                user?.is_corporate === 1
                    ?
                    <>
                        <NavLink className={'nav-link '} id="v-pills-profile-picture-tab" to={'/profile/profile-picture'} role="tab" aria-controls="v-pills-profile-picture" aria-selected="false"><i className="far fa-user-circle"></i>Company Logo</NavLink>
                    </>
                    :
                    <>
                        <NavLink className={'nav-link '} id="v-pills-profile-picture-tab" to={'/profile/profile-picture'} role="tab" aria-controls="v-pills-profile-picture" aria-selected="false"><i className="far fa-user-circle"></i>Profile Picture</NavLink>
                        {
                            user?.career_type === 'Working Professional'
                                ?
                                <NavLink className={'nav-link '} id="v-pills-experience-tab" to={'/profile/experience'} role="tab" aria-controls="v-pills-experience" aria-selected="false"><i className="fab fa-black-tie"></i>Experience</NavLink>
                                :
                                null
                        }
                        <NavLink className={'nav-link '} id="v-pills-education-tab" to={'/profile/education'} role="tab" aria-controls="v-pills-education" aria-selected="false"><i className="fas fa-graduation-cap"></i>Education</NavLink>
                        <NavLink className={'nav-link '} id="v-pills-learning-tab" to={'/profile/learning-preferences'} role="tab" aria-controls="v-pills-learning" aria-selected="false"><i className="far fa-id-badge"></i>Learning Preferences</NavLink>
                        {
                            (is_tutor !== "undefined" && is_tutor === 1)
                            &&
                            <NavLink className={'nav-link '} id="v-pills-mentor-tab" to={'/profile/mentor-preferences'} role="tab" aria-controls="v-pills-mentor" aria-selected="false"><i className="fas fa-id-card-alt"></i>Mentor Preferences</NavLink>
                        }
                    </>
            }
            <NavLink className={'nav-link '} id="v-pills-ai-tab" to={'/profile/responsible-ai'} role="tab" aria-controls="v-pills-ai" aria-selected="false"><i className="fas fa-brain"></i>Responsible AI</NavLink>
            <NavLink className={'nav-link '} id="v-pills-kyc-tab" to={'/profile/e-kyc'} role="tab" aria-controls="v-pills-kyc" aria-selected="false"><i className="fas fa-id-badge"></i>eKYC</NavLink>
            <NavLink className={'nav-link '} id="v-pills-account-tab" to={'/profile/security'} role="tab" aria-controls="v-pills-account" aria-selected="false"><i className="fas fa-user-lock"></i>Account and Security</NavLink>
        </>
    );
}

export default Links;
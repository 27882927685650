import { useEffect } from 'react';
import { useState } from 'react';
import FeedCard from './FeedCard';
import { PostPhotoPopup, PostVideoPopup, PostPollPopup } from './index';
import { API_BASE } from '../../../constants/global';
import { useOutletContext, useParams } from 'react-router-dom';
import { Toaster } from '../../../entity/GlobalJS';
import { useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import AboutCard from '../AboutCard';
import VerifiedBadge from '../../../components/VerifiedBadge';

const CircleFeed = () => {
    const _token = localStorage.getItem('lmstoken');
    const [group] = useOutletContext();
    const { unique_id } = useParams();
    const [caption, setCaption] = useState('');
    const [feeds, setFeeds] = useState('');
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const profile = user.image;
    const [newElements, setNewElements] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const goToTop = () => {
        window.scrollTo(0, 0);
    }

    const scrollBarStyle = {
        border: '1px solid red',
        width: '400px',
        height: '208px',
    };

    useEffect(() => {
        fetchFeeds();
        window.addEventListener("scroll", () => {
            const filterMenuEl = document.getElementById("post-area-start");
            if (typeof(filterMenuEl) !== 'undefined' && filterMenuEl !== null) {
                const filterMenu = filterMenuEl.getBoundingClientRect();

                if (typeof filterMenu !== 'undefined' && filterMenu !== null) {
                    if (filterMenu.top < -400) {
                        document.getElementById("backToTopID").classList.remove("hidden");
                    } else {
                        if (!document.getElementById("backToTopID").classList.contains('hidden')) {
                            document.getElementById("backToTopID").classList.add("hidden");
                        }
                    }
                }
            }
        });
        //eslint-disable-next-line
    }, []);

    const fetchFeeds = async () => {
        const response = await fetch(`${API_BASE}get-all-feeds/${unique_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            var _current_u_info     = JSON.parse(localStorage.getItem('lmsuser')); 
            var _current_u_id       = _current_u_info.id;
            var _current_u_email    = _current_u_info.email;
            var _feed_data          = data.data.feeds;
            var _updated_feed_data = [];
            _feed_data.forEach(function(i) { 
                if(i.is_moderated == "NO" && i.creator.email == _current_u_email){
                    i.is_moderated = 'YES';
                }
                _updated_feed_data.push(i);
            });
            setFeeds(_updated_feed_data);
        } else {
            setFeeds('');
        }
    }

    const feedPosted = (feed) => {
        var _current_u_info     = JSON.parse(localStorage.getItem('lmsuser')); 
        var _current_u_id       = _current_u_info.id;
        var _current_u_email    = _current_u_info.email;
        if(feed.creator.email == _current_u_email){
            feed.is_moderated = 'YES';
        }
        setNewElements([<FeedCard key={feed.id} feed={feed} user={user} profile={profile} />, ...newElements]);
    }

    const savePost = async (form) => {
        if (caption !== '') {
            const formData = new FormData();
            formData.append('group_id', unique_id);
            formData.append('post_type', 0);
            formData.append('caption', caption);

            setSubmitting(true);
            const response = await fetch(`${API_BASE}post-create-feed`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${_token}`
                },
                body: formData
            });

            const data = await response.json();
            if (data.status) {
                feedPosted(data.data.feed);
                Toaster.success(data.message);
                document.getElementById('close_popup_photo').click();
                setCaption('');
                setValue('caption', '');
                setSubmitting(false);
            } else {
                setCaption('');
                setValue('caption', '');
                Toaster.error(data.message);
                setSubmitting(false);
            }
        }
    }

    return (
        <>
            <section className={`custom-section feed_page`}>
                <div className="profile_user_middle_section taka_nanu" id='post-area-start'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="feed_page">
                                    <div className="custom-user-profile-content custom-learning-circle-content post_box_top">
                                        <div className="custom-learnig-circle-head">
                                            <div className="prof_img">
                                                <img src={profile} alt={user.first_name} />
                                            </div>
                                            <div className="form-group">
                                                <form onSubmit={handleSubmit(savePost)}>
                                                    <div className='textbox_scroll'>
                                                        <TextareaAutosize id="caption" {...register("caption", {
                                                            required: "required",
                                                            maxLength: {
                                                                value: 2000,
                                                                message: "Caption can not be more than 2000 charaters."
                                                            }
                                                        })} maxRows={8} type="text" onChange={(e) => { setCaption(e.target.value); }} className="form-control" placeholder="Start a Post" value={caption ? caption : ''} />
                                                    </div>
                                                    <span className="add-on max-chr-counter chrchk-caption" data-ele="caption" data-limit="2000" data-showcounter="true"></span>
                                                    <span className="text-danger">
                                                        {errors.caption && errors.caption.type === "required" && 'Please enter comment'}
                                                        {errors.caption && errors.caption.type !== "required" && errors.caption.message}
                                                    </span>
                                                    <div>
                                                        <button disabled={submitting} type='submit' className='btn btn-circle btn-info mt-3 mb-0 ek-lrs-interaction-shared' data-ek-lrs-title={`${group.title}`} data-ek-lrs-url={`/view-learning-circle/${unique_id}/feeds`}>POST</button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="posticon_box" style={{ marginTop: "0px" }}>
                                                <ul>
                                                    <li>
                                                        <span className="link" data-toggle="modal" data-target="#photoModalCenter"><i className="fas fa-camera"></i> Photo</span>
                                                    </li>
                                                    <li>
                                                        <span className="link" data-toggle="modal" data-target="#videoModalCenter"><i className="fas fa-video"></i> Video</span>
                                                    </li>
                                                    <li>
                                                        <span className="link" data-toggle="modal" data-target="#pollModalCenter"><i className="fas fa-poll-h"></i> Poll</span>
                                                    </li>
                                                </ul>

                                                <PostPhotoPopup feedPosted={feedPosted} profile={profile} user={user} caption={caption} setCaption={setCaption} />
                                                <PostVideoPopup feedPosted={feedPosted} profile={profile} user={user} caption={caption} setCaption={setCaption} />
                                                <PostPollPopup feedPosted={feedPosted} profile={profile} user={user} />
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>

                                <div className="list_post_feeds" id="list_post_feeds">

                                </div>

                                {newElements}

                                {
                                    feeds !== ''
                                    &&
                                    feeds.map((feed, index) =>
                                        <FeedCard key={index} feed={feed} user={user} profile={profile} />
                                    )
                                }

                                {
                                    feeds === '' && newElements.length <= 0
                                    &&
                                    <div className='empty_state'>
                                        <img alt="" src="images/no_feeds.png" />
                                        <h3>No feeds posted for this circle.</h3>
                                    </div>
                                }
                            </div>

                            <div className="col-lg-4">
                                <AboutCard group={group} />
                            </div>
                        </div>
                    </div>
                </div>

                <button onClick={goToTop} id="backToTopID" className='btn btn-circle btn-info back-to-top-button'><i className="fa fa-arrow-up" aria-hidden="true"></i></button>
            </section>
        </>
    );
}

export default CircleFeed;
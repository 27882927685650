import React, { useContext, useEffect, useState } from "react";
import ChatUserBox from "../../chat/chat_user_box";
import useEffectOnce from "../../chat/useEffectOnce";
import { API_BASE, socket } from "../../constants/global";
import authContext from '../../context/auth/authContext';
import ChatUserListSkeleton from "../../components/skeletons/ChatUserListSkeleton";

const _token = localStorage.getItem('lmstoken');

const LearningCircleChat = () => {
	const userData = JSON.parse(localStorage.getItem('lmsuser'));
	const [chatBox, setUserChatBox] = useState([]);
	// const [userData, setUserData] = useState('');
	const [chatUserData, setChatUserData] = useState([]);
	const [isActive, setActive] = useState("false");
	const crossBtnHide = true;
	const [onlineUserIds, setOnlineUser] = useState([]);
	const [searchField, setSearchField] = useState("");
	const [isLoader, setIsLoader] = useState(true);

	if (userData) {
		socket.emit('login', { userId: userData.id });
	}

	useEffectOnce(() => {
		getChatUserData();
	}, []);

	// GET USER CHAT BOX AND APPEND ON ARRAY
	const getUserChatBox = (user_id, encrypted_id, userimage, user_name, is_admin, socket) => {
		var unique_id = (parseInt(userData.id) > parseInt(user_id)) ? user_id + '_' + userData.id : userData.id + '_' + user_id;
		const isFound = chatBox.some(element => {
			if (element.key == unique_id) {
				return true;
			}
			return false;
		});
		if (!isFound) {
			setUserChatBox([
				{
					key: unique_id,
					sender_id: userData.id,
					userimage: userimage,
					encrypted_id: encrypted_id,
					user_id: user_id,
					user_name: user_name,
					is_admin: is_admin,
					socket: socket,
					join_room: unique_id,
					unique_id: unique_id,
					cross_button_hide: crossBtnHide,
					value: <React.Fragment key={unique_id}><ChatUserBox key={unique_id} sender_id={userData.id} userimage={userimage} encrypted_id={encrypted_id} user_id={user_id} removeChatBox={removeChatBox} user_name={user_name} is_admin={is_admin} socket={socket} join_room={unique_id} cross_button_hide={crossBtnHide} /></React.Fragment>
				}
			]);
		}
	}

	socket.once("connect", () => {
		socket.once("online", (userId) => {
			// update online status
			setOnlineUser(userId);
		});

		// USER IS OFFLINE
		socket.once("offline", (userId) => {
			// update offline status
			setOnlineUser(userId);
		});
	});

	// REMOVE OPENED CHAT BOX
	const removeChatBox = (e) => {
		const id = e.target.getAttribute("id");
		setUserChatBox(chatBox.filter(item => item.key != id));
	};

	// GET ALL CHAT USERS
	const getChatUserData = async (param = "") => {
		if (param != "") {
			param = '/' + param;
		}
		setIsLoader(true)
		const response = await fetch(API_BASE + 'get-all-chat-users' + param, {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${_token}`
			},
		});

		const data = await response.json();
		setIsLoader(false);
		if (data.status) {
			setChatUserData(data.data);
		} else {
		}
	}

	// HANDLE TOOGLE FOT CHATBOX
	const handleToggle = () => {
		setActive(!isActive);
	};

	const handleSearchChange = e => {
		setSearchField(e.target.value);
		getChatUserData(e.target.value);
	};

	return (
		<>
			<div className="col-lg-9 home-section">
				<div className="custom-user-profile-content custom-learning-circle-content chatPage">
					<div className="custom-learnig-circle-head">
						<h4>Chat</h4>
					</div>
					<div className="chatbox">
						<div className="chat_list">
							<div className="people-list" id="people-list">
								<div className="search">
									<input type="text" placeholder="Search" onChange={handleSearchChange} />
									<i className="fa fa-search"></i>
								</div>
								{
									isLoader ?
									<>
									<ChatUserListSkeleton count={7} />
									</>
									:
									<>
									<ul className="list">
									{
										(typeof chatUserData !== 'undefined' && chatUserData.length > 0) ?
											chatUserData.map(userdata =>
												<li className="clearfix" key={userdata.id}>
													<a className="link" onClick={(e) => { getUserChatBox(userdata.id, userdata.encrypted_id, userdata.image, userdata.name, userdata.is_admin, socket) }}>
														<div className="prof_pic">
															<img src={userdata.image} alt="avatar" />
														</div>
														<div className="about">
															<div className="name">{userdata.name} {userdata.is_admin == 1 ? <i className="fas fa-user-alt"></i> : ""}</div>
															<div className="status">
																{onlineUserIds.includes(userdata.id) ? <span><i className="fa fa-circle online"></i> online</span> : <span><i className="fa fa-circle offline"></i> offline</span>}
															</div>
														</div>
													</a>
												</li>
											)
										:
										""
									}
									</ul>
									</>
								}
							</div>
						</div>
						<div className="user_chat_box user_chat_box2">
							{chatBox.map(d => <React.Fragment key={d.unique_id}><ChatUserBox key={d.unique_id} setUserChatBox={setUserChatBox} chatBox={chatBox} sender_id={d.sender_id} userimage={d.userimage} encrypted_id={d.encrypted_id} user_id={d.user_id} removeChatBox={removeChatBox} user_name={d.user_name} is_admin={d.is_admin} socket={d.socket} join_room={d.unique_id} cross_button_hide={d.cross_button_hide} /></React.Fragment>)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default LearningCircleChat;
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useEffect, useState } from "react";
import QueryBoxSkeleton from '../../../../components/skeletons/QueryBoxSkeleton';
import { API_BASE, OPENAI_KEY } from "../../../../constants/global";
import { SwalBox, Toaster } from "../../../../entity/GlobalJS";
import QueryCard from "./QA/QueryCard";
import { Configuration, OpenAIApi } from "openai";



const QA = ({ course_title, course_id, manage }) => {
    const _token = localStorage.getItem('lmstoken');
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const [query, setQuery] = useState('');
    const [fetching, setFetching] = useState(true);
    const storedApiKey        = OPENAI_KEY;
    const [ openai, setOpenai] = useState(new OpenAIApi(new Configuration({ apiKey: storedApiKey })));
    const [queries, setQueries] = useState('');

    useEffect(() => {
        fetchQueries(0);
    }, []);

    const fetchQueries = async (search = '') => {
        setFetching(true);
        const response = await fetch(`${API_BASE}get-queries-list/${course_id}?search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status && data.data.comments.queries !== null) {
            setQueries('');
            setTimeout(() => {
                setQueries(data.data.comments.queries);
            }, 500);
            setFetching(false);
        } else {
            setFetching(false);
        }
    }
    const saveReply = async () => {
        const formData = new FormData();
        formData.append('course_id', course_id);
        
        formData.append('comment', query);

        const response = await fetch(`${API_BASE}post-course-query`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            fetchQueries(0);
            setQuery('');
            setFetching(false);
            let newMessage = query;
            let _default_prompt = 'I want you to act as my virtual tutor. I will initiate chat with you. I may ask you queries related to different topics and you will explain or answer me based on my queries. You will need to analise my reply/statement first and answer me according to the type of my statement i.e whether it is a question or just a sentense. Please keep your reply as short as possible.';
            let _default_course = 'Currently I am learning '+course_title+ '\n Here is my query :';
            let _request_prompt = _default_prompt +'\n'+_default_course+'\n'+newMessage+'.';
            try {
                openai.createCompletion({
                    model: "text-davinci-003",
                    prompt: _request_prompt,
                    stop: "Stop",
                    max_tokens: 4097-(_request_prompt.length),
                    temperature: 0.1,
                }).then((completion) => {
                    const responseText = completion.data.choices[0].text;
                    const formData = new FormData();
                    formData.append('course_id', course_id);
                    formData.append('parent_id', data.data.comment.id);
                    formData.append('comment', responseText+' I hope that this resolves your query. In case you have any further query, please ask your virtual tutor.' );
                    formData.append('bot', 'YES');
                    const response_bot = fetch(`${API_BASE}post-course-query`, {
                        method: 'POST',
                        headers: {
                            "Authorization": `Bearer ${_token}`
                        },
                        body: formData
                    });
                    fetchQueries(0);
                    // setNewQueries([data.data.comment, ...new_queries]);
                }).catch((error) => {
                    SwalBox.fire({
                        title: 'Something went wrong',
                        text: 'Please try again after some time.',
                        icon: 'error',
                    });
                });
            }catch(err) { }

        } else {
            Toaster.error(data.message);
        }
    }

    const addEmoji = (e) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        setQuery(query + emoji);
    };

    return (
        <>
            <div className="tab-pane fade show active" id="pills-qanda" role="tabpanel" aria-labelledby="pills-qanda-tab">
                {
                    <div className="row justify-content-lg-center">
                        <div className="col-md-12 col-lg-10">
                            {
                                !manage
                                &&
                                <div className='feed_page'>
                                    <div className="feed_post new_area_fix">
                                        <div className="feed_comment p-0">
                                            <div className="own_coment p-0">
                                                <div className="prof_img">
                                                    <img src={user.image} alt={user.first_name} />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        onChange={(e) => { setQuery(e.target.value) }}
                                                        type="text"
                                                        className="form-control textboxdesign"
                                                        placeholder="Write your query..."
                                                        value={query}
                                                        onKeyUp={(e) => { if (e.key === 'Enter') { saveReply(); } }}
                                                    />
                                                    <i id={`${course_id}_MenuButtonEmoji`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="far fa-smile"></i>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MenuButtonEmoji">
                                                        <div className='emojibox'>
                                                            <Picker
                                                                data={data}
                                                                onEmojiSelect={addEmoji}
                                                                style={{
                                                                    position: "absolute",
                                                                    marginTop: "465px",
                                                                    marginLeft: -40,
                                                                    maxWidth: "320px",
                                                                    borderRadius: "20px",
                                                                }}
                                                                theme="dark"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="blog-card feed_page">
                                {
                                    fetching ? <QueryBoxSkeleton count={1} /> : null
                                }

                                {
                                    queries !== ''
                                    &&
                                    queries.map((query, index) => {
                                        return (<QueryCard key={index} query={query} course_id={course_id} />);
                                    })
                                }

                                {
                                    (queries === '')
                                    &&
                                    <div className="col-12">
                                        <div className="empty_state">
                                            <h3>No Queries Raised!</h3>
                                            <img alt="" src="images/no_courses.png" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

            </div>
        </>
    );
}

export default QA;
import { useEffect, useState } from "react";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import P2PBenifits from "./P2PBenifits";

const Careers = () => {
    const [careers, setCareers] = useState('');

    useEffect(() => {
        fetchCareers();
        //eslint-disable-next-line
    }, []);

    async function fetchCareers() {
        const response = await fetch(API_BASE + 'get-careers', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setCareers(data.data.careers);
        } else {
            Toaster.error(data.message);
        }
    }

    return (
        <>
            <section>
                <div className="contact-banner">
                    <div className="container text-light text-center">
                        <h1>Careers</h1>
                    </div>
                </div>
            </section>

            <section className="custom-section">
                <div className="custom-breadcrumb">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Careers</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>

            <section className="custom-section">
                <div className="community-activity-section">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8">
                                <div className="community-activity-info">
                                    <div className="community-activity-header">
                                        <h3>We Prepare For The Future.</h3>
                                    </div>
                                    <div className="community-activity-content">
                                        <div className="community-news-contents" >
                                            <div className="community-tabs">
                                                {
                                                    careers !== ''
                                                    &&
                                                    careers.map((career, index) =>
                                                        <div key={index} className="community-tab-block">
                                                            <h5 className="primary-color">{career.title}</h5>
                                                            <div className="community-tabs-profile">
                                                                <span className="secondary-color">{career.experience}</span>
                                                            </div>
                                                            {parse(career.short_description)}
                                                            <Link to={"/career/" + career.id} className="btn btn-success btn-circle">Apply Now</Link>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="community-activity-info community-news-desc">
                                    <div className="community-activity-block">
                                        <P2PBenifits />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Careers;
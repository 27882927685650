import { useContext, useEffect, useLayoutEffect, useState, React } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { API_BASE, OPENAI_KEY } from "../../constants/global";
import { SwalBox, Toaster } from "../../entity/GlobalJS";
import { Configuration, OpenAIApi } from "openai";
import Quiz from 'react-quiz-component';
import Joyride from 'react-joyride';
import Cookies from "js-cookie";

const SelfAssessment = () => {
    const _lmsuser          = JSON.parse(localStorage.getItem('lmsuser'));
    const _lms_learner      = _lmsuser['first_name'];
    const _lms_learner_id    = _lmsuser['id'];
    const navigate = useNavigate();
    const [type_assessment, setTypeAssesement] = useState('');
    const [area_assessment, setAreaAssesement] = useState('');
    const [no_questions, setNumberofquestions] = useState('');
    const [processing, setProcessing] = useState(false);
    const [selfassesement, setSelfassesement] = useState(false);
    const [aiquiz, setAiquiz] = useState(false);
    const storedApiKey        = OPENAI_KEY;
    const [ openai, setOpenai] = useState(new OpenAIApi(new Configuration({ apiKey: storedApiKey })));
    const [result_heading_title_text, setResultHeadingTitleText] = useState('');
    const [result_points_text, setResultPointsText] = useState('');
    const [assesement_result_text, setAssesementResultText] = useState('');
    const [tour_steps, setTourSteps] = useState([]);

    const { register, control, formState: { errors }, getValues, setError, clearErrors } = useForm();
    useEffect(() => {
        selectAssesementType();
        //showAssesementTour();
        startAssesementTour();
    },[]);
    const showAssesementTour = () => {
        let show_tour = Cookies.get("show_assesement_tour");  
        if(show_tour != 'NO'){
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Would you like to have a quick tour?',
                text: 'This tour will take you through all the menu items in Assesement section and introduce you with the features.',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#30314c',
                cancelButtonColor: '#30314c',
                confirmButtonText: 'Yes, please.',
                cancelButtonText:'No, thank you.'
            }).then((result) => {
                if (result.isConfirmed) {
                    startAssesementTour();
                }else{
                    Cookies.set('show_assesement_tour', 'NO');
                }
            });
        }
    }
    const startAssesementTour = () => {
        let steps= [];
        steps= [
            {placementBeacon : 'right',disableBeacon : true,target: '.tour-assesement-step-01',title : 'Career Advancement',content: "By selecting this option, the system will remember the self-assessment topic and provide relevant suggestions for your career advancement in the future.",},
            {placementBeacon : 'right',disableBeacon : true,target: '.tour-assesement-step-02',title : 'Just for Practice',content: "This option can be utilized by young individuals who wish to practice and explore different topics without any specific career focus.",},
        ];
        setTourSteps(steps);
        Cookies.set('show_assesement_tour', 'NO');
    }
    const selectAssesementType = () => {
        SwalBox.fire({
            allowOutsideClick: false,
            title: 'Type of Self Assessment',
            text: 'Please choose the option that best fits your needs. This will help us tailor the self-assessment experience to your specific goals.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#30314c',
            cancelButtonColor: '#30314c',
            confirmButtonText: 'Career Advancement',
            cancelButtonText:'Just for Practice',
            customClass: {
                confirmButton: 'tour-assesement-step-01',
                cancelButton: 'tour-assesement-step-02',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setTypeAssesement('A');
                openAssesementPopup('A');
            }else{
                openAssesementPopup('B');
                setTypeAssesement('B');
            }
        });
    }
    function openAssesementPopup(type){
       document.getElementById('modalTogglerAssesement').click();
    }
    
    const cancelPopup = async () => {
        setTimeout(() => {
            navigate('/dashboard/home');
        }, 700);
    }

    const generateQuiz = async () => {
        SwalBox.fire({
            title: 'Please wait...',
            text:'Your quiz is being generated... please wait',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                SwalBox.showLoading();
            }
        });
        setProcessing(true);
        let _prompt_string = '';
        _prompt_string += 'I want you to generate '+ no_questions +' multi-choice questions to test my knowledge in  '+area_assessment+'. \nPlease follow the regex pattern given in this example: \nQuestion: What is two plus three ? \nA. One \nB. Seven \nC. Five \nD. Ten \nAnswer:  C. ';
        try {
            openai.createCompletion({
                model: "text-davinci-003",
                prompt: _prompt_string,
                temperature: 0.1,
                max_tokens: 4097-(_prompt_string.length),
            }).then((completion) => {
                const responseText = completion.data.choices[0].text;
                document.getElementById('assesementModalClose').click();
                const _questions = responseText.split("\n\n");
                var _questionbank = [];
                var _aiquestionbank = [];
                var _counter = 1;
                _questions.forEach(function(q) { 
                    const _answers = q.split("\n");
                    try {
                        if(_answers){
                            var _temp_q     = _answers[0];
                            var _temp_op1   = _answers[1];
                            var _temp_op2   = _answers[2];
                            var _temp_op3   = _answers[3];
                            var _temp_op4   = _answers[4];
                            var _temp_a_text = _answers[5];
                            if(_temp_a_text.includes("Answer: A.") == true){ var _temp_a     = 'A';}
                            if(_temp_a_text.includes("Answer: B.") == true){ var _temp_a     = 'B';}
                            if(_temp_a_text.includes("Answer: C.") == true){ var _temp_a     = 'C';}
                            if(_temp_a_text.includes("Answer: D.") == true){ var _temp_a     = 'D';}
    
                            var _quiz_question = {
                                'ID':_counter,
                                'Q':_temp_q.replace('Question: ',''),
                                'A':_temp_a.replace('A. ',''),
                                'OP1':_temp_op1.replace('B. ',''),
                                'OP2':_temp_op2.replace('C. ',''),
                                'OP3':_temp_op3.replace('D. ',''),
                                'OP4':_temp_op4,
                            }
                            let _canswer = 1;
    
                            if(_quiz_question.A == 'A') { _canswer = '1';}
                            if(_quiz_question.A == 'B') { _canswer = '2';}
                            if(_quiz_question.A == 'C') { _canswer = '3';}
                            if(_quiz_question.A == 'D') { _canswer = '4';}
                            let _temp_question = {
                                "question": _quiz_question.Q,
                                "questionType": "text",
                                "answerSelectionType": "single",
                                "answers": [
                                    _quiz_question.OP1,
                                    _quiz_question.OP2,
                                    _quiz_question.OP3,
                                    _quiz_question.OP4,
                                ],
                                "correctAnswer": _canswer,
                                "messageForCorrectAnswer": "Correct answer. Good job.",
                                "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
                                "point": "20"
                            };
                            //_questionbank.push(_quiz_question);
                            _aiquestionbank.push(_temp_question);
                            _counter++;
                        }
                    }catch(err) { }
                });
                setAiquiz({
                    "quizSynopsis":"Your Quiz for - "+area_assessment+ ' is ready',
                    "nrOfQuestions": no_questions,
                    "questions": _aiquestionbank,
                     "appLocale": {"resultPageHeaderText":result_heading_title_text, "resultPagePoint": result_points_text}
                });
                SwalBox.close();
            }).catch((error) => {
                SwalBox.fire({
                    title: 'Something went wrong',
                    text: 'Please try again after some time.',
                    icon: 'error',
                });
            });
        }catch(err) { }

    }
    const no_questions_options = Array.from(Array(20).keys()).map((num) => (<option key={num+1} value={num+1}>{num+1}</option>));
    const renderCustomResultPage = (obj) => {
        console.log(obj);
        return (
          <div>
            This is a custom result page. You can use obj to render your custom result page
          </div>
        )
    }
    const setQuizResult = (obj) => {
        setTimeout(() => {
            let _result_string = '';
            _result_string = 'Done';
            let _total_points       = obj.totalPoints;
            let _correct_points     = obj.correctPoints;
            let _percentage_f         = (100 * _correct_points) / _total_points;
            let _percentage         =_percentage_f.toFixed(2);
            
            let _message_text       = 'You have scored '+_percentage+'%. ';
            if(_percentage < 50 ){
                _message_text   +=   "Remember, there is always room for improvement, even for AI like myself. Keep practising, and you'll see progress soon!";
            }else if(_percentage > 50 &&  _percentage < 69){
                _message_text   +=   "Great effort on your score! Keep practising and you'll be ready for the next chapter in your career.";
            }else if(_percentage > 70){
                _message_text   +=   "Great Job! Your hard work has prepared you for the next chapter in your career.";
            }else{
                _message_text       = 'You have completed the quiz.';
            }
            setAssesementResultText(_message_text);
            // YOUR LOGIC GOES HERE
        }, 2000);
    }

    return (
        <>
        <div className="col-lg-9 home-section">
            <div className="custom-user-profile-content custom-learning-circle-content">
                <div className="custom-learnig-circle-head"><h4>Self Assessment</h4></div>
                <div className="learning-circle-courses">
                <section>
                    <p className="assesement-result-text">{assesement_result_text}</p>
                </section>
                    <section>
                    {
                    (aiquiz != false)
                    &&
                    <Quiz 
                    quiz={aiquiz} 
                    shuffle={true} 
                    showDefaultResult={true} 
                    onComplete={setQuizResult}
                    
                    />
                    }
                    </section>
                </div>
            </div>
        </div>
        <div className="materials_section">
            <div className="top_list">
                <div className="modal fade" id="assesementModal" tabIndex="-1" role="dialog" aria-labelledby="assesementModalTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog modal-dialog-centered modal-ek-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header"><h4 id="assesementModalTitle">Self Assessment</h4><button type="button" id="assesementModalClose" className="close hide" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Area/Subject of assessment </label>
                                    <input id="area_assessment" {...register("area_assessment", {
                                    maxLength: {
                                        value: 80,
                                        message: "Area/Subject can not be more than 80 charaters."
                                    }
                                    })} onChange={(e) => { setAreaAssesement(e.target.value); }} value={area_assessment} type="text" className="form-control" placeholder="Enter Area/Subject of assessment" autoFocus required />
                                    <span className="add-on max-chr-counter chrchk-area_assessment" data-ele="area_assessment" data-limit="80" data-showcounter="true"></span>
                                    <span className="text-danger">
                                        {errors.area_assessment && errors.area_assessment.type === "required" && 'Please enter Area/Subject.'}
                                        {errors.area_assessment && errors.area_assessment.type !== "required" && errors.area_assessment.message}
                                    </span>
                                </div>
                                {
                                (type_assessment === 'A' && type_assessment !== '')
                                &&
                                <div className="form-group">
                                    <label>Eligibility Type</label>
                                    <select {...register("eligibility")}>
                                        <option value="course">I am checking my eligibility to apply for a Course</option>
                                        <option value="course">I am checking my eligibility to apply for a Job</option>
                                    </select>
                                    <span className="text-danger">
                                        {errors.eligibility && errors.eligibility.type === "required" && 'Please select the section.'}
                                        {errors.eligibility && errors.eligibility.type !== "required" && errors.eligibility.message}
                                    </span>
                                </div>
                                }
                                <div className="form-group">
                                    <label>Number of Questions</label>
                                    <select {...register("no_questions")}  onChange={(e) => { setNumberofquestions(e.target.value); }}>
                                    {no_questions_options}
                                    </select>
                                    <span className="add-on max-chr-counter chrchk-no_questions" data-ele="no_questions" data-limit="80" data-showcounter="false"></span>
                                    <span className="text-danger">
                                        {errors.no_questions && errors.no_questions.type === "required" && 'Please enter Area/Subject.'}
                                        {errors.no_questions && errors.no_questions.type !== "required" && errors.no_questions.message}
                                    </span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={(e) => { cancelPopup(); }} type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Cancel</button>
                                <button onClick={(e) => { generateQuiz(); }}  disabled={processing} type="submit" className="btn btn-circle btn-md submit-pink-btn"> {processing ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Generate Quiz</>} </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a id="modalTogglerAssesement" data-toggle="modal" data-target="#assesementModal">&nbsp;</a>
        {
            tour_steps.length > 0 
            &&                 
            <Joyride
                steps={tour_steps}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                showSkipButton={true}
                continuous={true}
                hideCloseButton={true}
                disableScrolling={true}
                styles={{options: {width: 500,zIndex: 5000,primaryColor: '#31314c',textColor: '#ef4a45',overlayColor: 'rgb(2 1 0 / 69%)',}}}
            />
            }  
        </>
    )
}

export default SelfAssessment
import { useContext, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import logo from '../../assets/images/logo.svg';
import { API_BASE } from '../constants/global';
import authContext from '../context/auth/authContext';
import jwt_decode from "jwt-decode";
import { Toaster } from "./../entity/GlobalJS";

const Navbar = () => {
    const ref = useRef(null);
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const [coursesMenuData, setCoursesMenuData] = useState('');
    const [learningCircleMenuData, setLearningCircleMenuData] = useState('');

    useEffect(() => {
        const _token = localStorage.getItem('lmstoken');
        if (_token) {
            const decodedToken = jwt_decode(_token);
            const currentDate = new Date();

            if (decodedToken.exp * (60 * 60 * 24 * 7) < currentDate.getTime()) {
                Toaster.info('Your session is expired, please login again.');
                navigate('/logout');
                return;
            }
        }

        fetchHeaderMenuData();
        //eslint-disable-next-line
    }, []);

    function hideMenu(e) {
        if (ref.current.classList.contains('show')) {
            ref.current.classList.remove('show')
        }
    }

    async function fetchHeaderMenuData() {
        const response = await fetch(`${API_BASE}get-header-menu-data`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        const data = await response.json();
        if (data.status) {
            setCoursesMenuData(data.data.course_categories);
            setLearningCircleMenuData(data.data.learning_circle_categories);
        } else {
            // Toaster.error(data.message);
        }
    }

    return (
        <section className="header-section">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light sticky-top">
                    <Link className="navbar-brand" to="/">
                        <img src={`images/logo.svg`} alt="Logo" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobile_nav" aria-controls="mobile_nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div ref={ref} className="collapse navbar-collapse" id="mobile_nav">
                        <ul className="navbar-nav mr-auto mt-2 mt-lg-0 float-md-right">
                        </ul>
                        <ul className="navbar-nav navbar-light">
                            {
                            cntxAuth.isLogged
                            ?
                            <>
                            <li className="nav-item"><span><Link className="nav-link ek-menu-btn-home" to="/dashboard/home">Dashboard</Link></span></li>
                            </>
                            :
                            ''
                            }
                            <li className="tour-lr-step-12 nav-item dropdown megamenu-li dmenu">
                                <a href={'/courses'} className="nav-link">Courses</a>
                                {
                                    coursesMenuData.length > 0
                                        ?
                                        <span className="nav-link dropdown-toggle" id="dropdown01" data-toggle="dropdown"></span>
                                        :
                                        null
                                }
                                {
                                    coursesMenuData.length > 0
                                    &&
                                    <div className="dropdown-menu megamenu sm-menu border-top" aria-labelledby="dropdown01">
                                        <div className="row">
                                            {
                                                coursesMenuData
                                                &&
                                                coursesMenuData.map((category, index) =>
                                                    category.sub_category.length > 0
                                                        ?
                                                        <div key={index} className="col-sm-6 col-lg-3 border-right mb-4">
                                                            <h6><Link to={`/category/${category.slug}`} onClick={((e) => hideMenu(e))}>{category.title}</Link></h6>
                                                            {
                                                                category.sub_category.map((sub_cat, indexs) =>
                                                                    <Link key={indexs} className="dropdown-item" to={`/sub-category/${sub_cat.slug}`} onClick={((e) => hideMenu(e))}>{sub_cat.title}</Link>
                                                                )
                                                            }
                                                        </div>
                                                        :
                                                        ''
                                                )
                                            }
                                        </div>
                                    </div>
                                }
                            </li>
                            <li className="tour-lr-step-13 nav-item dropdown megamenu-li dmenu">
                                <a href={'/explore-learning-circles'} className="nav-link">Learning circle</a>
                                {
                                    learningCircleMenuData.length > 0
                                        ?
                                        <span className="nav-link dropdown-toggle" id="dropdown02" data-toggle="dropdown"></span>
                                        :
                                        null
                                }
                                {
                                    learningCircleMenuData.length > 0
                                    &&
                                    <div className="dropdown-menu megamenu sm-menu border-top" aria-labelledby="dropdown02">
                                        <div className="row">
                                            {
                                                learningCircleMenuData
                                                &&
                                                learningCircleMenuData.map((category, index) =>
                                                    category.sub_category.length > 0
                                                        ?
                                                        <div key={index} className="col-sm-6 col-lg-3 border-right mb-4">
                                                            <h6><Link to={`/explore-learning-circles/${category.slug}`} onClick={((e) => hideMenu(e))}>{category.title}</Link></h6>
                                                            {
                                                                category.sub_category.map((sub_cat, indexs) =>
                                                                    <Link key={indexs} className="dropdown-item" to={`/explore-learning-circles/${category.slug}/${sub_cat.slug}`} onClick={((e) => hideMenu(e))}>{sub_cat.title}</Link>
                                                                )
                                                            }
                                                        </div>
                                                        :
                                                        ''
                                                )
                                            }
                                        </div>
                                    </div>
                                }
                            </li>
                            {
                                cntxAuth.isLogged && (cntxAuth?.userInfo?.is_corporate === 1)
                                    ?
                                    ''
                                    :
                                    <li className="nav-item"><Link className="nav-link" to="/pages/teaching" onClick={((e) => hideMenu(e))}>Start teaching</Link></li>
                            }
                            {/*<li className="nav-item"><Link className="nav-link" to="/for-corporates" onClick={((e) => hideMenu(e))}>For corporates</Link></li>*/}
                            {
                                cntxAuth.isLogged
                                    ?
                                    ''
                                    :
                                    <>
                                        <li className="nav-item"><Link className="nav-link" to="/login" onClick={((e) => hideMenu(e))}>Log in</Link></li>
                                        <li className="nav-item"><Link className="nav-link" to="/register" onClick={((e) => hideMenu(e))}>Join now</Link></li>
                                    </>
                            }
                        </ul>
                    </div>

                    {
                        cntxAuth.isLogged
                            ?
                            <div className="tour-cr-step-01 tour-lr-step-01 profile-detail">
                                <ul>
                                    <li className="nav-item dmenu dropdown">
                                        <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src={cntxAuth.userInfo.image} className="rounded-circle" alt="Profile" />
                                        </span>
                                        <div className="dropdown-menu sm-menu border-top mob_menu" aria-labelledby="navbarDropdown">
                                            <Link to={'/dashboard/home'} className="dropdown-item" >User Dashboard</Link>
                                            <Link to={'/profile/general'} className="dropdown-item" >
                                                {
                                                    cntxAuth.isLogged && (cntxAuth?.userInfo?.is_corporate === 1)
                                                        ?
                                                        'Company '
                                                        :
                                                        'Personal '
                                                }
                                                Profile
                                            </Link>
                                            <Link className="dropdown-item ek-lrs-logout" to="/logout">Logout</Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            :
                            ''
                    }
                </nav>
            </div>
        </section>
    );
}

export default Navbar;
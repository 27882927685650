import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useOutletContext, useSearchParams } from "react-router-dom";
import CircleBoxSkeleton from "../../../components/skeletons/CircleBoxSkeleton";
import { API_BASE } from "../../../constants/global";
import CustomPagination from "../../../entity/CustomPagination";
import CourseCard from "./CourseCard";

const OnGoingCourses = (props) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const [modes, progs] = useOutletContext();
    const _token = localStorage.getItem('lmstoken');
    const [list_courses, setListCourses] = useState('');
    const [categories, setCategories] = useState('');
    const [selected_categories, setSelectedCategories] = useState('');
    const [selected_modes, setSelectedModes] = useState('');
    const [selected_prog, setSelectedProg] = useState('');
    const [total_count, setTotalCount] = useState(0);
    const [clearAllBtnClass, setclearAllBtnClass] = useState('hide');
    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        fetchCategories();
        fetchCourses(currentPage);
    }, [selected_categories, selected_modes, selected_prog, location, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const clearSearch = () => {
        setSelectedCategories('');
        setSelectedModes('');
        setSelectedProg('');
        setclearAllBtnClass('hide');
        fetchCourses();
    }

    async function fetchCategories() {
        const response = await fetch(`${API_BASE}get-all-categories`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setCategories(data.data.course_categories);
        } else {
            setCategories('');
        }
    }

    const fetchCourses = async (page = 0) => {
        var formData = new FormData();
        var exhasAnyFilter = false;
        if (selected_categories !== '' && selected_categories.length > 0) {
            for (var i = 0; i < selected_categories.length; i++) {
                formData.append('cat[]', selected_categories[i]);
            }
            exhasAnyFilter = true;
        }

        if (selected_prog !== '' && selected_prog.length > 0) {
            for (var i = 0; i < selected_prog.length; i++) {
                formData.append('prog[]', selected_prog[i]);
            }
            exhasAnyFilter = true;
        }

        if (selected_modes !== '' && selected_modes.length > 0) {
            for (var i = 0; i < selected_modes.length; i++) {
                formData.append('mod[]', selected_modes[i]);
            }
            exhasAnyFilter = true;
        }
        if(exhasAnyFilter == false){
            setclearAllBtnClass('hide');
        }
        setIsLoader(true);
        const response = await fetch(`${API_BASE}get-ongoing-courses?page=${page}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        setIsLoader(false);
        if (data.status) {
            setListCourses(data.data.courses);
            setTotalCount(data.data.total_count);
        } else {
            setListCourses('');
            setTotalCount(0);
        }
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-course" role="tabpanel" aria-labelledby="pills-course-tab">
                <div className="blog-card">
                    <div className="container">
                        <div className="category-search-block">
                            <div className="category-navigation filter_box pb-0 tour-student-step-02">
                                <ul>
                                    {
                                        (typeof props.type === 'undefined' && categories !== '')
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Category</button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        categories.map((category, index) => {
                                                            var flag = selected_categories.indexOf(category.slug) !== -1;
                                                            return (<a onClick={(e) => { if (!flag) { setSelectedCategories([...selected_categories, category.slug]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{category.title}</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }

                                    {
                                        progs !== '' && typeof progs !== 'undefined'
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Course Progress</button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        progs.map((prog, index) => {
                                                            var flag = selected_prog.indexOf(prog) !== -1;
                                                            return (<a onClick={(e) => { if (!flag) { setSelectedProg([...selected_prog, prog]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{prog}</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }

                                    {
                                        modes !== '' && typeof modes !== 'undefined'
                                        &&
                                        <li>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Learning type</button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {
                                                        modes.map((mode, index) => {
                                                            var flag = selected_modes.indexOf(mode) !== -1;
                                                            return (<a onClick={(e) => { if (!flag) { setSelectedModes([...selected_modes, mode]); } setclearAllBtnClass(''); }} key={index} className={`dropdown-item ${flag ? ' active' : ''}`}>{mode}</a>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                            <div className="selected-filter filter_display custom_filter_btn">
                                <ul>
                                    {
                                        selected_categories !== ''
                                        &&
                                        selected_categories.map((category, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{category}</span><i onClick={(e) => { setSelectedCategories(selected_categories.filter(item => item !== category)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    }

                                    {
                                        selected_prog !== ''
                                        &&
                                        selected_prog.map((prog, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{prog}</span><i onClick={(e) => { setSelectedProg(selected_prog.filter(item => item !== prog)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    }

                                    {
                                        selected_modes !== ''
                                        &&
                                        selected_modes.map((mode, index) =>
                                            <li key={index}>
                                                <span className="btn btn-light btn-success select-filter"><span>{mode}</span><i onClick={(e) => { setSelectedModes(selected_modes.filter(item => item !== mode)) }} className="fas fa-times"></i></span>
                                            </li>
                                        )
                                    }
                                </ul>

                                {
                                    <a onClick={(e) => { clearSearch(); }} className={`remove-filter ${clearAllBtnClass}`}>Clear all</a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            isLoader ?
                                <>
                                    <CircleBoxSkeleton count={4} />
                                </>
                                :
                                <>
                                    {
                                        list_courses !== ''
                                        &&
                                        list_courses.map((course, index) =>
                                            <div key={index} className='col-md-6 col-lg-6'>
                                                <CourseCard course={course} is_completed={false} refresh={fetchCourses} />
                                            </div>
                                        )
                                    }

                                    {
                                        list_courses === ''
                                        &&
                                        <div className="col-md-12 col-lg-12 text-center">
                                            <div className="empty_state">
                                                <h3>No ongoing courses</h3>
                                                <img alt="" src="images/no_courses.png" />
                                                <p>
                                                    Explore our course catalogue to enrol on a course and start your learning journey.
                                                    <br /><a href="/courses" className="link" >Explore Courses</a>
                                                </p>
                                            </div>
                                        </div>
                                    }
                                </>
                        }


                    </div>
                </div>

                {
                    total_count > 4
                    &&
                    <div className="custom-pagination">
                        <nav aria-label="Page navigation example">
                            <CustomPagination pageCount={total_count} perPageRecord={4} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                        </nav>
                    </div>
                }
            </div>
        </>
    );
}

export default OnGoingCourses;
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { API_BASE, DEFAULT_UPLOAD_FILE_IMG } from "../../../constants/global";
import CustomPagination from "../../../entity/CustomPagination";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";
import parse from 'html-react-parser';
import TinyMCEEditor from "../../../entity/TinyMCEEditor";
import SinglePage from "../../../pages/front/pdf/single-page";

const CircleMaterials = () => {
    const { register, control, handleSubmit, formState: { errors }, setValue, getValues, setError, clearErrors } = useForm();
    const _token = localStorage.getItem('lmstoken');
    const [upFileClass, setUpFileClass] = useState('');
    const { unique_id } = useParams();
    const [total_count, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [owner, setOwner] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [list_sections, setListSections] = useState('');
    const [slides, setSlides] = useState('');
    const [sections, setSections] = useState('');

    const [section_id, setSectionId] = useState('');
    const [section_title, setSectionTitle] = useState('');
    const [section_desc, setSectionDesc] = useState('');
    const [set_order_after, setOrderAfter] = useState('');

    const [slide_section_id, setSlideSectionId] = useState('');
    const [slide_id, setSlideId] = useState('');
    const [slide_title, setSlideTitle] = useState('');
    const [slide_desc, setSlideDesc] = useState('');

    const [material_file, setMaterialFile] = useState('');
    const [material_desc, setMaterialDesc] = useState('');
    const [file_name, setFileName] = useState('');

    const [view_slide, setViewSlide] = useState('');

    useEffect(() => {
        fetchSections(currentPage);
        // eslint-disable-next-line 
    }, []);

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const fetchListSections = async () => {
        const response = await fetch(`${API_BASE}get-section-order/${unique_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            setListSections(data.data.sections);
        }
    }

    const fetchSections = async (page, search = '') => {
        const response = await fetch(`${API_BASE}get-all-sections/${unique_id}?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setSlides(data.data.slides);
            setSections(data.data.sections);
            setTotalCount(data.data.total_count);
            setOwner(data.data.admin);
        } else {
            setSlides('');
            setSections('');
            setTotalCount(0);
            setOwner(data.data.admin);
        }

        if (page === 0) {
            setCurrentPage(0);
        }

        fetchListSections();
    }

    const saveSection = async (form) => {
        if (section_title === '') {
            setError("section_title", { type: "focus", message: 'Please enter title.' }, { shouldFocus: true });
            return false;
        }

        if (section_desc === '') {
            setError("section_desc", { type: "focus", message: 'Please enter short description.' }, { shouldFocus: true });
            return false;
        }

        clearSectionError();

        setProcessing(true);

        const formData = new FormData();
        formData.append('group_uid', unique_id);
        formData.append('title', section_title);
        formData.append('description', section_desc);
        if (section_desc !== '') {
            formData.append('set_order_after', set_order_after);
        }

        var sURL = `${API_BASE}post-create-section${section_id !== '' ? '/' + section_id : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            document.getElementById('SectionModalLongClose').click();
            Toaster.success(rdata.message);
            fetchListSections();
            fetchSections(currentPage);
            setProcessing(false);
        } else {
            Toaster.error(rdata.message);
            setProcessing(false);
        }
    }

    const saveSlide = async (form) => {
        if (slide_title === '') {
            setError("slide_title", { type: "focus", message: 'Please enter slide title.' }, { shouldFocus: true });
            return false;
        }

        if (slide_desc === '') {
            setError("slide_desc", { type: "focus", message: 'Please enter short description.' }, { shouldFocus: true });
            return false;
        }

        clearSlideError();

        setProcessing(true);

        const formData = new FormData();
        if (slide_section_id !== '') {
            formData.append('section_id', slide_section_id);
        }
        formData.append('title', slide_title);
        formData.append('description', slide_desc);
        formData.append('group_uid', unique_id);

        var sURL = `${API_BASE}post-create-slider${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            document.getElementById('SlideModalLongClose').click();
            Toaster.success(rdata.message);
            fetchSections(currentPage);
        } else {
            Toaster.error(rdata.message);
        }

        setProcessing(false);
    }

    const removePhoto = () => {
        setMaterialFile('');
        setFileName('');
        setUpFileClass('');
    }

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        onDrop: (acceptFile) => {
            setMaterialFile(acceptFile[0]);
            setFileName(acceptFile[0].name);
            setUpFileClass('upfile_img_vdo');
            moderateUploadedMedia(acceptFile[0]);
        },
    });

    const saveMaterial = async (form) => {
        if (slide_title === '') {
            setError("material_title", { type: "focus", message: 'Please enter material titledd.' }, { shouldFocus: true });
            return false;
        }
        if(material_desc != ""){

        }else{
            if (material_file === '') {
                setError("material_file", { type: "focus", message: 'Please select materials file.' }, { shouldFocus: true });
                return false;
            }
        }
        if (material_file !== '') {
            let _allowed_material_type = [
                'video/3gpp','video/x-msvideo','video/x-flv','video/jpeg','video/x-ms-wmv','video/mp4','application/mp4','video/quicktime','audio/ogg','video/ogg','video/webm','video/x-ms-asf','video/mkv', 'video/x-matroska',
                'application/x-7z-compressed','application/zip','application/x-rar-compressed','application/x-tar',
                'application/pdf',
                'audio/x-aac','audio/x-ms-wma',
                'image/bmp','image/gif','image/jpeg','application/vnd.oasis.opendocument.image','image/png','image/tiff',
                'text/csv','text/plain','text/tab-separated-values','text/richtext','text/calendar','application/rtf','application/vnd.oasis.opendocument.text',
                'application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.openxmlformats-officedocument.spreadsheetml.template','application/vnd.oasis.opendocument.spreadsheet','application/oda','application/msword',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.ms-powerpoint','application/vnd.oasis.opendocument.presentation',
            ];
            let _is_a_valid_mine =   _allowed_material_type.includes(material_file.type);
            if(_is_a_valid_mine != true){
                SwalBox.fire({
                    title: 'Unsupported File Format',
                    text: "The file "+material_file.name+" you're trying to upload is not supported by EduKula. Please ensure that your file meets our supported file formats, which include: PDF, Word, PPT, Audio, Video, Image, ZIP, SCORM and TINCAN",
                    icon: 'warning',
                });
                setError("material_file", { type: "focus", message: 'Please select a valid file.' }, { shouldFocus: true });
                return false;
            }
        }

        clearMaterialError();
        setProcessing(true);

        const formData = new FormData();
        if (slide_section_id !== '') {
            formData.append('section_id', slide_section_id);
        }
        formData.append('title', slide_title);
        formData.append('material_file', material_file);
        formData.append('group_uid', unique_id);

        var sURL = `${API_BASE}post-create-material${slide_id !== '' ? `/${slide_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });

        const rdata = await response.json();
        if (rdata.status) {
            document.getElementById('UploadModalLongClose').click();
            Toaster.success(rdata.message);
            fetchSections(currentPage);
        } else {
            Toaster.error(rdata.message);
        }
        setUpFileClass('');
        setProcessing(false);
    }

    const clearSectionError = () => {
        clearErrors('section_title');
        clearErrors('section_desc');
        clearErrors('set_order_after');
    }

    const clearSlideError = () => {
        clearErrors('slide_section');
        clearErrors('slide_title');
        clearErrors('material_file');
    }

    const clearMaterialError = () => {
        clearErrors('slide_section');
        clearErrors('material_title');
        clearErrors('material_file');
    }

    const ConfirmDeleteSection = (section_id) => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: 'Sure to delete material section?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteSection(section_id);
            }
        });
    }

    const DeleteSection = async (section_id) => {
        const response = await fetch(`${API_BASE}delete-material-section/${section_id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            fetchSections(currentPage);
        } else {
            Toaster.error(data.message);
        }
    }

    const ConfirmDeleteSlide = (slide_id) => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: '    Sure to delete material slide?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteSlide(slide_id);
            }
        });
    }

    const DeleteSlide = async (slide_id) => {
        const response = await fetch(`${API_BASE}delete-material-slide/${slide_id}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            fetchSections(currentPage);
        } else {
            Toaster.error(data.message);
        }
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
    
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
    
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    
    
    const moderateUploadedMedia = async (media) => {
        const formData = new FormData();
        var fileField = media;
        if(media.type == "video/mp4"){
            /*
            formData.append("video", fileField);
            const response = await fetch(API_BASE + 'validate-uploaded-video', {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + _token
                },
                body: formData
            });
            const data = await response.json();
            if (data.status) {
                var _is_valid = data.data;
                if(_is_valid != ""){
                    SwalBox.fire({
                        title: 'Inappropriate video',
                        text: 'Your video has been removed as it contains inappropriate content ('+_is_valid+ ') , please upload a valid image.',
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok'
                    });
                    setTimeout(function(){ removePhoto(); }, 1000)
                }
            }
            */
        }else if(media.type == "image/png" || media.type == "image/jpeg"){
            formData.append("image", fileField);
            const response = await fetch(API_BASE + 'validate-uploaded-image', {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + _token
                },
                body: formData
            });
            const data = await response.json();
            if (data.status) {
                var _is_valid = data.data;
                if(_is_valid != ""){
                    SwalBox.fire({
                        title: 'Inappropriate image',
                        text: 'Your image has been removed as it contains inappropriate content ('+_is_valid+ ') , please upload a valid image.',
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok'
                    });
                    setTimeout(function(){ removePhoto(); }, 1000)
                }
            }
        }else{


        }
    }
    return (
        <>
            <section className={`custom-section materials_section`}>
                <div className="profile_user_middle_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="custom-user-profile-content custom-learning-circle-content">
                                    <div className="custom-learnig-circle-head">
                                        <div className="top_list">
                                            <h4>Materials List</h4>
                                            <div className="clearfix"></div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group half_width">
                                                        <i className="fas fa-search"></i><input onChange={(e) => { fetchSections(0, e.target.value); }} type="text" className="form-control" placeholder="Search Materials" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <a className="btn btn-circle btn-info" id="MaterialsMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-plus" aria-hidden="true"></i> Add</a>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="MaterialsMenuButton">
                                                        {
                                                            owner
                                                            &&
                                                            <a onClick={(e) => { clearSectionError(); setSectionId(''); setSectionTitle(''); setSectionDesc(''); }} className="dropdown-item cursor-pointer" data-toggle="modal" data-target="#SectionModalLong">Add Section</a>
                                                        }
                                                        <a onClick={(e) => { clearSlideError(); setSlideId(''); setSlideTitle(''); setSlideDesc(''); setSlideSectionId(''); }} className="dropdown-item cursor-pointer" data-toggle="modal" data-target="#SlideModalLong">Add Slide</a>
                                                        <a onClick={(e) => { clearMaterialError(); setSlideId(''); setSlideTitle(''); setMaterialFile(''); setMaterialDesc(''); setSlideSectionId(''); }} className="dropdown-item cursor-pointer" data-toggle="modal" data-target="#UploadModalLong">Upload File</a>
                                                        <a className="d-none" data-toggle="modal" data-target="#MaterialViewModal" id="MaterialViewModalclick">View Slide</a>
                                                    </div>
                                                    <div className="modal fade" id="SectionModalLong" tabIndex="-1" role="dialog" aria-labelledby="SectionModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                                        <div className="modal-dialog modal-dialog-centered" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h4 id="SectionModalLongTitle">{section_id === '' ? 'Add' : 'Edit'} Section</h4>
                                                                    <button type="button" id="SectionModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className="form-group">
                                                                        <label>Section</label>
                                                                        <input id="section_title" {...register("section_title", {
                                                                            required: "required",
                                                                            maxLength: {
                                                                                value: 80,
                                                                                message: "Title can not be more than 80 charaters."
                                                                            }
                                                                        })} onChange={(e) => { setSectionTitle(e.target.value); }} type="text" className="form-control" value={section_title} placeholder="Enter a Title" autoFocus />
                                                                        <span className="add-on max-chr-counter chrchk-section_title" data-ele="section_title" data-limit="80" data-showcounter="true"></span>
                                                                        <span className="text-danger">
                                                                            {errors.section_title && errors.section_title.type === "required" && 'Please enter section title.'}
                                                                            {errors.section_title && errors.section_title.type !== "required" && errors.section_title.message}
                                                                        </span>
                                                                    </div>
                                                                    {
                                                                        (section_id === '' && list_sections !== '')
                                                                        &&
                                                                        <div className="form-group">
                                                                            <label>Add After</label>
                                                                            <select {...register("section_after", { required: "required" })} onChange={(e) => { setOrderAfter(e.target.value); }}>
                                                                                <option value="">Select position</option>
                                                                                {
                                                                                    list_sections !== ''
                                                                                    &&
                                                                                    list_sections.map((section, index) =>
                                                                                        <option key={index} value={section.id}>After {section.title}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                            <span className="text-danger">
                                                                                {errors.section_after && errors.section_after.type === "required" && 'Please select position of the section.'}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    <div className="form-group">
                                                                        <label>Short Description</label>
                                                                        <textarea id="section_desc" {...register("section_desc", {
                                                                            required: "required",
                                                                            maxLength: {
                                                                                value: 200,
                                                                                message: "Short Description can not be more than 200 charaters."
                                                                            }
                                                                        })} onChange={(e) => { setSectionDesc(e.target.value); }} rows="10" cols="10" placeholder="Description" value={section_desc}></textarea>
                                                                        <span className="add-on max-chr-counter chrchk-section_desc" data-ele="section_desc" data-limit="200" data-showcounter="true"></span>
                                                                        <span className="text-danger">
                                                                            {errors.section_desc && errors.section_desc.type === "required" && 'Please enter short description.'}
                                                                            {errors.section_desc && errors.section_desc.type !== "required" && errors.section_desc.message}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                                                    <button onClick={(e) => { saveSection(); }} type="button" className="btn btn-circle btn-md submit-pink-btn" disabled={processing}> Save Section </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal fade" id="SlideModalLong" tabIndex="-1" role="dialog" aria-labelledby="SlideModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                                        <div className="modal-dialog modal-dialog-centered modal-ek-lg" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h4 id="SlideModalLongTitle">{slide_id === '' ? 'Add' : 'Edit'} Slide</h4>
                                                                    <button type="button" id="SlideModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    {
                                                                        <div className="form-group">
                                                                            <label>Select Section</label>
                                                                            <select {...register("slide_section1")} onChange={(e) => { setSlideSectionId(e.target.value); }} value={slide_section_id !== '' ? slide_section_id : ''}>
                                                                                <option value="">Select section</option>
                                                                                {
                                                                                    list_sections !== ''
                                                                                    &&
                                                                                    list_sections.map((section, index) =>
                                                                                        <option key={index} value={section.id}>{section.title}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                            <span className="text-danger">
                                                                                {errors.slide_section1 && errors.slide_section1.type === "required" && 'Please select the section.'}
                                                                                {errors.slide_section1 && errors.slide_section1.type !== "required" && errors.slide_section1.message}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    <div className="form-group">
                                                                        <label>Title</label>
                                                                        <input id="slide_title" {...register("slide_title", {
                                                                            maxLength: {
                                                                                value: 80,
                                                                                message: "Title can not be more than 80 charaters."
                                                                            }
                                                                        })} onChange={(e) => { setSlideTitle(e.target.value); }} value={slide_title} type="text" className="form-control" placeholder="Enter a Title" autoFocus />
                                                                        <span className="add-on max-chr-counter chrchk-slide_title" data-ele="slide_title" data-limit="80" data-showcounter="true"></span>
                                                                        <span className="text-danger">
                                                                            {errors.slide_title && errors.slide_title.type === "required" && 'Please enter slide title.'}
                                                                            {errors.slide_title && errors.slide_title.type !== "required" && errors.slide_title.message}
                                                                        </span>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Describe the Slide in detail</label>
                                                                        <Controller
                                                                            control={control}
                                                                            name="slide_desc"
                                                                            rules={{ required: true, message: "asfsf" }}
                                                                            render={({ field: { onChange, value, name, ref } }) => (
                                                                                <TinyMCEEditor onChange={(e) => { setSlideDesc(e) }} value={slide_desc} placeholder='About Slide..' />
                                                                            )}
                                                                        />
                                                                        <span className="text-danger">
                                                                            {errors.slide_desc && errors.slide_desc.type === "required" && 'Please enter short description.'}
                                                                            {errors.slide_desc && errors.slide_desc.type !== "required" && errors.slide_desc.message}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                                                    <button type="button" onClick={(e) => { saveSlide(); }} className="btn btn-circle btn-md submit-pink-btn" disabled={processing}>Save Slide</button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal fade" id="UploadModalLong" tabIndex="-1" role="dialog" aria-labelledby="UploadModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                                        <div className="modal-dialog modal-dialog-centered" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h4 id="UploadModalLongTitle">{slide_id === '' ? 'Add' : 'Edit'} Material</h4>
                                                                    <button type="button" id="UploadModalLongClose" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    {
                                                                        <div className="form-group">
                                                                            <label>Select Section</label>
                                                                            <select {...register("slide_section")} onChange={(e) => { setSlideSectionId(e.target.value); }} value={slide_section_id !== '' ? slide_section_id : ''}>
                                                                                <option value="">Select section</option>
                                                                                {
                                                                                    list_sections !== ''
                                                                                    &&
                                                                                    list_sections.map((section, index) =>
                                                                                        <option key={index} value={section.id}>{section.title}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                            <span className="text-danger">
                                                                                {errors.slide_section && errors.slide_section.type === "required" && 'Please select the section.'}
                                                                                {errors.slide_section && errors.slide_section.type !== "required" && errors.slide_section.message}
                                                                            </span>
                                                                        </div>
                                                                    }

                                                                    <div className="form-group">
                                                                        <label>Title</label>
                                                                        <input id="material_title" {...register("material_title", {
                                                                            maxLength: {
                                                                                value: 80,
                                                                                message: "Title can not be more than 80 charaters."
                                                                            }
                                                                        })} onChange={(e) => { setSlideTitle(e.target.value); }} value={slide_title} type="text" className="form-control" placeholder="Enter a Title" autoFocus />
                                                                        <span className="add-on max-chr-counter chrchk-material_title" data-ele="material_title" data-limit="80" data-showcounter="true"></span>
                                                                        <span className="text-danger">
                                                                            {errors.material_title && errors.material_title.type === "required" && 'Please enter material title.'}
                                                                            {errors.material_title && errors.material_title.type !== "required" && errors.material_title.message}
                                                                        </span>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label>Upload File</label>
                                                                        {
                                                                            material_file !== ''
                                                                            &&
                                                                            <div className="file-choosen">
                                                                                <div onClick={(e) => { removePhoto(); }} className="btnGrp">
                                                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                                                </div>
                                                                                <h5>Selected File: {file_name}</h5>
                                                                            </div>
                                                                        }

                                                                        {
                                                                            material_file === ''
                                                                            &&
                                                                            <>
                                                                                <div {...getRootProps()} className={upFileClass}>
                                                                                    <img src={DEFAULT_UPLOAD_FILE_IMG} className="" alt='preview' />
                                                                                    <input {...getInputProps()} />
                                                                                </div>
                                                                                <strong>{(material_desc && material_desc !== '') && 'Current file : '}{parse((material_desc && material_desc !== '') ? material_desc : '')}</strong>
                                                                                <p className="small_txt">You can upload PDF, Word, PPT, Video, Image, SCORM and TINCAN</p>
                                                                            </>
                                                                        }

                                                                        <span className="text-danger">
                                                                            {errors.material_file && errors.material_file.type === "required" && 'Please select materials file.'}
                                                                            {errors.material_file && errors.material_file.type !== "required" && errors.material_file.message}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-circle btn-md cancel-btn" data-dismiss="modal">Close</button>
                                                                    <button onClick={(e) => { saveMaterial(); }} disabled={processing} type="button" className="btn btn-circle btn-md submit-pink-btn">{processing ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></> : <>Save Material</>}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal fade" id="MaterialViewModal" tabIndex="-1" role="dialog" aria-labelledby="MaterialViewModalTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                                        <div className="modal-dialog modal-dialog-centered pdf_height materials_large_modal" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h4 id="MaterialViewModalTitle">View Content</h4>
                                                                    <button type="button" id="MaterialViewModalClose" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    {
                                                                        view_slide !== ''
                                                                        &&
                                                                        <>
                                                                            {
                                                                                (view_slide.type === 'Text' && view_slide.material.material_text !== '')
                                                                                &&
                                                                                parse(view_slide.description)
                                                                            }

                                                                            {
                                                                                (view_slide.type === 'Photo' && view_slide.material.material_media !== '')
                                                                                &&
                                                                                <img className="w-100" src={view_slide.material.material_media} alt={view_slide.material.title} />
                                                                            }

                                                                            {
                                                                                (view_slide.type === 'Video' && view_slide.material.material_media !== '')
                                                                                &&
                                                                                <video className="w-100" controls>
                                                                                    <source src={view_slide.material.material_media} type="video/mp4" />
                                                                                    Sorry, your browser doesn't support embedded videos.
                                                                                </video>
                                                                            }

                                                                            {
                                                                                view_slide.type === 'Document'
                                                                                    ?
                                                                                    <>
                                                                                        {
                                                                                            ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].indexOf(getExtension(view_slide.material.material_media).toLowerCase()) !== -1
                                                                                                ?
                                                                                                getExtension(view_slide.material.material_media).toLowerCase() === 'pdf'
                                                                                                    ?
                                                                                                    <iframe className="doc_iframe" id="pdf-js-viewer" src={`${API_BASE.replace('public/api/', '')}pdfjs/web/viewer.html?file=${view_slide.material.material_media}`} title="webviewer" frameBorder="0" width="500" height="600"></iframe>
                                                                                                    :
                                                                                                    <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${view_slide.material.material_media}`} style={{ width: 100 + '%' }} frameBorder="0"></iframe>
                                                                                                :
                                                                                                <p>Download: <a className="btn btn-circle btn-success coman_btn m-0" href={view_slide.material.material_media} target="_blank" download>{view_slide.material.material_media_name} <i className="fas fa-download"></i></a></p>
                                                                                        }
                                                                                    </>

                                                                                    :
                                                                                    ""
                                                                            }

                                                                            {
                                                                                (view_slide.type === 'TinCan')
                                                                                &&
                                                                                <iframe frameBorder="0" marginHeight="0" marginWidth="0" width="100%" height="500" scrolling="auto" src={view_slide.material.material_tin_can}>
                                                                                    Your browser doesn't support iframes
                                                                                </iframe>
                                                                            }

                                                                            {
                                                                                (view_slide.type === 'Scorm')
                                                                                &&
                                                                                <iframe frameBorder="0" marginHeight="0" marginWidth="0" width="100%" height="500" scrolling="auto" src={view_slide.material.material_scorm}>
                                                                                    Your browser doesn't support iframes
                                                                                </iframe>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="meetingdata">
                                        <table>
                                            <tbody>
                                                {
                                                    (typeof slides !== 'undefined' && slides.length > 0)
                                                    &&
                                                    <tr key={Math.random()}>
                                                        <td>
                                                            <table>
                                                                <thead>
                                                                    <tr key={Math.random()}>
                                                                        <th width="300">Title</th>
                                                                        <th width="100">Added by</th>
                                                                        <th width="20"></th>
                                                                        <th width="150">Date-Time</th>
                                                                        <th width="150"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        <>
                                                                            {
                                                                                slides.map((slide, index) =>
                                                                                    <ListSlide
                                                                                        owner={owner}
                                                                                        key={index}
                                                                                        slide={slide}
                                                                                        setViewSlide={setViewSlide}
                                                                                        ConfirmDeleteSlide={ConfirmDeleteSlide}
                                                                                        clearSlideError={clearSlideError}
                                                                                        setSlideId={setSlideId}
                                                                                        setSlideTitle={setSlideTitle}
                                                                                        setSlideDesc={setSlideDesc}
                                                                                        clearMaterialError={clearMaterialError}
                                                                                        setMaterialFile={setMaterialFile}
                                                                                        setMaterialDesc={setMaterialDesc}
                                                                                        setSlideSectionId={setSlideSectionId}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                }

                                                {
                                                    sections !== ''
                                                    &&
                                                    sections.map((section, index) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <table>
                                                                    <thead>
                                                                        <tr key={Math.random()}>
                                                                            <th colSpan={4} scope="row">
                                                                                <p><strong>{section.title}</strong>{section.description}</p>
                                                                            </th>
                                                                            <th>
                                                                                <span className="floatR">
                                                                                    {
                                                                                        owner
                                                                                        &&
                                                                                        <>
                                                                                            <a data-toggle="modal" data-target="#SectionModalLong" onClick={(e) => { clearSectionError(); setSectionId(section.id); setSectionTitle(section.title); setSectionDesc(section.description); }} className="edit_btn_s"><i className="fas fa-edit"></i></a>
                                                                                            <a onClick={(e) => { ConfirmDeleteSection(section.id); }} className="edit_btn_s"><i className="fas fa-trash-alt"></i></a>
                                                                                        </>
                                                                                    }
                                                                                </span>
                                                                                <div className="clearfix"></div>
                                                                            </th>
                                                                        </tr>
                                                                        <tr key={Math.random()}>
                                                                            <th width="300">Title</th>
                                                                            <th width="100">Added by</th>
                                                                            <th width="20"></th>
                                                                            <th width="150">Date-Time</th>
                                                                            <th width="150"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            (typeof section.slides !== 'undefined' && section.slides.length > 0)
                                                                            &&
                                                                            <>
                                                                                {
                                                                                    section.slides.map((slide, index) =>
                                                                                        <ListSlide
                                                                                            owner={owner}
                                                                                            key={index}
                                                                                            slide={slide}
                                                                                            setViewSlide={setViewSlide}
                                                                                            ConfirmDeleteSlide={ConfirmDeleteSlide}
                                                                                            clearSlideError={clearSlideError}
                                                                                            setSlideId={setSlideId}
                                                                                            setSlideTitle={setSlideTitle}
                                                                                            setSlideDesc={setSlideDesc}
                                                                                            clearMaterialError={clearMaterialError}
                                                                                            setMaterialFile={setMaterialFile}
                                                                                            setMaterialDesc={setMaterialDesc}
                                                                                            setSlideSectionId={setSlideSectionId}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </>
                                                                        }

                                                                        {
                                                                            (typeof section.slides === 'undefined' || section.slides.length <= 0)
                                                                            &&
                                                                            <tr>
                                                                                <td className="text-center nodata" colSpan={5}><p><strong>There is no material uploaded</strong></p></td>
                                                                            </tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                                {
                                                    (slides === '' && sections === '')
                                                    &&
                                                    <tr key={Math.random()}>
                                                        <th className="text-center" scope="row"><strong>There were no sections or materials added</strong></th>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                        <div className="clearfix"></div>

                                        {
                                            total_count > 5
                                            &&
                                            <div className="custom-pagination">
                                                <nav aria-label="Page navigation example">
                                                    <CustomPagination currentPage={currentPage} pageCount={total_count} perPageRecord={5} handlePageClick={(data) => { fetchSections(data.selected); setCurrentPage(data.selected); }} />
                                                </nav>
                                            </div>
                                        }
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CircleMaterials;

const ListSlide = ({ owner, slide, setViewSlide, ConfirmDeleteSlide, clearSlideError, setSlideId, setSlideTitle, setSlideDesc, clearMaterialError, setMaterialFile, setMaterialDesc, setSlideSectionId }) => {
    return (
        <>
            <tr key={slide.id}>
                <td><p><strong>{slide.title}</strong></p></td>
                <td><Link to={`/profile-details/${slide.creator.slug}`}>{slide.creator.name}</Link></td>
                <td>
                    {
                        (slide.material.material_media !== null || slide.material.material_tin_can !== null || slide.material.material_scorm !== null)
                        &&
                        <i className="fas fa-paperclip"></i>
                    }
                </td>
                <td>{slide.date}</td>
                <td>
                    {
                        (slide.material.material_text !== null || slide.material.material_media !== null || slide.material.material_tin_can !== null || slide.material.material_scorm !== null)
                        &&
                        <a onClick={(e) => { setViewSlide(slide); document.getElementById('MaterialViewModalclick').click(); }} className="edit_btn ek-lrs-file-launched" data-ek-lrs-title={slide.title} data-ek-lrs-url={slide.material.material_media}>View</a>
                    }
                    {
                        (owner || slide.is_my)
                        &&
                        <a onClick={(e) => { ConfirmDeleteSlide(slide.id); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a>
                    }

                    {
                        ((owner || slide.is_my) && slide.type === 'Text' && slide.material.material_text !== '')
                        &&
                        <a data-toggle="modal" data-target="#SlideModalLong" onClick={(e) => { clearSlideError(); setSlideId(slide.id); setSlideSectionId(slide.section); setSlideTitle(slide.title); setSlideDesc(slide.description); }} className="dlt_btn"><i className="fas fa-edit"></i></a>
                    }
                    {
                        ((owner || slide.is_my) && slide.type !== 'Text' && (slide.material.material_media !== null || slide.material.material_tin_can !== null || slide.material.material_scorm !== null))
                        &&
                        <a onClick={(e) => { clearMaterialError(); setSlideId(slide.id); setSlideSectionId(slide.section); setSlideTitle(slide.title); setMaterialFile(''); setMaterialDesc(slide?.material?.material_media_name); document.getElementById('MaterialViewModalClose').click(); }} data-toggle="modal" data-target="#UploadModalLong" className="dlt_btn"><i className="fas fa-edit"></i></a>
                    }
                </td>
            </tr>
        </>
    );
}
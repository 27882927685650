import SocialLinks from "../../components/SocialLinks";
import { useForm } from "react-hook-form";
import { API_BASE, FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from "../../constants/global";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { Toaster,SwalBox } from "./../../entity/GlobalJS";

import authContext from "../../context/auth/authContext";
import { LoginSocialGoogle, LoginSocialFacebook, IResolveParams } from 'reactjs-social-login';

const SocialMediaLogin = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);

    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState('');

    const onLoginStart = useCallback(() => {
        // alert('login start')
    }, []);

    const onLogoutSuccess = useCallback(() => {
        setProfile(null)
        setProvider('')
        alert('logout success')
    }, []);

    const EnrollToCourse = async (url, _token, enrolling) => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status && data.data.enrolled) {
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Course Enrollment Confirmation',
                text: 'Congratulations! You have successfully enrolled in the course. It\'s time to begin your learning journey. You will be recirected to the course page in a moment.',
                icon: 'success',
                showCloseButton: true,
            });
            setTimeout(() => {
                SwalBox.close();
                localStorage.removeItem('new_registration');
                navigate(`/learn-course/${enrolling}`);
            }, 3000);
        } else {
            Toaster.error('You are already enrolled in this course');
            localStorage.removeItem('new_registration');
            if (data.message === "No data found.") {
                setTimeout(() => {
                    navigate(`/learn-course/${enrolling}`);
                }, 1000);
            } else {
                setTimeout(() => {
                    navigate('/dashboard/home');
                }, 1000);

            }
        }
    }

    const loginWithSocialMedia = async (provider, sMediadata) => {
        let ip = '0.0.0.0';
        /*
        let ip_response = await fetch("https://api.ipify.org/?format=json");
        ip_response = await ip_response.json();
        if (typeof ip_response != 'undefined' && typeof ip_response.ip != 'undefined') {
            ip = ip_response.ip;
        }
        */

        if (provider == "google") {
            const response = await fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + sMediadata.access_token
                },
            });

            const data = await response.json();
            if (data.id) {
                const formData = new FormData();
                formData.append('social_media_id', data.id);
                formData.append('first_name', data.given_name);
                formData.append('last_name', data.family_name);
                formData.append('email', data.email);
                formData.append('provider', provider);
                formData.append('ip', ip);
                const response = await fetch(API_BASE + 'sign-in-with-social-media', {
                    method: 'POST',
                    body: formData
                });

                const datarespo = await response.json();
                if (datarespo.status) {
                    setValue('email', '');
                    setValue('password', '');
                    Toaster.success(datarespo.message);
                    localStorage.setItem('lmstoken', datarespo.data._token);
                    localStorage.setItem('lmsuser', JSON.stringify(datarespo.data.user));
                    localStorage.setItem('lrsuser', JSON.stringify(datarespo.data.user));

                    cntxAuth.updateLogin(true);
                    cntxAuth.updateUser(datarespo.data.user);



                     var enrolling = localStorage.getItem('enrolling');
                    if (typeof enrolling !== 'undefined' && enrolling !== null) {

                        const _token = datarespo.data._token;
                        var couid = localStorage.getItem('couid');
                        var uc = localStorage.getItem('uc');
                        var url;
                        if (typeof couid !== 'undefined' && couid !== null) {
                            url = `${API_BASE}post-enroll-course/${couid}`;
                            if (typeof uc !== 'undefined' && uc !== null) {
                                url = `${API_BASE}post-enroll-course/${couid}/${uc}`;
                            }
                        }
                        EnrollToCourse(url, _token, enrolling);
                        // navigate(`/course-details/${enrolling}`);
                        // return;
                    } else {
                        setTimeout(() => {
                            navigate('/dashboard/home');
                        }, 1000);
                    }
                } else {
                    Toaster.error(datarespo.message);
                }
            }
        }
        if (provider == "facebook") {
            const response = await fetch('https://graph.facebook.com/me?fields=first_name,last_name,email&access_token=' + sMediadata.accessToken, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
            });

            const data = await response.json();
            if (data.id) {
                const formData = new FormData();
                formData.append('social_media_id', data.id);
                formData.append('first_name', data.first_name);
                formData.append('last_name', data.last_name);
                formData.append('email', data.email);
                formData.append('provider', provider);
                formData.append('ip', ip);
                const response = await fetch(API_BASE + 'sign-in-with-social-media', {
                    method: 'POST',
                    body: formData
                });

                const datarespo = await response.json();
                if (datarespo.status) {
                    setValue('email', '');
                    setValue('password', '');
                    Toaster.success(datarespo.message);
                    localStorage.setItem('lmstoken', datarespo.data._token);
                    localStorage.setItem('lmsuser', JSON.stringify(datarespo.data.user));
                    localStorage.setItem('lrsuser', JSON.stringify(response.data.user));
                    cntxAuth.updateLogin(true);
                    cntxAuth.updateUser(datarespo.data.user);

                    setTimeout(() => {
                        navigate('/dashboard/home');
                    }, 1000);
                } else {
                    Toaster.error(datarespo.message);
                }
            }
        }
    }

    useEffect(() => {
        if (cntxAuth.isLogged) {
            navigate("/");
        }
        //eslint-disable-next-line
    }, []);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    /*
    return (
        <>

            <div className="login-footer-icons">
                <ul>
                    <li><p>Sign in with</p></li>
                    <li>
                        {
                            <LoginSocialGoogle
                                isOnlyGetToken
                                client_id={GOOGLE_CLIENT_ID}
                                onLoginStart={onLoginStart}
                                scope="openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
                                onResolve={({ provider, data }: IResolveParams) => {
                                    loginWithSocialMedia(provider, data);
                                }}
                                onReject={(err) => { console.log(err) }}
                            >
                                <a type="button" className="btn btn-circle btn-login-icon"><i className="fab fa-google"></i></a>
                            </LoginSocialGoogle>
                        }
                    </li>
                    <li>
                        {
                            <LoginSocialFacebook
                                isOnlyGetToken
                                appId={FACEBOOK_APP_ID}
                                onLoginStart={onLoginStart}
                                onResolve={({ provider, data }: IResolveParams) => {
                                    loginWithSocialMedia(provider, data);
                                }}
                                onReject={(err) => {
                                    console.log(err);
                                }}
                            >
                                <a type="button" className="btn btn-circle btn-login-icon"><i className="fab fa-facebook-f"></i></a>
                            </LoginSocialFacebook>
                        }
                    </li>
                    <li>
                        <a href="http://ec2-43-205-128-232.ap-south-1.compute.amazonaws.com:9011/oauth2/authorize?client_id=794c0faf-7ceb-439a-bac4-83ed2ea6fb77&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%2Ffusiontest%2Fauthcallback.php" className="btn btn-circle btn-login-icon"><i className="fab fa-openid"></i></a>
                    </li>
                </ul>
            </div>
        </>
    );
    */


    return (
        <>
            <div className="ek-sign-up-link">
            {
            <LoginSocialGoogle
                isOnlyGetToken
                client_id={GOOGLE_CLIENT_ID}
                onLoginStart={onLoginStart}
                scope="openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
                onResolve={({ provider, data }: IResolveParams) => {
                    loginWithSocialMedia(provider, data);
                }}
                onReject={(err) => { console.log(err) }}
            >
            <span>Sign in with <i className="fab fa-google"></i></span>
            </LoginSocialGoogle>
            }
            </div>
        </>
    );










}

export default SocialMediaLogin;

import { useEffect } from "react";
import { useState } from "react";
import { API_BASE } from "../../constants/global";
import ListItem from "./ListItem";

const LearningCircleTab = (props) => {
    const [notification, setNotification] = useState('');
    const _token = localStorage.getItem('lmstoken');
    const [page, setPage] = useState(0);
    const [total_page, setTotalPage] = useState(0);

    useEffect(() => {
        fetchLearningCircleNotifications();
    }, [page]);

    const fetchLearningCircleNotifications = async () => {
        const response = await fetch(`${API_BASE}get-notification-feeds-by-tab/Learning_Circle?page=${page}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status) {
            setTotalPage(data?.data?.count);
            if (data?.data?.feeds) {
                setNotification([...notification, ...data?.data?.feeds]);
            }
            props.setLearningCircle(0);
            setTimeout(() => {
                readLearningCircleNotifications();
            }, 3000);
            setTimeout(() => {
                props.fetchUnreadNotificationCount();
            }, 200);
        }
    }

    const readLearningCircleNotifications = async () => {
        const response = await fetch(`${API_BASE}set-read-notification-feeds-by-tab/Learning_Circle`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            }
        });

        const data = await response.json();
        if (data.status) {
            // fetchLearningCircleNotifications();
        }
    }

    const nextPage = () => {
        setPage(page + 1);
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-circle" role="tabpanel" aria-labelledby="pills-circle-tab">
                <div className="course-tab-contents">
                    <div className="course-tabs-pane-block">
                        <div className="user-body">
                            <ul className="noti_box">
                                {
                                    (notification !== '' && notification !== 'undefined')
                                        ?
                                        <>
                                            {
                                                notification.map((ntfcn, index) =>
                                                    <ListItem key={index} data={ntfcn} />
                                                )
                                            }

                                            {
                                                notification.length < total_page
                                                &&
                                                <a className="cursor-pointer" onClick={(e) => { nextPage(); }}>Load more</a>
                                            }
                                        </>
                                        :
                                        <li>
                                            <div className="col-12">
                                                <div className="empty_state">
                                                    <h3>No notifications!</h3>
                                                    <img alt="" src="images/no_courses.png" />
                                                </div>
                                            </div>                                            
                                        </li>
                                }
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default LearningCircleTab;
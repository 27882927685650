import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API_BASE, IMAGE_EXT, IMAGE_TXT } from "../../constants/global";
import ImageCropper from "./ImageCropper";
import { SwalBox, Toaster } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt.ts";

const ProfilePicture = () => {
    const lUser = JSON.parse(localStorage.getItem('lmsuser'));
    const _token = localStorage.getItem('lmstoken');
    const cntxAuth = useContext(authContext);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [user_photo, setPhoto] = useState('');
    const [default_photo, setDefaultPhoto] = useState('');
    const [prev, setPrev] = useState(false);
    const [new_photo, setNewPhoto] = useState(null);
    const [cropbtnstatus, setCropbtnstatus] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);
    const [ekycstatus, seteKycStatus] = useState(false);
    const [ekycmatch, seteKycMatch] = useState(false);
    useEffect(() => {
        fetchProfile();
        checkKycStatus();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (showPrompt) {
            ConfirmSaveChanges();
        }
    }, [showPrompt]);


    const ConfirmSaveChanges = () => {
        confirmNavigation();
        /*
        SwalBox.fire({
            title: 'Changes won\'t be saved!',
            text: 'To save the changes made, click on \'Save\'; or Click on \'Cancel\' to discard the changes.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: 'Save'
        }).then((result) => {
            if (result.isConfirmed) {
                confirmNavigation();
            } else {
                confirmNavigation();
            }
        });
        */
    }

    const fetchProfile = async () => {
        const response = await fetch(`${API_BASE}get-profile-photo`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setPhoto(data.data.photo);
            setDefaultPhoto(data.data.photo);
        }
    }

    const prevImage = (e) => {
        setShowDialog(true);
        var filePath = e.target.value;
        if (!IMAGE_EXT.exec(filePath)) {
            Toaster.error(IMAGE_TXT);
            setValue('image');
            setPrev(false);
            return false;
        }
        var is_mod_image = moderateUploadedMedia();
        if (is_mod_image != false) {
            setPrev(true);
            setPhoto(URL.createObjectURL(e.target.files[0]));
        } else {
            setPrev(false);
            return false;
        }

    }
    const processSaveProfileImage = async () => {
        const formData = new FormData();
        var fileField = document.querySelectorAll('input[type="file"]')[0].files[0];
        if (new_photo !== '' && typeof new_photo !== 'undefined' && typeof new_photo !== null) {
            formData.append("image", new_photo);
        }
        if (ekycstatus == true) {
            formData.append("kycstatus", 'YES');
        }
        const response = await fetch(API_BASE + 'post-update-photo', {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            setValue('image');
            setPrev(false);
            setPhoto(data?.data?.user?.image);
            cntxAuth.updateUser(data?.data?.user);
            localStorage.setItem('lmsuser', JSON.stringify(data?.data?.user));
            Toaster.success(data.message);
            setShowDialog(false);
        } else {
            Toaster.error(data.message);
        }

    }
    const saveProfileImage = async () => {
        let _procceed_with_update = true;
        let _match_status = false;
        if (ekycstatus == true && ekycmatch == false) {
            SwalBox.fire({
                title: 'Profile Picture Update Warning',
                text: 'Important Notice: \nYour new profile picture does not match the submitted KYC document, if you still update your profile picture, you\'ll have to go through the KYC process again. \nProceed with caution. \nUpdate Profile Picture? ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonClass: 'button_confirm_class',
                cancelButtonColor: '#d33',
                cancelButtonClass: 'button_cancel_class',
                confirmButtonText: 'I understand. Update Profile Picture.',
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    processSaveProfileImage();

                } else {
                    _procceed_with_update = false;
                    setPrev(false); setPhoto(default_photo); setValue('image');
                }
            });
        } else {
            processSaveProfileImage();
        }
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    const moderateUploadedMedia = async () => {
        setCropbtnstatus('disabled')
        const formData = new FormData();
        var fileField = document.querySelectorAll('input[type="file"]')[0].files[0];
        const base64 = await convertBase64(fileField);
        if (ekycstatus == true) {
            formData.append("kycstatus", 'YES');
        }
        formData.append("image", fileField);
        formData.append("base64", base64);
        formData.append("mediatype", fileField.type);


        try {
            const response = await fetch(API_BASE + 'validate-uploaded-image', {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + _token
                },
                body: formData
            });
            const data = await response.json();
            if (data.status) {
                var _is_valid = data.data;
                seteKycMatch(data.default_match);
                if (_is_valid != "") {
                    fetchProfile();
                    SwalBox.fire({
                        title: 'Inappropriate image',
                        text: 'Your image has been removed as it contains inappropriate content (' + _is_valid + ') , please upload a valid image.',
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok'
                    });
                    return false;
                } else {
                    setCropbtnstatus('');
                }
            } else {
                setCropbtnstatus('');
            }
        } catch (error) {
            console.log(error?.message);
            setCropbtnstatus('');
        }

    }

    const checkKycStatus = async () => {
        try {
            const response = await fetch(`${API_BASE}get-kyc-status`, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });
            const data = await response.json();
            if (data.status) {
                if (data.kyc == true || data.kyc == 'true') {
                    seteKycStatus(true);
                } else {
                    seteKycStatus(false);
                }
            }
        } catch (err) { }
    }

    return (
        <div className="tab-pane fade show active" id="v-pills-profile-picture" role="tabpanel" aria-labelledby="v-pills-profile-picture-tab">
            <div className="profile-dashboard-content">
                {
                    lUser.is_corporate === 1
                        ?
                        <h4>Company Logo </h4>
                        :
                        <h4>Profile Picture </h4>
                }
                <div className="profile-dashboard-box">
                    <form onSubmit={handleSubmit(saveProfileImage)}>
                        <input type="file" {...register("image")} id="control_photo" className="d-none" accept="image/*" onChange={(e) => { prevImage(e); }} />
                        <div className="update-profile-section">
                            <div className="update-profile-block">
                                <div className="update-profile-img">
                                    <img src={user_photo} className="rounded-circle" alt="Profile Image" />
                                    {
                                        !prev
                                        &&
                                        <span onClick={(e) => { document.getElementById('control_photo').click(); }}><i className="fas fa-camera"></i></span>
                                    }
                                    {
                                        prev
                                        &&
                                        <span onClick={(e) => { setPrev(false); setPhoto(default_photo); setValue('image'); }}><i className="fa fa-undo"></i></span>
                                    }
                                </div><br />
                                {
                                    lUser.is_corporate === 1
                                    &&
                                    <p className="mt-1"><small className="dlt_btn">Please upload logo with maximum 512x512 dimension.</small></p>
                                }
                            </div>
                        </div>
                        {
                            prev
                            &&
                            <>
                                <button className="btn btn-success btn-circle btn-md">Save</button>
                                <ImageCropper cropbtnstatus={cropbtnstatus} photo={user_photo} setPhotoToShow={setPhoto} show_modal={prev} setPhotoToUpload={setNewPhoto} src={'Profile'} />
                            </>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ProfilePicture;

import SocialLinks from "../../components/SocialLinks";
import { useForm } from "react-hook-form";
import { API_BASE, GOOGLE_CLIENT_ID } from "../../constants/global";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { SwalBox, Toaster } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import SocialMediaLogin from "./SocialMediaLogin";

const Login = () => {
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);
    const [ip, setIp] = useState("0.0.0.0");
    const [passwordType, setPasswordType] = useState("password");
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (cntxAuth.isLogged) {
            navigate('/dashboard/home');
        }
        fetchIspIp()
        //eslint-disable-next-line
    }, []);

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    async function fetchIspIp() {
        try {
            let response = await fetch("https://api.ipify.org/?format=json");
            response = await response.json();
            if (typeof response != 'undefined' && typeof response.ip != 'undefined') {
                setIp(response.ip);
            }
        } catch (err) { }
    }

    const EnrollToCourse = async (url, _token, enrolling) => {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status && data.data.enrolled) {
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Course Enrollment Confirmation',
                text: 'Congratulations! You have successfully enrolled in the course. It\'s time to begin your learning journey. You will be recirected to the course page in a moment.',
                icon: 'success',
                showCloseButton: true,
            });
            setTimeout(() => {
                SwalBox.close();
                setProcessing(false);
                localStorage.removeItem('new_registration');
                navigate(`/learn-course/${enrolling}`);
            }, 3000);
        } else {
            Toaster.error('You are already enrolled in this course');
            setProcessing(false);
            localStorage.removeItem('new_registration');
            if (data.message === "No data found.") {
                Toaster.error('You are already enrolled in this course');
                setTimeout(() => {
                    navigate(`/learn-course/${enrolling}`);
                }, 1000);
            } else {
                setTimeout(() => {
                    Toaster.error('Please enter correct unique code');
                    navigate(`/course-details/${enrolling}`);
                }, 1000);

            }
        }
    }
    async function onSubmit(data) {
        data['ip'] = ip;
        setProcessing(true);
        let response = await fetch(`${API_BASE}login`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        });

        response = await response.json();

        if (response.status) {
            setValue('email', '');
            setValue('password', '');
            Toaster.success(response.message);
            localStorage.setItem('lmstoken', response.data._token);
            localStorage.setItem('lmsuser', JSON.stringify(response.data.user));
            localStorage.setItem('lrsuser', JSON.stringify(response.data.user));
            localStorage.setItem('no_of_time_login', response.data.no_of_time_login);
            localStorage.setItem('has_auto_password', response.data.has_auto_password);
            cntxAuth.updateLogin(true);
            cntxAuth.updateUser(response.data.user);


            var enrolling = localStorage.getItem('enrolling');
            if (typeof enrolling !== 'undefined' && enrolling !== null) {
                const _token = response.data._token;
                var couid = localStorage.getItem('couid');
                var uc = localStorage.getItem('uc');
                var url;
                if (typeof couid !== 'undefined' && couid !== null) {
                    url = `${API_BASE}post-enroll-course/${couid}`;
                    if (typeof uc !== 'undefined' && uc !== null) {
                        url = `${API_BASE}post-enroll-course/${couid}/${uc}`;
                    }
                }
                EnrollToCourse(url, _token, enrolling);
            }
            else {
                setTimeout(() => {
                    setProcessing(false);
                    localStorage.removeItem('new_registration');
                    navigate('/dashboard/home');
                }, 1000);
            }
        } else {
            if (typeof response?.data?.corporate_emp_status !== 'undefined' && response?.data?.corporate_emp_status !== 1) {
                employeeError(response?.data?.corporate_emp_status, response?.data?.request_auth);
            } else {
                Toaster.error(response.message);
            }
            setProcessing(false);
        }
    }

    const employeeError = (status = 0, request_auth = '') => {
        var textT = status === 2 ?
            `We regret to inform you that your account has been deleted by your company. However, we would like to provide you with the opportunity to download all your course completion certificates. To do so, please click on the download button below.`
            :
            `We regret to inform you that your account has been deactivated by your company. To reactivate your account, please contact your company administrator.`;
        SwalBox.fire({
            title: status === 2 ? 'Account has been deleted!' : 'Account has been inactivated!',
            text: textT,
            icon: status === 2 ? 'error' : 'warning',
            showCancelButton: status === 2 ? true : false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: status === 2 ? 'Download Certificates' : 'Okay',
        }).then((result) => {
            if (result.isConfirmed) {
                (status === 2) ? navigate(`/my-certificates/${request_auth}`) : navigate('/login');
            }
        });
    }

    return (
        <>
            <section>
                <div className="custom-login-section new_login_design">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="custom-login-box">
                                    <div className="login-form">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <h2>Log in to your <span>EduKula account!</span></h2>
                                            <div className="form-group">
                                                <input {...register("email", {
                                                    required: "required",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Entered value does not match email format"
                                                    }
                                                })} type="email" className="form-control ek-lrs-login-email" placeholder="Email Address"
                                                    autoComplete="off"
                                                />
                                                <span className="text-danger">
                                                    {errors.email && errors.email.type === "required" && 'Please enter email address'}
                                                    {errors.email && errors.email.type !== "required" && errors.email.message}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <input {...register("password", {
                                                    required: "required",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Password can not be less than 8 characters."
                                                    }
                                                })} type={passwordType} id="password-field" className="form-control" placeholder="Password" autoComplete="off" />
                                                <span className={`${passwordType === "password" ? "fa fa-eye " : "fa fa-eye-slash"} field-icon`} onClick={togglePassword}></span>
                                                <span className="text-danger">
                                                    {errors.password && errors.password.type === "required" && 'Please enter password.'}
                                                    {errors.password && errors.password.type !== "required" && errors.password.message}
                                                </span>
                                            </div>
                                            <div className="form-group small clearfix">
                                                <Link to="/forgot-password" className="forgot-link">Forgotten password?</Link>
                                            </div>
                                            <button disabled={processing} type="submit" className="btn btn-success ek-lrs-login">
                                                {
                                                    processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Sign in'
                                                }
                                            </button>

                                            <div className="login-footer">
                                                <div className="login-footer-text or_textline">
                                                    <p>Or</p>
                                                </div>
                                                <SocialMediaLogin />
                                                <p className="sign-up-link"><Link to="/register">Don't have an account? sign up</Link></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="custom-login-box">
                                    <div className="login-img-box">
                                        <img src="images/login-img.png" alt="Login" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Login;
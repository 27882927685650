import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { API_BASE } from "../../../../constants/global";
import { SwalBox, Toaster } from "../../../../entity/GlobalJS";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt.ts";
import Cookies from "js-cookie";

const Step2 = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const course_id = props.cid;
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue, setError, clearErrors } = useForm();

    const [video, setVideo] = useState('');
    const [youtube_link, setYoutubeLink] = useState('');
    const [video_src, setVideoSrc] = useState('');
    const [video_up, setVideoUp] = useState(false);
    const [video_changed, setVideoChanged] = useState(true);
    const [videoValidationMsg, setVideoValidationMsg] = useState('');
    const [pre_count, setPreCount] = useState(0);
    const [out_count, setOutCount] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    const [is_continue, setContinue] = useState(true);

    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);
    const [aiautofill, setAiAutoFill] = useState(null);

    useEffect(() => {
        setchAiAutofill();
        fetchCourse();
        window.scrollTo(0, 0);
        // eslint-disable-next-line 
    }, []);
    useEffect(() => {
        if (showPrompt) {
            ConfirmSaveChanges();
        }
    }, [showPrompt]);
    const setchAiAutofill = async () => {
        try {
            let ai_course = Cookies.get('ai_course_structure');
            if(ai_course != null && ai_course != ''){
                var _parsed_course = JSON.parse(ai_course);
                setAiAutoFill(null);
                setAiAutoFill(_parsed_course);
                if(_parsed_course != null){
                    setValue('description', _parsed_course.course_description);
                }
            }
        }catch(err) { }
    }

    const AiPrerequisite = async () => {
        var id = '';
        const formData2 = new FormData();
        formData2.append('prerequisite', aiautofill.course_prerequisites);
        const response2 = await fetch(`${API_BASE}post-save-prerequisites/${course_id}${id !== '' ? `/${id}` : ''}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData2
        });
        const data2 = await response2.json();
        if (data2.status) {

        }

    }
    const AiOutcomes = async () => {
        var id = '';
        const formData3 = new FormData();
        formData3.append('outcomes', aiautofill.course_objective);
        const response3 = await fetch(`${API_BASE}post-save-outcomes/${course_id}${id !== '' ? `/${id}` : ''}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData3
        });
        const data3 = await response3.json();
        if (data3.status) {
            fetchCourse();
        }

    }
    
    







    const ConfirmSaveChanges = () => {
        SwalBox.fire({
            title: 'Changes won\'t be saved!',
            text: 'To save the changes made, click on \'Save\'; or Click on \'Cancel\' to discard the changes.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: 'Save'
        }).then((result) => {
            if (result.isConfirmed) {
                saveNExit(null, true);
            } else {
                confirmNavigation();
            }
        });
    }

    const fetchCourse = async () => {
        const response = await fetch(`${API_BASE}get-course-form-data-stepTwo/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status) {
            const course = data.data;
            setValue('course_privacy', course?.course_privacy !== null ? course?.course_privacy : '');
            setValue('description', course?.description);
            setValue('youtube_link', course?.youtube_link);
            setYoutubeLink(course?.youtube_link);
            setVideoUp(course?.videoUp);
            setVideoChanged(course?.videoUp ? false : true);
            setVideoSrc(course?.videoUp ? course?.video : '');
            setchAiAutofill();
            if(aiautofill != null){
                setValue('description', aiautofill.description);
            }
        } else {
            Toaster.error(data.message);
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'video/*': ['.mp4', '.mkv']
        },
        maxFiles: 1,
        onDrop: (acceptFile, fileRejections) => {
            fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                    if (err.code === "file-invalid-type") {
                        setVideoValidationMsg(`${err.message}`);
                    }
                });
            });
            if (fileRejections.length == 0) {
                setVideoSrc(URL.createObjectURL(acceptFile[0]));
                setVideo(acceptFile[0]);
                setVideoValidationMsg('');
                setShowDialog(true);
            }
            clearErrors('course_video');
        },
    });

    const removeVideo = () => {
        setVideoSrc('');
        setVideo('');
        setVideoChanged(true);
        if (course_id !== '') {
            setVideoUp(false);
        }
    }

    const saveStepTwo = async (form) => {
        const formData = new FormData();

        clearErrors('course_video');
        if (video_up === false && video_changed && video !== '') {
            formData.append('video', video);
        }

        formData.append('course_privacy', form.course_privacy);
        formData.append('description', form.description);
        formData.append('videoUp', video_up);
        formData.append('video_changed', video_changed);
        formData.append('youtube_link', form.youtube_link);
        setSubmitting(true);
        var sURL = `${API_BASE}post-create-course-stepTwo${(course_id !== '') ? `/${course_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            setShowDialog(false);
            if (is_continue) {
                props.setStep(3);
                navigate(`/dashboard/tutor/edit-course/${course_id}/3`);
            } else {
                navigate('/dashboard/tutor/draft-courses');
            }

            setSubmitting(false);
        } else {
            setContinue(true);
            Toaster.error(data.message);
            setSubmitting(false);
        }
    }

    const saveNExit = async (e, toAnother = false) => {
        if (e !== null) e.preventDefault();
        setShowDialog(false);
        clearErrors();

        const formData = new FormData();
        formData.append('exit', 1);

        if (video_up === false && video_changed && video !== '') {
            formData.append('video', video);
        }

        formData.append('course_privacy', document?.getElementById("course_privacy")?.value);
        formData.append('description', document?.getElementById("description")?.value);
        formData.append('videoUp', video_up);
        formData.append('video_changed', video_changed);

        setSubmitting(true);
        var sURL = `${API_BASE}post-create-course-stepTwo${(course_id !== '') ? `/${course_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            if (toAnother) {
                confirmNavigation();
            } else {
                navigate('/dashboard/tutor/draft-courses');
            }
            setSubmitting(false);
        } else {
            if (toAnother) {
                confirmNavigation();
            }

            setContinue(true);
            Toaster.error(data.message);
            setSubmitting(false);
        }
    }

    function youtube_parser(url){
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    }

    return (
        <>
            <h4>Describe the Course in Detail</h4>
            <form onSubmit={handleSubmit(saveStepTwo)}>
                <div className="row">
                    <Prerequisites course_id={course_id} setPreCount={setPreCount} />
                    <Outcomes course_id={course_id} setOutCount={setOutCount} />
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor='course_privacy'>Choose Privacy</label>
                            <p>Public Courses are listed on EduKula portal and any user can enrol to it. Private courses are not listed on EduKula and only students invited can enrol to the course. </p>
                            <select
                                {...register("course_privacy", {
                                    required: "required"
                                })}
                                className="form-control"
                                id="course_privacy"
                                onChange={() => {
                                    setShowDialog(true);
                                }}
                            >
                                <option value=''>Choose privacy</option>
                                <option value={'Public'}>Public</option>
                                <option value={'Private'}>Private</option>
                            </select>
                            <span className="text-danger">
                                {errors.course_privacy?.type === "required" && 'Please select privacy.'}
                                {errors.course_privacy?.type !== "required" && errors.course_privacy?.message}
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Upload Introductory Video</label>
                            <p>Uploading an introductory video that gives a glimpse of the course will increase the chances of a student to enrol in your course.</p>
                            <span className="text-danger">
                                {errors.course_video?.type === "required" && 'Please select course photo.'}
                                {errors.course_video?.type !== "required" && errors.course_video?.message}
                            </span>
                            <p className="text-danger">{videoValidationMsg !== '' && videoValidationMsg}</p>
                            <p></p>
                            {
                                (video_up === false || video_changed)
                                &&
                                video_src === ''
                                &&
                                <>
                                    <div {...getRootProps()} className="cover-page-image">
                                        <input {...getInputProps()} />
                                        <a className="profile-img"> Course Video<span><i className="fas fa-video"></i></span> </a>
                                    </div>
                                    <p className="ek-course-yt-vid-label">OR</p>
                                    <label>Add Youtube Video Link</label>
                                    <input
                                    id="youtube_link"
                                    {...register("youtube_link", {})}
                                    type="text"
                                    className="form-control ek-course-yt-vid"
                                    placeholder="e.g. https://youtu.be/QUJ3OJZqe84"
                                    />
                                </>
                            }

                            {
                                (video_src !== '')
                                &&
                                <>
                                    <div className="video_img_box mt-2">
                                        <video controls>
                                            <source src={video_src} type="video/mp4" />
                                            Sorry, your browser doesn't support embedded videos.
                                        </video>
                                        <div onClick={(e) => { removeVideo(); }} className="btnGrp">
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                (video_src === '' && youtube_link != null && youtube_link != '')
                                &&
                                <>
                                    <div className="video_img_box mt-2">
                                        <iframe
                                        width="853"
                                        height="480"
                                        src={`https://www.youtube-nocookie.com/embed/${youtube_parser(youtube_link)}?rel=0`}
                                        frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label>Course description</label>
                            <textarea
                                id="description"
                                {...register("description", {
                                    required: "required"
                                })}
                                className="form-control"
                                placeholder="Insert your course description."
                                rows="5"
                                onChange={() => {
                                    setShowDialog(true);
                                }}
                            ></textarea>
                            <span className="text-danger">
                                {errors.description?.type === "required" && 'Please enter description.'}
                                {errors.description?.type !== "required" && errors.description?.message}
                            </span>
                        </div>
                    </div>
                </div>
                <button disabled={submitting} type="button" onClick={(e) => { props.setStep(1); }} className="btn btn-circle btn-md cancel-btn mr-2">Previous</button>
                <button disabled={submitting} type="submit" className="btn btn-circle btn-md submit-pink-btn ml-1 mr-2">{submitting ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Continue'}</button>
                <button disabled={submitting} type="submit" onClick={(e) => { saveNExit(e); }} className="btn btn-circle btn-md cancel-btn">Save and Exit</button>
            </form>
        </>
    );
}

export default Step2;

const Prerequisites = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const course_id = props.course_id;
    const [prerequisites, setPrerequisites] = useState('');
    const [new_rec, setNewRec] = useState(false);
    const [fetching, setFetching] = useState(true);

    useEffect(() => {
        fetchPrerequisites();
        // eslint-disable-next-line 
    }, []);

    const fetchPrerequisites = async () => {
        setFetching(true);
        const response = await fetch(`${API_BASE}get-prerequisites/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        if (data.status) {
            setFetching(false);
            setPrerequisites(data.data.prerequisites);
        } else {
            setFetching(false);
            setPrerequisites('');
        }
    }

    const updateInputBox = async (id, description) => {
        const formData = new FormData();
        formData.append('prerequisite', description);

        const response = await fetch(`${API_BASE}post-save-prerequisites/${course_id}${id !== '' ? `/${id}` : ''}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();

        if (data.status) {
            setPrerequisites(data.data.prerequisites);
        } else {
            Toaster.error(data.message);
        }
    }

    const deletePrerequisite = async (id) => {
        const response = await fetch(`${API_BASE}delete-prerequisites/${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status && data.data.deleted) {
            Toaster.success(data.message);
            fetchPrerequisites();
        } else {
            Toaster.error(data.message);
        }
    }

    return (
        <div className="col-lg-12">
            <div className="form-group">
                <label>List Requirements or prerequisites for taking your course</label>
                <p>List down the required tools, skills, and level of experience the learners should have prior to taking your course. <br />If there are no requirements, use this space to highlight how the course is accessible to all.</p>
                {
                    fetching
                        ?
                        <>
                            <p className="text-center"><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...</p>
                        </>
                        :
                        <>
                            {
                                prerequisites !== ''
                                    ?
                                    prerequisites.map((prerequisite, index) =>
                                        <InputBox key={index} id={prerequisite.id} value={prerequisite.description} updateInputBox={updateInputBox} DeleteItemBox={deletePrerequisite} isNew={false} place="Example: No programming experience needed. You will learn everything you need to know" />
                                    )
                                    :
                                    null
                            }

                            {
                                (prerequisites === '' || new_rec)
                                &&
                                <InputBox id={''} value={''} isNew={true} updateInputBox={updateInputBox} setNewRec={setNewRec} place="Example: No programming experience needed. You will learn everything you need to know" />
                            }

                            {
                                prerequisites !== '' && !new_rec
                                &&
                                <div className="response addmtyr"><a className="text-danger cursor-pointer " onClick={(e) => { setNewRec(true); }}><i className="fas fa-plus"></i> Add More To Your Response</a></div>
                            }
                        </>
                }
            </div>
        </div>
    );
}

const Outcomes = (props) => {
    const _token = localStorage.getItem('lmstoken');
    const course_id = props.course_id;
    const [outcomes, setOutcomes] = useState('');
    const [new_rec, setNewRec] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [outcome_count, setOutcomeCount] = useState(0);

    useEffect(() => {
        fetchOutcomes();
        // eslint-disable-next-line 
    }, []);

    const fetchOutcomes = async () => {
        setFetching(true);
        const response = await fetch(`${API_BASE}get-outcomes/${course_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            setFetching(false);
            setOutcomes(data.data.outcomes);
            setOutcomeCount(data.data.count);
        } else {
            setFetching(false);
            setOutcomes('');
        }
    }

    const updateInputBox = async (id, description) => {
        const formData = new FormData();
        formData.append('outcomes', description);

        const response = await fetch(`${API_BASE}post-save-outcomes/${course_id}${id !== '' ? `/${id}` : ''}`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();

        if (data.status) {
            fetchOutcomes();
        } else {
            Toaster.error(data.message);
        }
    }

    const deleteOutcome = async (id) => {
        const response = await fetch(`${API_BASE}delete-outcomes/${id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status && data.data.deleted) {
            Toaster.success(data.message);
            fetchOutcomes();
        } else {
            Toaster.error(data.message);
        }
    }

    return (
        <>
            <div className="col-lg-12">
                <div className="form-group">
                    <label>What will students learn in your course?</label>
                    <p>You must enter at least 3 key concepts or skills that learners will come in possession of after completing your course.</p>
                    {
                        fetching
                            ?
                            <>
                                <p className="text-center"><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Loading...</p>
                            </>
                            :
                            <>
                                {
                                    outcomes !== ''
                                    &&
                                    outcomes.map((outcome, index) =>
                                        <InputBox key={index} id={outcome.id} value={outcome.description} updateInputBox={updateInputBox} DeleteItemBox={deleteOutcome} isNew={false} place="Example: Fundamentals of graphic design and image editing." />
                                    )
                                }
                                {
                                    outcomes !== '' && outcome_count < 3
                                    &&
                                    <>
                                        {
                                            outcome_count < 2
                                                ?
                                                <>
                                                    <InputBox id={''} isNew={true} hideRemove={true} updateInputBox={updateInputBox} setNewRec={setNewRec} place="Example: Fundamentals of graphic design and image editing." />
                                                    <InputBox id={''} isNew={true} hideRemove={true} updateInputBox={updateInputBox} setNewRec={setNewRec} place="Example: How to navigate the Photoshop interface." />
                                                </>
                                                :
                                                <InputBox id={''} isNew={true} hideRemove={true} updateInputBox={updateInputBox} setNewRec={setNewRec} place="Example: Techniques for selecting and manipulating images." />
                                        }
                                    </>
                                }

                                {
                                    (outcomes === '')
                                    &&
                                    <>
                                        <InputBox id={''} isNew={true} hideRemove={true} updateInputBox={updateInputBox} setNewRec={setNewRec} place="Example: Fundamentals of graphic design and image editing." />
                                        <InputBox id={''} isNew={true} hideRemove={true} updateInputBox={updateInputBox} setNewRec={setNewRec} place="Example: How to navigate the Photoshop interface." />
                                        <InputBox id={''} isNew={true} hideRemove={true} updateInputBox={updateInputBox} setNewRec={setNewRec} place="Example: Techniques for selecting and manipulating images." />
                                    </>
                                }
                                {
                                    new_rec
                                    &&
                                    <InputBox id={''} isNew={true} updateInputBox={updateInputBox} setNewRec={setNewRec} place="Example: Fundamentals of graphic design and image editing." />
                                }

                                {
                                    outcomes !== '' && !new_rec && outcome_count >= 3
                                    &&
                                    <div className="response addmtyr"><a className="text-danger cursor-pointer " onClick={(e) => { setNewRec(true); }}><i className="fas fa-plus "></i> Add More To Your Response</a></div>
                                }
                            </>
                    }
                </div>
            </div>
        </>
    );
}

const InputBox = (props) => {
    const [editing, setEditing] = useState(false);
    const [input_value, setInputValue] = useState(props.value);

    const ConfirmDelete = (id) => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: 'Sure to delete key point?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                props.DeleteItemBox(id);
            }
        });
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            try{
                event.preventDefault();
                if (typeof(input_value) != 'undefined' && input_value.trim() !== '') {
                    props.updateInputBox(props.id, input_value);
                    setEditing(false);
                    props.setNewRec(false);
                }
            }catch(e){ }
        }
    }
    const handleBlur = (event) => {
        try{
            event.preventDefault();
            if (typeof(input_value) != 'undefined' && input_value.trim() !== '') {
                props.updateInputBox(props.id, input_value);
                setEditing(false);
                props.setNewRec(false);
            }
        }catch(e){ }
    }

    return (
        <>
            <div className="response add_edit_box">
                <input disabled={!editing && !props.isNew} value={input_value} onChange={(e) => { setInputValue(e.target.value); }} onBlur={(event) => { handleBlur(event); }} onKeyDown={(event) => { handleKeyDown(event); }} type="text" className="form-control" placeholder={props?.place ? props?.place : ''} />
                {
                    (!editing && !props.isNew)
                    &&
                    <>
                        <a onClick={(e) => { setEditing(true); }} className="dlt_edt"><i className="fas fa-edit"></i></a>
                        <a onClick={(e) => { ConfirmDelete(props.id); }} className="dlt_btn"><i className="fas fa-trash-alt"></i></a>
                    </>
                }

                {
                    (editing || props.isNew)
                    &&
                    <>
                        <a onClick={(e) => { if (input_value.trim() !== '') { setEditing(false); props.setNewRec(false); } }} className="dlt_rgt"><i className="fa fa-check"></i></a>
                        {
                            props.hideRemove && typeof props.hideRemove !== 'undefined'
                                ?
                                <></>
                                :
                                <a onClick={(e) => { setInputValue(props.value); setEditing(false); props.setNewRec(false); }} className="dlt_btn"><i className="fas fa-times"></i></a>
                        }
                    </>
                }
            </div>
        </>
    );
}
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API_BASE, IMAGE_EXT, IMAGE_TXT } from "../../constants/global";
import ImageCropper from "./ImageCropper";
import { SwalBox, Toaster } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt.ts";

const Ekyc = () => {
    const lUser = JSON.parse(localStorage.getItem('lmsuser'));
    const _token = localStorage.getItem('lmstoken');
    const cntxAuth = useContext(authContext);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [user_photo, setPhoto] = useState('');
    const [default_photo, setDefaultPhoto] = useState('');
    const [prev, setPrev] = useState(false);
    const [new_photo, setNewPhoto] = useState(null);
    const [cropbtnstatus, setCropbtnstatus] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);

    const [default_kyc_user_photo, setDefaultKycUserPhoto] = useState('');
    const [default_kyc_user_identity, setDefaultKycUserIdentity] = useState('');
    const [kyc_user_photo, setKycUserPhoto]         = useState('');
    const [kyc_user_identity, setKycUserIdentity]   = useState('');


    const [isValidKycPhoto, setValidKycPhoto] = useState(false);
    const [isValidKycDoc, setValidKycDoc] = useState(false);

    const [baseKycPhoto, setBaseKycPhoto] = useState('');
    const [baseKycDoc, setBaseKycDoc] = useState('');
    const [baseKycPhotoType, setBaseKycPhotoType] = useState('');
    const [baseKycDocType, setBaseKycDocType] = useState('');

    const [isKycApproved, setKycApproved] = useState(false);
    const [isValidProfilePic, setIsValidProfilePic] = useState(false);

    useEffect(() => {
          isValidProfilePhoto();
    }, []);

    useEffect(() => {
        if (showPrompt) {
            ConfirmSaveChanges();
        }
    }, [showPrompt]);
    const ConfirmSaveChanges = () => {
        handleSubmit(saveProfileImage)();
        confirmNavigation();
        /*
        SwalBox.fire({
            title: 'Changes won\'t be saved!ttt',
            text: 'To save the changes made, click on \'Save\'; or Click on \'Cancel\' to discard the changes.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: 'Save'
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmit(saveProfileImage)();
                confirmNavigation();
            } else {
                confirmNavigation();
            }
        });
        */    
    } 
    const fetchProfile = async () => {
        try {
            const response = await fetch(`${API_BASE}get-kyc-status`, {
                mode:'cors',
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });
            const data = await response.json();
            if (data.status) {
                if(data.kyc == true || data.kyc == 'true'){
                    setKycApproved(true);
                }
            }

        }catch(err) {	}
        setKycUserPhoto('placeholder/ek-kyc-photo.jpg');
        setKycUserIdentity('placeholder/ek-kyc-identity.jpg')
    }
    
    const isValidProfilePhoto = async () => {
        setKycUserPhoto('placeholder/ek-kyc-photo.jpg');
        setKycUserIdentity('placeholder/ek-kyc-identity.jpg');
        const response = await fetch(`${API_BASE}check-kyc-profile-photo`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
 
        const data = await response.json();
        if (data.status) {
            if(data.is_valid == true){
                setIsValidProfilePic(true);
                fetchProfile();
            }else{
                SwalBox.fire({
                    title: 'Invalid profile picture.',
                    text: 'In order to start eKYC, you must upload profile photo that has your face in it.',
                    icon: 'warning',
                });
            }
        }
    }
    
    const prevKycPhoto = (e) => {
        setShowDialog(true);
        var filePath = e.target.value;
        if (!IMAGE_EXT.exec(filePath)) {
            Toaster.error(IMAGE_TXT);
            setValue('image');
            setPrev(false);
            return false;
        }
        var is_mod_image = verifyUserPhoto();
        if(is_mod_image != false){
            //setPrev(true);
            verifyUserPhoto();
            setKycUserPhoto(URL.createObjectURL(e.target.files[0]));
        }else{
            //setPrev(false);
            return false;
        }
    }
    const prevKycIdentity = (e) => {
        setShowDialog(true);
        var filePath = e.target.value;
        if (!IMAGE_EXT.exec(filePath)) {
            Toaster.error(IMAGE_TXT);
            setValue('image');
            setPrev(false);
            return false;
        }
        var is_mod_image = verifyUserIdentity();
        if(is_mod_image != false){
            //setPrev(true);
            verifyUserIdentity()
            setKycUserIdentity(URL.createObjectURL(e.target.files[0]));
        }else{
            //setPrev(false);
            return false;
        }
    }
    const saveProfileImage = async () => {
        /*
        const formData = new FormData();
        var fileField = document.querySelectorAll('input[type="file"]')[0].files[0];
        if (new_photo !== '' && typeof new_photo !== 'undefined' && typeof new_photo !== null) {
            formData.append("image", new_photo);
        }

        const response = await fetch(API_BASE + 'post-update-photo', {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            setValue('image');
            setPrev(false);
            setPhoto(data?.data?.user?.image);
            cntxAuth.updateUser(data?.data?.user);
            localStorage.setItem('lmsuser', JSON.stringify(data?.data?.user));
            Toaster.success(data.message);
            setShowDialog(false);
        } else {
            Toaster.error(data.message);
        }
        */
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    
    
    const verifyUserPhoto = async () => {
        setCropbtnstatus('disabled')
        const formData = new FormData();
        var fileField = document.querySelectorAll('#control_photo')[0].files[0];
        const base64 = await convertBase64(fileField);
        formData.append("image", fileField);
        formData.append("base64", base64);
        formData.append("mediatype", fileField.type);       
        //setValidKycPhoto(true);
        
        setBaseKycPhoto(base64);
        setBaseKycPhotoType(fileField.type);
        const response = await fetch(`${API_BASE}validate-uploaded-image`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            var _is_valid = data.data;
            if(_is_valid != ""){
                fetchProfile();
                SwalBox.fire({
                    title: 'Inappropriate image',
                    text: 'Your image has been removed as it contains inappropriate content ('+_is_valid+ ') , please upload a valid image.',
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                });
                setValidKycPhoto(false);
            }else{
                setValidKycPhoto(true);
            }
        }
        
        
    }

    const verifyUserIdentity = async () => {
        setCropbtnstatus('disabled')
        const formData = new FormData();
        var fileField = document.querySelectorAll('#control_identity')[0].files[0];
        const base64 = await convertBase64(fileField);
        formData.append("image", fileField);
        formData.append("base64", base64);
        formData.append("mediatype", fileField.type);
        //setValidKycDoc(true);
        
        setBaseKycDoc(base64);
        setBaseKycDocType(fileField.type);
        const response = await fetch(`${API_BASE}validate-uploaded-image`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + _token
            },
            body: formData
        });
        const data = await response.json();
        if (data.status) {
            var _is_valid = data.data;
            if(_is_valid != ""){
                fetchProfile();
                SwalBox.fire({
                    title: 'Inappropriate image',
                    text: 'Your image has been removed as it contains inappropriate content ('+_is_valid+ ') , please upload a valid image.',
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                });
                setValidKycDoc(false);
            }else{
                setValidKycDoc(true);
            }
        } 
        
        
    }

    const confirmKycSubmission = async () => {
        if(isValidKycPhoto == true && isValidKycDoc == true){
            SwalBox.fire({
                title: 'Important Notice - KYC Verification Process',
                text: 'Kindly note that once your KYC verification process is completed, it will not be possible to change your first name or last name without re-verifying your identity.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonClass: 'button_confirm_class',
                cancelButtonColor: '#d33',
                cancelButtonClass: 'button_cancel_class',
                confirmButtonText: 'Procceed with e-KYC',
                cancelButtonText: 'Abort e-KYC'
            }).then((result) => {
                if (result.isConfirmed) {
                    SwalBox.close();
                    SwalBox.fire({
                        title: 'Please wait...',
                        text:'We are verifying your documents... please wait',
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        onBeforeOpen: () => {
                            SwalBox.showLoading();
                        }
                    });
                    processKycDocuments();
                } else {
                    setKycUserPhoto('placeholder/ek-kyc-photo.jpg');
                    setKycUserIdentity('placeholder/ek-kyc-identity.jpg');
                    SwalBox.close();
                }
            });
        }else{
            SwalBox.fire({
                title: "Documents Required",
                icon: 'error',
                text:'Please upload your documents before submitting.',
                showCancelButton: false,
                showConfirmButton: false,
                shoOkButton: false
            });
            setTimeout(function(){ 
                SwalBox.close();
                setKycUserPhoto('placeholder/ek-kyc-photo.jpg');
                setKycUserIdentity('placeholder/ek-kyc-identity.jpg');
            }, 3000);
        }
    }

    const processKycDocuments = async () => {
        if(isValidKycPhoto == true && isValidKycDoc == true){
            const formData = new FormData();
            var fileField_photo     = document.querySelectorAll('#control_photo')[0].files[0];
            var fileField_identity  = document.querySelectorAll('#control_identity')[0].files[0];
            const base64Photo       = baseKycPhoto;
            const base64Identity    = baseKycDoc;
            const base64PhotoType       = baseKycPhotoType;
            const base64IdentityType    = baseKycDocType;
            formData.append("image_photo", fileField_photo);
            formData.append("image_identity", fileField_identity);
            formData.append("base64_photo", base64Photo);
            formData.append("base64_identity", base64Identity);
            formData.append("mediatype_photo", base64PhotoType);
            formData.append("mediatype_identity", base64IdentityType);
            const response = await fetch(`${API_BASE}validate-kyc-document`, {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + _token
                },
                body: formData
            });
            const data = await response.json();
            if (data.status) {
                SwalBox.close();
                var _kyc_message = data.message;
                if(_kyc_message == 'PASSED'){
                    SwalBox.fire({
                        title: "e-KYC Status",
                        icon: 'success',
                        text:'Your e-KYC has been approved.',
                        showCancelButton: false,
                        showConfirmButton: false,
                        shoOkButton: false
                    });
                    setKycApproved(true);
                }else if(_kyc_message == 'FAILED'){
                    if(data.reason != ""){
                        var _text = data.reason;
                    }else{
                        var _text ='Your e-KYC has been rejected, please try again.';
                    }
                    SwalBox.fire({
                        title: "e-KYC Status",
                        icon: 'error',
                        text:_text,
                        showCancelButton: false,
                        showConfirmButton: false,
                        shoOkButton: false
                    });
                }else if(_kyc_message == 'ERROR'){ 
                    if(data.reason != ""){
                        var _text = data.reason;
                    }else{
                        var _text ='The document you have submitted do not have your face, please upload valid documents.';
                    }
                    SwalBox.fire({
                        title: "e-KYC Status",
                        icon: 'error',
                        text:_text,
                        showCancelButton: false,
                        showConfirmButton: false,
                        shoOkButton: false
                    });

                }else if(_kyc_message == 'NOT STARTED'){ 
                    SwalBox.fire({
                        title: "e-KYC Status",
                        icon: 'info',
                        text:'Your documents are being verified.',
                        showCancelButton: false,
                        showConfirmButton: false,
                        shoOkButton: false
                    });
                }else{

                }
                var _is_valid = data.data;
                console.info(_is_valid);
                setTimeout(function(){ 
                    SwalBox.close();
                    setKycUserPhoto('placeholder/ek-kyc-photo.jpg');
                    setKycUserIdentity('placeholder/ek-kyc-identity.jpg');
                }, 3000);
            }else{
                SwalBox.close();
                SwalBox.fire({
                    title: "e-KYC Failed",
                    icon: 'error',
                    text:'The documents you have submitted are invalid or do not full-fill our e-KYC requirement. .',
                    showCancelButton: false,
                    showConfirmButton: false,
                    shoOkButton: false
                });
                setTimeout(function(){ 
                    SwalBox.close();
                    setKycUserPhoto('placeholder/ek-kyc-photo.jpg');
                    setKycUserIdentity('placeholder/ek-kyc-identity.jpg');
                }, 3000);
            }
        }else{
            SwalBox.fire({
                title: "Documents Required",
                icon: 'error',
                text:'Please upload your documents before submitting.',
                showCancelButton: false,
                showConfirmButton: false,
                shoOkButton: false
            });
            setTimeout(function(){ 
                SwalBox.close();
                setKycUserPhoto('placeholder/ek-kyc-photo.jpg');
                setKycUserIdentity('placeholder/ek-kyc-identity.jpg');
            }, 3000);
        }
    }

    return (
        <div className="tab-pane fade show active" id="v-pills-profile-picture" role="tabpanel" aria-labelledby="v-pills-profile-picture-tab">
            <div className="profile-dashboard-content">
                <h4>eKYC</h4>
                                    
                {
                isKycApproved == false
                &&
                <>    
                    <p>Upload the documents as mentioned below.( in .jpg/.png format only)</p>
                    <div className="profile-dashboard-box">
                            <div className="row mb-4 pt-0">
                                <div className="col-lg-6">
                                    <input type="file" {...register("image")} id="control_photo" className="d-none" accept="image/*" onChange={(e) => { prevKycPhoto(e); }} />
                                    <div className="update-profile-section">
                                        <div className="update-profile-block">
                                            <div className="update-profile-img">
                                                <img src={kyc_user_photo} className="ek-rounded-rec" alt="Profile Image" />
                                                {
                                                !prev
                                                &&
                                                <span onClick={(e) => { document.getElementById('control_photo').click(); }}><i className="fas fa-upload"></i></span>
                                                }
                                                {
                                                prev
                                                &&
                                                <span onClick={(e) => { setPrev(false); setKycUserPhoto(default_kyc_user_photo); setValue('image'); }}><i className="fa fa-undo"></i></span>
                                                }
                                                <p>Your Photo</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <input type="file" {...register("image")} id="control_identity" className="d-none" accept="image/*" onChange={(e) => { prevKycIdentity(e); }} />
                                    <div className="update-profile-section">
                                        <div className="update-profile-block">
                                            <div className="update-profile-img">
                                                <img src={kyc_user_identity} className="ek-rounded-rec" alt="Profile Image" />
                                                {
                                                !prev
                                                &&
                                                <span onClick={(e) => { document.getElementById('control_identity').click(); }}><i className="fas fa-upload"></i></span>
                                                }
                                                {
                                                prev
                                                &&
                                                <span onClick={(e) => { setPrev(false); setKycUserIdentity(default_kyc_user_identity); setValue('image'); }}><i className="fa fa-undo"></i></span>
                                                }
                                                <p>A Valid Identity Proof</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                isValidProfilePic == true
                                &&
                                <>    
                                <div className="row mb-4 pt-0">
                                    <div className="col-lg-12 ek-align-center">
                                        <button onClick={(e) => { confirmKycSubmission(); }} className="btn btn-info btn-circle btn-md">Submit</button>
                                    </div>
                                </div>
                                </>
                            }
                            <div className="row mb-4 pt-0">
                                <div className="col-lg-12 ek-align-center">
                                <p>Here are sample documents for eKYC. <a className="ek-red-col-text" href="placeholder/sample-photo.jpg" target="_blank">Sample Photo</a> &  <a className="ek-red-col-text" href="placeholder/sample-card.jpg" target="_blank">Sample ID</a> </p>
                                </div>
                            </div>
                     </div>
                </>
                }
                {
                isKycApproved == true
                &&
                <>    
                <div className="profile-dashboard-box">
                    <div className="row mb-4 pt-0">
                        <div className="col-lg-6">
                            <p>Your e-KYC has already been approved.</p>
                        </div>
                    </div>
                </div>
                </>
                }
            </div>
        </div>
    );
}

export default Ekyc;
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE } from '../../constants/global';
import { Toaster } from '../../entity/GlobalJS';

const UserPref = () => {
    const { _token } = useParams();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const new_registration = localStorage.getItem('new_registration');

    const { register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        if (typeof new_registration === 'undefined' || !new_registration) {
            navigate(`/login`);
        }
        //eslint-disable-next-line
    }, []);

    const onSubmit = async (data) => {
        setProcessing(true);
        let response = await fetch(`${API_BASE}update-teaching/${_token}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        });

        response = await response.json();

        if (response.status) {
            setProcessing(false);
            Toaster.success(response.message);
            localStorage.setItem('lmsuser', JSON.stringify(response.data.user));
            localStorage.setItem('lrsuser', JSON.stringify(response.data.user));
            localStorage.removeItem('new_registration');
            navigate(`/dashboard/home`);
        } else {
            setProcessing(false);
            Toaster.error(response.message);
        }
    }

    const skip = () => {
        localStorage.removeItem('new_registration');
        navigate(`/dashboard/home`);
    }

    return (
        <section>
            <div className="step_3_box">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="custom-login-box">
                                <div className="login-form">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <h2>Would you like to become a tutor?</h2>
                                        <div className="form-group">
                                            <label>Share your knowledge and earn money by teaching on EduKula! You can create and sell a course on our platform, regardless of your expertise or teaching experience.</label>
                                            <div className="check_box_select">
                                                <label className="form-check-label"htmlFor="inlineRadio1">
                                                    <input {...register('is_tutor', { required: "required" })} className="form-check-input" type="radio" id="inlineRadio1" value="yes" />
                                                    <span>Yes, I'm interested in teaching on EduKula.</span>
                                                </label>
                                            </div>
                                            <div className="check_box_select">
                                                <label className="form-check-label"htmlFor="inlineRadio2">
                                                    <input {...register('is_tutor', { required: "required" })} className="form-check-input" type="radio" id="inlineRadio2" value="no" />
                                                    <span>No, I'm not interested in teaching right now.</span>
                                                </label>
                                            </div>
                                            <div className="clearfix"></div>
                                            <span className="text-danger">
                                                {errors.is_tutor && errors.is_tutor.type === "required" && 'Please select any one option.'}
                                                {errors.is_tutor && errors.is_tutor.type !== "required" && errors.is_tutor.message}
                                            </span>
                                        </div>
                                        {/* <a onClick={skip} className="btn btn-circle btn-warning cursor-pointer mb-2">Skip for now</a> */}
                                        <button disabled={processing} type="submit" className="btn btn-circle btn-success">
                                            {processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Finish'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UserPref
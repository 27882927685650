import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { Toaster, SwalBox } from "./../../entity/GlobalJS";
import authContext from "../../context/auth/authContext";
import { API_BASE } from "../../constants/global";
import useEffectOnce from "../../chat/useEffectOnce";
import { useForm } from "react-hook-form";

const VerifyEmail = () => {
    const { _token } = useParams();
    const navigate = useNavigate();
    const cntxAuth = useContext(authContext);

    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [errorMsg, setErrorMsg] = useState("");
    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const inputRef5 = useRef(null);
    const inputRef6 = useRef(null);

    const [ip, setIp] = useState("");
    const [processing, setProcessing] = useState(false);
    const [email, setEmail] = useState('');
    const { register, handleSubmit, formState: { errors }, setValue, watch, clearErrors } = useForm();

    useEffectOnce(() => {
        if (typeof _token === 'undefined') {
            navigate('/login');
        }

        if (cntxAuth.isLogged) {
            navigate('/dashboard/home');
        }

        checkUser();
        fetchIspIp();
        autofillCode();
        //eslint-disable-next-line
    }, []);

    const handleChange = (event, index) => {
        const value = event.target.value;
        if (value.length <= 1) {
            setOtp((prevOtp) => {
                const newOtp = [...prevOtp];
                newOtp[index] = value;
                return newOtp;
            });

            if (value.length === 1) {
                switch (index) {
                    case 0:
                        inputRef2.current.focus();
                        break;
                    case 1:
                        inputRef3.current.focus();
                        break;
                    case 2:
                        inputRef4.current.focus();
                        break;
                    case 3:
                        inputRef5.current.focus();
                        break;
                    case 4:
                        inputRef6.current.focus();
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const handleBlur = () => {
        // check if any input box has no value entered
        if (otp.some((value) => value === "")) {
            setErrorMsg("Please enter all OTP digits.");
        } else {
            setErrorMsg("");
        }
    };

    const checkUser = async () => {
        let response = await fetch(`${API_BASE}verify-userid/${_token}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        response = await response.json();

        if (response.status) {
            //console.log(response.data.verified);
            if (response.data.verified) {
                // navigate('/');
            }

            setEmail(response.data.email);
        } else {
            Toaster.error(response.message);
        }
    }

    const fetchIspIp = async () => {
        let response = await fetch("https://api.ipify.org/?format=json");
        response = await response.json();
        if (typeof response != 'undefined' && typeof response.ip != 'undefined') {
            setIp(response.ip);
        }
    }

    const EnrollToCourse = async (url, _token, enrolling) => {
        setProcessing(false);
        Toaster.success('Verification successful.');
        localStorage.setItem('new_registration', true);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });
        const data = await response.json();
        if (data.status && data.data.enrolled) {
            SwalBox.fire({
                allowOutsideClick: false,
                title: 'Course Enrollment Confirmation',
                text: 'Congratulations! You have successfully enrolled in the course. It\'s time to begin your learning journey. You will be recirected to the course page in a moment.',
                icon: 'success',
                showCloseButton: true,
            });
            setTimeout(() => {
                SwalBox.close();
                setProcessing(false);
                navigate(`/learn-course/${enrolling}`);
            }, 3000);
        } else {
            Toaster.error(data.message);
        }
        return;
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        if (otp.some((value) => value === "")) {
            setErrorMsg("Please enter all OTP digits.");
            return false;
        } else {
            setErrorMsg("");
        }

        const code = otp.join("");

        var data = new FormData;
        data.append('ip', ip);
        data.append('code', code);

        setProcessing(true);
        let response = await fetch(`${API_BASE}verify-email/${_token}`, {
            method: 'POST',
            headers: {},
            body: data
        });

        response = await response.json();

        if (response.status) {
            localStorage.setItem('lmstoken', response.data._token);
            localStorage.setItem('lmsuser', JSON.stringify(response.data.user));
            localStorage.setItem('lrsuser', JSON.stringify(response.data.user));

            cntxAuth.updateLogin(true);
            cntxAuth.updateUser(response.data.user);

            var enrolling = localStorage.getItem('enrolling');
            if (typeof enrolling !== 'undefined' && enrolling !== null) {
                const _token = response.data._token;

                var couid = localStorage.getItem('couid');
                var uc = localStorage.getItem('uc');
                var url;
                if (typeof couid !== 'undefined' && couid !== null) {
                    url = `${API_BASE}post-enroll-course/${couid}`;
                    if (typeof uc !== 'undefined' && uc !== null) {
                        url = `${API_BASE}post-enroll-course/${couid}/${uc}`;
                    }
                }
                EnrollToCourse(url, _token, enrolling);
            } else {
                setTimeout(() => {
                    setProcessing(false);
                    Toaster.success('Verification successful.');
                    localStorage.setItem('new_registration', true);
                    if (response?.data?.user?.is_corporate === 1) {
                        navigate(`/register-steps/corporate-preference/${_token}`);
                    } else {
                        navigate(`/register-steps/career-preference/${_token}`);
                    }
                }, 1000);
            }
        } else {
            setProcessing(false);
            setErrorMsg(response.message);
        }
    }

    const resendCode = async () => {
        let response = await fetch(`${API_BASE}resend-code/${_token}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        response = await response.json();

        if (response.status) {
            Toaster.success(response.message);
        } else {
            setProcessing(false);
            Toaster.error(response.message);
        }
    }
    const autofillCode = async () => {

        const ek_search = window.location.search;
        const ek_params = new URLSearchParams(ek_search);
        const ek_otp = atob(ek_params.get('otp'));
        handleBlur();
        const myArray = ek_otp.split("");
        setOtp(myArray);
        var element = document.getElementById('opt_ref_1');
        var event = new Event('change');
        element.dispatchEvent(event);
        document.getElementById("opt_ref_1").value = "-";
        document.getElementById("opt_ref_1").value = parseInt(myArray[0]);

        document.getElementById("opt_ref_2").value = "-";
        document.getElementById("opt_ref_2").value = myArray[1];

        document.getElementById("opt_ref_3").value = "-";
        document.getElementById("opt_ref_3").value = myArray[2];

        document.getElementById("opt_ref_4").value = "-";
        document.getElementById("opt_ref_4").value = myArray[3];

        document.getElementById("opt_ref_5").value = "-";
        document.getElementById("opt_ref_5").value = myArray[4];

        document.getElementById("opt_ref_6").value = "-";
        document.getElementById("opt_ref_6").value = myArray[5];


    }

    return (
        <>
            <section>
                <div className="step_1_box">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="custom-login-box">
                                    <div className="login-form">
                                        <form onSubmit={onSubmit}>
                                            <h2>Confirm your email</h2>
                                            <p className="otptext">Type in the code we sent to<br /><strong>{email}</strong></p>
                                            <div className="form-group">
                                                <input id="opt_ref_1" type="number" value={otp[0]} onChange={(event) => handleChange(event, 0)} maxLength="1" pattern="[0-9]" ref={inputRef1} onBlur={handleBlur} className="form-control no-spinner" placeholder="0" autoFocus={true} />
                                                <input id="opt_ref_2" type="number" value={otp[1]} onChange={(event) => handleChange(event, 1)} maxLength="1" pattern="[0-9]" ref={inputRef2} onBlur={handleBlur} className="form-control no-spinner" placeholder="0" />
                                                <input id="opt_ref_3" type="number" value={otp[2]} onChange={(event) => handleChange(event, 2)} maxLength="1" pattern="[0-9]" ref={inputRef3} onBlur={handleBlur} className="form-control no-spinner" placeholder="0" />
                                                <input id="opt_ref_4" type="number" value={otp[3]} onChange={(event) => handleChange(event, 3)} maxLength="1" pattern="[0-9]" ref={inputRef4} onBlur={handleBlur} className="form-control no-spinner" placeholder="0" />
                                                <input id="opt_ref_5" type="number" value={otp[4]} onChange={(event) => handleChange(event, 4)} maxLength="1" pattern="[0-9]" ref={inputRef5} onBlur={handleBlur} className="form-control no-spinner" placeholder="0" />
                                                <input id="opt_ref_6" type="number" value={otp[5]} onChange={(event) => handleChange(event, 5)} maxLength="1" pattern="[0-9]" ref={inputRef6} onBlur={handleBlur} className="form-control no-spinner" placeholder="0" />
                                            </div>
                                            <div className="w-100 text-center mb-2">{errorMsg ? <span className="text-center w-100 text-danger">{errorMsg}</span> : null}</div>

                                            <div className="dont_recev clearfix">
                                                <p>Didn't receive the code? <a className="cursor-pointer" onClick={resendCode}>send again</a></p>
                                            </div>
                                            <button type="submit" className="btn btn-circle btn-success">
                                                {processing ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : <>Verify and Confirm</>}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default VerifyEmail;

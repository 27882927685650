import { useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import CourseCard from "../../components/CourseCard";
import PageBanner from "../../components/PageBanner";
import { API_BASE } from "../../constants/global";
import ExploreCourses from "./ExploreCourses";

const CategoryList = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [reload, setReload] = useState(false);

    const { slug } = useParams();
    const [category, setCategory] = useState('');
    const type = (window.location.href.indexOf("sub-category") >= 0) ? 'sc' : 'c';
    const location = useLocation();

    useLayoutEffect(() => {
        fetchCategoryCourse();
    }, [location, reload]);

    async function fetchCategoryCourse() {
        const response = await fetch(`${API_BASE}get-category-details/${slug}/${(window.location.href.indexOf("sub-category") >= 0) ? true : false}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setCategory('');
            setTimeout(() => {
                setCategory(data.data.category);
            }, 200);
        } else {
            setCategory('');
        }
    }

    return (
        <>
            {
                category !== ''
                &&
                <>
                    <Breadcrumb category={category} type={type} />
                    <ExploreCourses category={category} type={type} />
                </>
            }
        </>
    );
}

export default CategoryList;
import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import TestPage from './pages/TestPage';

import authContext from './context/auth/authContext';
import PageNotFound from './pages/errors/404';

import { ComingSoon, Home, Home2, AboutUs, ContactUs, Careers, CareerDetails, Blogs, BlogDetails, CategoryList, CmsPages, TutorProfile, HomeSearchPage, Sitemap, LearningCircleDetails, ExploreLearningCircles, ExploreCourses, CourseDetails, ForCorporates, NoAuthCertificates } from './pages/front';
import { Login, Logout, Register, ForgotPassword, ResetPassword, EmployeeInvitation, CorporateRegister, Verify, VerifyEmail, CareerPref, UserPref, CorpPref, CoursePref, InviteEmployee } from './pages/auth';
import { LayoutProfile, GeneralProfile, ProfilePicture, Education, Experience, Security, LearningPreferences, MentorPreferences, ResumeBuilder, ExpertAdvice, ResponsibleAi, Ekyc } from './personal_profile';
import { LayoutDashboard, DashboardHome, AnnouncementDetail, NotificationListing, CareerAdvice, VirtualTutor, SelfAssessment } from "./user_dashboards";
import { ManageCircle, CircleMaterials, CreateLearningCircle, LearningCircleChat, MyCircles, CircleMeetings, CircleFeed, CircleMeetingsCreate, JoinRequests, CircleMembers, CircleICreated, CircleIJoined, PendingInvitation, SentInvitation } from './user_dashboards/learning_circle';
import { TutorLayout, CourseListing, CreateCourse, ManageCourse, PublishedCourses, DraftCourses } from "./user_dashboards/tutor";
import { StudentLayout, MyCourses, CourseCertificate, OnGoingCourses, CompletedCourses } from "./user_dashboards/student";
import { LearnCourseLayout } from "./learn_course";
import { VacancyLayout, VacancyListing, VacancyDetail, VacancyForm } from "./user_dashboards/vacancy";
import { EmployeeLayout, EmployeeListing, EmployeeForm, EmployeeDetail } from "./user_dashboards/employee";

import ReportLayout from './user_dashboards/reports/ReportLayout';
import ReportIndex from './user_dashboards/reports/ReportIndex';
import StudentReport from './user_dashboards/reports/StudentReport';
import TutorReport from './user_dashboards/reports/TutorReport';
import ActivityReport from './user_dashboards/reports/ActivityReport';
import CourseReport from './user_dashboards/reports/CourseReport';
import LearningCircleReport from './user_dashboards/reports/LearningCircleReport';
import LoginReport from './user_dashboards/reports/LoginReport';

import AnalyticsLayout from './user_dashboards/analytics/AnalyticsLayout';
import AnalyticsIndex from './user_dashboards/analytics/AnalyticsIndex';
import MasterLayout from './pages/MasterLayout';
import RegiserLayout from './pages/RegiserLayout';
import ComingSoonLayout from './pages/ComingsoonLayout';
const PrivateRoute = ({ children }) => {
    const cntxAuth = useContext(authContext);
    return cntxAuth.isLogged ? children : <Navigate to="/login" />;
}

const disabledEvent = (e) => {
    if (e.stopPropagation) {
        e.stopPropagation();
    } else if (window.event) {
        window.event.cancelBubble = true;
    }

    e.preventDefault();
    return false;
}

const RouteGroup = () => {
    localStorage.removeItem('learningCircleUniqueId');
    const location = useLocation();

    useEffect(() => {
        document.addEventListener("keydown", function (e) {
            if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
                disabledEvent(e);
            }

            if (e.keyCode === 123) {
                disabledEvent(e);
            }
        }, false);

        const handleContextmenu = e => {
            e.preventDefault()
        }

        document.addEventListener('contextmenu', handleContextmenu)
        return () => {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, []);

    return (
        <>
            <Routes>
            <Route path="/coming-soon" element={<ComingSoonLayout />}>
            <Route exact path="/coming-soon" element={<ComingSoon />} />
            </Route>

                <Route path="/register-steps" element={<RegiserLayout />}>
                    <Route exact path="verify-email/:_token" element={<VerifyEmail />} />
                    <Route exact path="career-preference/:_token" element={<PrivateRoute><CareerPref /></PrivateRoute>} />
                    <Route exact path="teaching-preference/:_token" element={<PrivateRoute><UserPref /></PrivateRoute>} />
                    {/*<Route exact path="corporate-preference/:_token" element={<PrivateRoute><CorpPref /></PrivateRoute>} />*/ }
                    <Route exact path="invite-employees/:_id" element={<PrivateRoute><InviteEmployee /></PrivateRoute>} />
                    <Route exact path="course-preference/:_token" element={<PrivateRoute><CoursePref /></PrivateRoute>} />
                </Route>
                <Route path="/" element={<MasterLayout />}>
                    {/* AUTH ROUTES */}
                    {/*<Route exact path="/for-corporates" element={<ForCorporates />} />*/}
                    <Route exact path="/register" element={<Register />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/verify/:_token" element={<Verify />} />
                    {/* <Route exact path="/verify-email/:_token" element={<VerifyEmail />} /> */}

                    {/*<Route exact path="/employee-invitation/:invitation_id" element={<EmployeeInvitation />} />*/}
                    {/*<Route exact path="/corporate-register" element={<CorporateRegister />} />*/}
                    <Route exact path="/forgot-password" element={<ForgotPassword />} />
                    <Route exact path="/reset-password/:_token" element={<ResetPassword />} />
                    <Route exact path="/logout" element={<Logout />} />
                    {/* AUTH ROUTES */}

                    {/* FRONT PAGES ROUTES */}
                    <Route exact path="/" element={<Home2 />} />
                    <Route exact path="/2" element={<Home />} />
                    <Route exact path="/search/:slug" element={<HomeSearchPage />} />

                    <Route path="/search">
                        <Route path=":slug" element={<HomeSearchPage />} />
                        <Route path="" element={<HomeSearchPage />} />
                    </Route>

                    <Route exact path="/about-us" element={<AboutUs />} />
                    <Route exact path="/contact-us" element={<ContactUs />} />
                    <Route exact path="/pages/:slug" element={<CmsPages />} />
                    <Route exact path="/careers" element={<Careers />} />
                    <Route exact path="/career/:id" element={<CareerDetails />} />
                    <Route exact path="/blogs" element={<Blogs />} />
                    <Route exact path="/blog/:slug" element={<BlogDetails />} />
                    <Route exact path="/category/:slug" element={<CategoryList />} />
                    <Route exact path="/sub-category/:slug" element={<CategoryList />} />
                    <Route exact path="/sitemap" element={<Sitemap />} />
                    <Route exact path="/my-certificates/:emp" element={<NoAuthCertificates />} />

                    <Route path="/explore-learning-circles">
                        <Route path=":category_slug" element={<ExploreLearningCircles />} />
                        <Route path=":category_slug/:sub_category_slug" element={<ExploreLearningCircles />} />
                        <Route exact path="" element={<ExploreLearningCircles />} />
                    </Route>

                    <Route exact path="/learning-circle-details/:unique_id" element={<LearningCircleDetails />} />
                    <Route exact path="/courses" element={<ExploreCourses />} />
                    <Route exact path="/course-details/:_slug" element={<CourseDetails />} />
                    {/* FRONT PAGES ROUTES */}

                    {/* PROFILE ROUTES */}
                    <Route exact path="/profile-details/:slug" element={<TutorProfile />} />
                    <Route path="profile" element={<PrivateRoute><LayoutProfile /></PrivateRoute>}>
                        <Route path="general" element={<GeneralProfile />} />
                        <Route path="profile-picture" element={<ProfilePicture />} />
                        <Route path="experience" element={<Experience />} />
                        <Route path="education" element={<Education />} />
                        <Route path="security" element={<Security />} />
                        <Route path="responsible-ai" element={<ResponsibleAi />} />
                        <Route path="e-kyc" element={<Ekyc />} />
                        <Route path="learning-preferences" element={<LearningPreferences />} />
                        <Route path="mentor-preferences" element={<MentorPreferences />} />
                        <Route path="resume-builder" element={<ResumeBuilder />} />
                        <Route path="expert-advice" element={<ExpertAdvice />} />
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Route>
                    {/* PROFILE ROUTES */}

                    {/* DASHBOARD ROUTES */}
                    <Route path="dashboard" element={<PrivateRoute><LayoutDashboard /></PrivateRoute>}>
                        <Route index element={<DashboardHome />} />
                        <Route path="home" element={<DashboardHome />} />
                        <Route path="announcement-details/:announcementId" element={<AnnouncementDetail />} />

                        {/* LEARNING CIRCLE ROUTES */}
                        <Route path="my-circles" element={<MyCircles />}>
                            <Route index element={<CircleICreated />} />
                            <Route path="i-have-created" element={<CircleICreated />} />
                            <Route path="i-have-joined" element={<CircleIJoined />} />
                            <Route path="invitation" element={<PendingInvitation />} />
                            <Route path="*" element={<Navigate replace to="/404" />} />
                        </Route>

                        <Route path="create-learning-circle" element={<CreateLearningCircle />} />
                        <Route path="edit-learning-circle/:unique_id" element={<CreateLearningCircle />} />
                        {/* LEARNING CIRCLE ROUTES */}

                        {/* TUTOR ROUTES */}
                        <Route path="tutor" element={<TutorLayout />}>
                            <Route element={<CourseListing />}>
                                <Route path="published-courses" element={<PublishedCourses />} />
                                <Route path="draft-courses" element={<DraftCourses />} />
                            </Route>

                            <Route path="create-course" element={<CreateCourse />} />
                            <Route path="edit-course/:course_id" element={<CreateCourse />} />
                            <Route path="edit-course/:course_id/:_page" element={<CreateCourse />} />
                            <Route path="manage-course/:course_id" element={<ManageCourse />} />
                            <Route path="*" element={<Navigate replace to="/404" />} />
                        </Route>
                        {/* TUTOR ROUTES */}

                        {/* STUDENT ROUTES */}
                        <Route path="student" element={<StudentLayout />}>
                            <Route element={<MyCourses />}>
                                <Route path="ongoing-courses" element={<OnGoingCourses />} />
                                <Route path="completed-courses" element={<CompletedCourses />} />
                                <Route path="*" element={<Navigate replace to="/404" />} />
                            </Route>

                            <Route path="course-certificate/:_slug" element={<CourseCertificate />} />
                            <Route path="*" element={<Navigate replace to="/404" />} />
                        </Route>
                        {/* STUDENT ROUTES */}

                        {/* CAREER ROUTES */}
                        <Route path="career-advice" element={<CareerAdvice />} />
                        <Route path="virtual-tutor" element={<VirtualTutor />} />
                        <Route path="self-assessment" element={<SelfAssessment />} />
                        {/* CAREER ROUTES */}

                        {/* NOTIFICATION ROUTES */}
                        <Route path="notification" element={<NotificationListing />} />
                        {/* NOTIFICATION ROUTES */}

                        {/* VACANCY ROUTES */}
                        {/** 
                        <Route path="vacancy" element={<VacancyLayout />}>
                            <Route index element={<VacancyListing />} />
                            <Route path="form" element={<VacancyForm />} />
                            <Route path="edit/:vacancy_id" element={<VacancyForm />} />
                            <Route path="detail/:vacancy_id" element={<VacancyDetail />} />
                            <Route path="*" element={<Navigate replace to="/404" />} />
                        </Route>
                        */}
                        {/* VACANCY ROUTES */}

                        {/* EMPLOYEE ROUTES */}
                        {/** 
                        <Route path="employee" element={<EmployeeLayout />}>
                            <Route index element={<EmployeeListing />} />
                            <Route path="form" element={<EmployeeForm />} />
                            <Route path="edit/:employee_id" element={<EmployeeForm />} />
                            <Route path="detail/:employee_id" element={<EmployeeDetail />} />
                            <Route path="*" element={<Navigate replace to="/404" />} />
                        </Route>
                        */}
                        {/* EMPLOYEE ROUTES */}

                        {/* REPORTS & ANALYTICS ROUTES */}
                        <Route path="reports" element={<ReportLayout />}>
                            {
                                /*
                                <Route index element={<StudentReport />} />
                                <Route path="student-reports" element={<StudentReport />} />
                                <Route path="tutor-reports" element={<TutorReport />} />
                                */
                            }
                            <Route index element={<ReportIndex />} />
                            <Route exact path="activity-report" element={<ActivityReport />} />
                            <Route exact path="course-report" element={<CourseReport />} />
                            <Route exact path="learning-circle-report" element={<LearningCircleReport />} />
                            <Route exact path="login-report" element={<LoginReport />} />
                            <Route path="student-reports" element={<StudentReport />} />
                            <Route path="tutor-reports" element={<TutorReport />} />
                            <Route path="*" element={<Navigate replace to="/404" />} />
                        </Route>

                        <Route path="analytics" element={<AnalyticsLayout />}>
                            <Route index element={<AnalyticsIndex />} />
                            <Route path="*" element={<Navigate replace to="/404" />} />
                        </Route>
                        {/* REPORTS & ANALYTICS ROUTES */}

                        <Route path="my-chat" element={<LearningCircleChat />} />
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Route>
                    {/* DASHBOARD ROUTES */}

                    {/* LEARNING CIRCLE ROUTES */}
                    <Route path="/view-learning-circle/:unique_id" element={<PrivateRoute><ManageCircle /></PrivateRoute>}>
                        <Route path="feeds" element={<CircleFeed />} />
                        <Route path="meetings" element={<CircleMeetings />} />
                        <Route path="create-meeting" element={<CircleMeetingsCreate />} />
                        <Route path="materials" element={<CircleMaterials />} />
                        <Route path="members" element={<CircleMembers />} />
                        <Route path="join-requests" element={<JoinRequests />} />
                        <Route path="sent-invitations" element={<SentInvitation />} />
                        <Route path="*" element={<Navigate replace to="/404" />} />
                    </Route>
                    {/* LEARNING CIRCLE ROUTES */}

                    {/* TUTOR ROUTES */}
                    <Route exact path='/learn-course/:_slug' element={<PrivateRoute><LearnCourseLayout /></PrivateRoute>} />
                    <Route exact path='/learn-course/:_slug/:_slide' element={<PrivateRoute><LearnCourseLayout /></PrivateRoute>} />
                    {/* TUTOR ROUTES */}

                    {/* ERROR ROUTES */}
                    <Route path="/404" element={<PageNotFound />} />
                    <Route path="/test-page" element={<TestPage />} />
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Route>

                {/* ERROR ROUTES */}
            </Routes>
        </>
    );
}

export default RouteGroup;
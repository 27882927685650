import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import { Toaster } from "../../entity/GlobalJS";

const LearningCircleReport = () => {
    const _token = localStorage.getItem('lmstoken');
    const userData = JSON.parse(localStorage.getItem('lmsuser'));
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [submitting, setSubmitting] = useState(false);
    const [file, setFile] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if(userData?.is_corporate === 1)
        {
            //navigate('/dashboard/home');
        }
    }, [])
    const clearForm = () => {
        setValue('timezone', userData?.timezone);
        setValue('title');
        setValue('agenda');
        setValue('date');
        setValue('time');
        setValue('duration');
    }

    const setEndDate = (e) => {
        document.getElementById(`end_date`).setAttribute('min', e.target.value);
    }

    const exportData = async (form) => {
        //console.info(form);
        //reports/circle/
        //getCircleReportByMember
        //member_id
        form.member_id = userData.id;
        const response = await fetch(`${API_BASE}reports/student-circle`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
            body: JSON.stringify(form)
        });

        const data = await response.json();
        if (data.status) {
            let download = data.data?.file_path ? data.data?.file_path : '';
            setFile(download)
            Toaster.success(data.message);
        } else {
            setFile('')
            Toaster.error(data.message);
        }
        
    }
    return(
        <>
            <div className="col-lg-9">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4 className="generate-report">Learning Circle Report</h4>
                        <NavLink to={`/dashboard/reports`} className="btn btn-circle btn-md submit-pink-btn back-button-course float-right pl-3 pr-3 pt-1 pb-1 mt-2"><i className="fa fa-arrow-left" aria-hidden="true"></i> Back to Reports Dashboard</NavLink>
                    </div>
                    <div className="learning-circle-courses">
                        <section>
                            <div className="explore-section">
                                <div className="container">
                                    <div className="explore-block" style={{'border': 'none'}}>
                                        <div className="blog-card">
                                            <div className="row">
                                                <div className="col-md-12 pb-5">
                                                    <form onSubmit={handleSubmit(exportData)}>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="start_date"><b>From Date</b></label>
                                                                    <input {...register("start_date")} id="start_date" type="date" className="form-control" onChange={(e) => { setEndDate(e) }} placeholder="Date*" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="end_date"><b>To Date</b></label>
                                                                    <input {...register("end_date")} id="end_date" type="date" className="form-control" placeholder="Date*" />
                                                                </div>
                                                            </div>
                                                            {/*
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Export Included :</b> </label><br />
                                                                    <label htmlFor="created">
                                                                        <input {...register("created")} id="created" type="checkbox" name="created" className="" />
                                                                        &nbsp;Circles I have created
                                                                    </label>&nbsp;&nbsp;
                                                                    <label htmlFor="joined">
                                                                        <input {...register("joined")} id="joined" type="checkbox" name="joined" className="" />
                                                                        &nbsp;Circles I have joined
                                                                    </label>&nbsp;&nbsp;
                                                                </div>
                                                            </div>
                                                            */}

                                                        </div>
                                                        <button type="submit" className="btn btn-circle btn-md submit-pink-btn">Export CSV</button>
                                                    </form>
                                                    <br />
                                                    {
                                                        file !== ''
                                                        &&
                                                        <p><a href={file} className="dlt_btn" download>Click to download</a></p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LearningCircleReport;

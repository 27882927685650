import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import parse from 'html-react-parser';
import LatestBlogs from "./LatestBlogs";

const BlogDetails = () => {
    const location = useLocation();
    const { slug } = useParams();
    const [blog, setBlog] = useState('');
    const navigate = useNavigate();
    
    useLayoutEffect(() => {
        fetchBlog();
        //eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        fetchBlog();
        //eslint-disable-next-line
    }, []);

    async function fetchBlog() {
        const response = await fetch(API_BASE + 'get-blog/' + slug, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setBlog(data.data.blogs);
        } else {
            navigate('/404');
        }
    }

    return (
        <>
            <section>
                <div className="contact-banner">
                    <div className="container text-light text-center">
                        <h1>{blog.title}</h1>
                    </div>
                </div>
            </section>

            <section className="custom-section">
                <div className="custom-breadcrumb">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item"><a href="/blogs">Blogs and News</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{blog.title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>

            <section className="custom-section">
                <div className="community-activity-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="community-activity-info">
                                    {
                                        blog !== ''
                                        &&
                                        <>
                                            <div className="custom-blog-details-head">
                                                <img src={blog.image} className="card-img-top" alt={blog.title} />
                                                <span className="news-date">{blog.date}</span>
                                            </div>
                                            <div className="community-activity-header">
                                                <h3>{blog.title}</h3>
                                                <div className="community-tab-block">
                                                    {parse(blog.short_description !== null && blog.short_description !== '' ? blog.short_description : '')}
                                                </div>
                                            </div>
                                            <div className="community-activity-content">
                                                {parse(blog.long_description !== null && blog.long_description !== '' ? blog.long_description : "")}
                                            </div>
                                        </>
                                    }

                                    {
                                        blog === ''
                                        &&
                                        <>
                                            <div className="community-activity-header">
                                                <h3>Blog Details</h3>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <LatestBlogs />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogDetails;
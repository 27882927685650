import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_BASE } from "../../../constants/global";
import { Step1, Step2, Step3, Step4, Step5 } from './steps/index';

const CreateCourse = () => {
    const _token = localStorage.getItem('lmstoken');
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const { course_id } = useParams();
    const { _page } = useParams();
    const navigate = useNavigate();
    const [active_step, setStep] = useState((typeof _page !== 'undefined' && _page !== '') ? parseInt(_page) : 1);
    const [cid, setCourseId] = useState(typeof course_id !== 'undefined' ? course_id : '');
    const [completed, setCompleted] = useState(0);

    useEffect(() => {
        if (!user?.tutor_service_enabled) {
            navigate(`/dashboard/tutor/published-courses`);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchCompletedSteps();
        //eslint-disable-next-line
    }, [active_step]);

    const fetchCompletedSteps = async () => {
        if (course_id !== '' && course_id != undefined) {
            const response = await fetch(`${API_BASE}get-course-steps/${course_id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${_token}`
                },
            });

            const data = await response.json();

            if (data.status) {
                const steps = data.data;
                setCompleted(steps);
            }
        }
    }

    return (
        <>
            <div className="col-lg-9 home-section">
                <div className="custom-user-profile-content custom-learning-circle-content tutor_dashboard">
                    <div className="custom-learnig-circle-head">
                        <h4>Create a Course</h4>
                    </div>

                    <div className="learning-circle-form">
                        <div className="count_level">
                            <ul>
                                <li className={active_step >= 1 ? 'done' : ''}>
                                    {completed >= 1 ? <span className="cursor-pointer" onClick={() => { setStep(1); }}>1</span> : <span>1</span>}
                                </li>
                                <li className={active_step >= 2 ? 'done' : ''}>
                                    {completed >= 2 ? <span className="cursor-pointer" onClick={() => { setStep(2); }}>2</span> : <span>2</span>}
                                </li>
                                <li className={active_step >= 3 ? 'done' : ''}>
                                    {completed >= 3 ? <span className="cursor-pointer" onClick={() => { setStep(3); }}>3</span> : <span>3</span>}
                                </li>
                                <li className={active_step >= 4 ? 'done' : ''}>
                                    {completed >= 4 ? <span className="cursor-pointer" onClick={() => { setStep(4); }}>4</span> : <span>4</span>}
                                </li>
                                <li className={active_step >= 5 ? 'done' : ''}>
                                    {completed >= 5 ? <span className="cursor-pointer" onClick={() => { setStep(5); }}>5</span> : <span>5</span>}
                                </li>
                            </ul>
                        </div>
                        <div className="clearfix"></div>

                        {
                            active_step === 1
                            &&
                            <Step1 cid={cid} setStep={setStep} setCourseId={setCourseId} />
                        }

                        {
                            active_step === 2
                            &&
                            <Step2 cid={cid} setStep={setStep} />
                        }

                        {
                            active_step === 3
                            &&
                            <Step3 cid={cid} setStep={setStep} />
                        }

                        {
                            active_step === 4
                            &&
                            <Step4 cid={cid} setStep={setStep} />
                        }

                        {
                            active_step === 5
                            &&
                            <Step5 cid={cid} setStep={setStep} />
                        }
                    </div>

                    <div className="clearfix"></div>
                </div>
            </div>
        </>
    );
}

export default CreateCourse;
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TutorReport = () =>{
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    useEffect(() => {        
        if(user?.is_tutor !== 1)
        {
            navigate('/dashboard/home');
        }
    }, []);
    return(
        <p className="text-center">Comming soon ..</p>
    )
}
export default TutorReport;

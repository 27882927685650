import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { CircleBoxSkeleton } from "../../../components/skeletons";
import { API_BASE } from "../../../constants/global";
import authContext from "../../../context/auth/authContext";
import CustomPagination from "../../../entity/CustomPagination";
import { SwalBox, Toaster } from "../../../entity/GlobalJS";

const DraftCourses = () => {
    const user = JSON.parse(localStorage.getItem('lmsuser'));
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const navigate = useNavigate();
    const urlState = location.state;

    const [published, setPublished, drafted, setDrafted] = useOutletContext();
    const cntxAuth = useContext(authContext);

    const _token = localStorage.getItem('lmstoken');

    const [courses, setCourses] = useState('');
    const [total_count_view, setTotalCountView] = useState(0);
    const [total_count, setTotalCount] = useState(0);
    const [isLoader, setIsLoader] = useState(true);
    const [eligible, setEligible] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submittingcourse, setSubmittingCourse] = useState(false);
    useEffect(() => {
        fetchCourses(currentPage);
        fetchEligiblity();
        // eslint-disable-next-line

        if ((cntxAuth?.userInfo?.is_corporate === 1) && urlState !== null && urlState.is_from === 'in_review') {
            document.getElementById('CourseToVacancyButton').click();
        }
    }, [location, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }
    
    const fetchCourses = async (page) => {
        setIsLoader(true);
        const response = await fetch(`${API_BASE}get-tutor-drafted-course-list?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        setIsLoader(false);
        if (data.status) {
            setCourses(data.data.courses);
            setTotalCount(data.data.total_count);
            setTotalCountView(data.data.total_count);
            setDrafted(data.data.total_count);
        }
    }

    const fetchEligiblity = async (page) => {
        setIsLoader(true);
        const response = await fetch(`${API_BASE}check-tutor-eligibility`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();
        setIsLoader(false);
        if (data.status) {
            setEligible(data.data.eligible);
        }
    }

    const ConfirmDelete = (id) => {
        SwalBox.fire({
            title: 'Are you sure?',
            text: 'Sure to delete draft course?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCourses(id);
            }
        });
    }

    const deleteCourses = async (id) => {
        const response = await fetch(`${API_BASE}delete-course/${id}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status && data.data.deleted) {
            Toaster.success(data.message);
            fetchCourses(0);
            setTotalCountView(total_count_view - 1);
            setDrafted(drafted - 1);
        }
    }

    const submitForReview = async (course_id) => {
        setSubmitting(true);
        setSubmittingCourse(course_id);
        var sURL = `${API_BASE}post-create-course-stepFive/${course_id}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: { "Authorization": `Bearer ${_token}` }
        });
        const data = await response.json();

        if (data.status) {
            Toaster.success(data.message);
            const c_response = await fetch(`${API_BASE}get-tutor-drafted-course-list?page=0`, {
                method: 'GET',
                headers: { "Content-Type": "application/json", "Accept": "application/json", "Authorization": `Bearer ${_token}` },
            });
            const c_data = await c_response.json();
            if (c_data.status) {
                setCourses(c_data.data.courses);
                setIsLoader(false);
                setSubmitting(false);
                if ((cntxAuth?.userInfo?.is_corporate === 1)) {
                    document.getElementById('CourseToVacancyButton').click();
                }
            }
        } else {
            Toaster.error(data.message);
            setIsLoader(false);
            setSubmitting(false);
        }
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div className="blog-card">
                    <div className="row">
                        {
                            courses !== '' && !eligible
                            &&
                            <div className="col-md-12 col-lg-12">
                                <div className="edit_prof_msg">
                                <p>{user.is_corporate ? `In order to submit a course for review, your company’s general profile must be completed with the company’s introduction.` : `In order to submit a course for review, your profile must be completed with your name, self-introduction, a photo, and tutor preferences`}</p>
                                    <Link className="btn" to={'/profile/general'}>Edit Profile</Link>
                                </div>
                            </div>
                        }

                        {
                            isLoader
                                ?
                                <CircleBoxSkeleton count={4} />
                                :
                                <>
                                    {
                                        courses !== ''
                                            ?
                                            courses.map((course, index) =>
                                                <div key={index} className="col-md-6 col-lg-6">
                                                    <div className={course.progress == '100%' ? 'blog-card-detail card_sbmt_btny' : 'blog-card-detail card_sbmt_btnn'}>
                                                        <Link className={course.progress == '100%' ? 'pos_link pos_sbmt_btny' : 'pos_link pos_sbmt_btnn'} to={`/dashboard/tutor/edit-course/${course.id}`}></Link>
                                                        <div className="custom-card-img">
                                                            {/*
                                                        <a className="ellipsbtn" id={`MaterialsMenuButton${course.id}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby={`MaterialsMenuButton${course.id}`}>
                                                            <a onClick={(e) => { ConfirmDelete(course.id); }} className="dropdown-item">Delete Draft</a>
                                                        </div>
                                                        */}
                                                            <img src={course.image} className="card-img-top" alt={course.title} />
                                                        </div>
                                                        <div className="blog-card-inner ek_mb20">
                                                            <h5 className="ek_h5inner">{course.title}</h5>
                                                            <a className="ekellipsbtn" id={`MaterialsMenuButton${course.id}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby={`MaterialsMenuButton${course.id}`}>
                                                                <a onClick={(e) => { ConfirmDelete(course.id); }} className="dropdown-item">Delete Draft</a>
                                                            </div>
                                                            <ul className="ekellipsdd">
                                                                <li>
                                                                    <strong>{course.review_status} </strong>
                                                                    {
                                                                        course.review_status === "In Progress"
                                                                        &&
                                                                        course.progress + ' Completed'
                                                                    }
                                                                </li>
                                                            </ul>
                                                            {
                                                                course.progress == '100%' && course.review_status != 'In Review'
                                                                &&
                                                                <>
                                                                    <button disabled={submitting} onClick={(e) => { submitForReview(course.id); }} className="btn btn-circle btn-md btn-success">{submittingcourse == course.id && submitting ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Submit for Review'}</button>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            :
                                            null
                                    }

                                    {
                                        (total_count_view <= 0 || courses === '')
                                            ?
                                            <div className="col-md-12 col-lg-12 text-center">
                                                <div className="empty_state">
                                                    <h3>No courses in draft</h3>
                                                    <img alt="" src="images/no_courses.png" />
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </>
                        }
                    </div>
                </div>

                {
                    total_count > 4
                    &&
                    <div className="custom-pagination">
                        <nav aria-label="Page navigation example">
                            <CustomPagination pageCount={total_count} perPageRecord={4} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                        </nav>
                    </div>
                }
            </div>

            <a className="btn ml-2 mb-2 d-none" data-toggle="modal" id="CourseToVacancyButton" data-target="#CourseToVacancyModal">Add</a>
            <div className="modal invitePopup header_red three_btn modal fade" id="CourseToVacancyModal" tabIndex="-1" role="dialog" aria-labelledby="CourseToVacancyModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button onClick={() => { document.getElementById('CourseToVacancyModalClose').click(); navigate(`/dashboard/tutor/draft-courses`) }} type="button" id="CourseToVacancyModalClose" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <div className="blog-card">
                                <p>Your course titled <strong>{urlState?.course_name}</strong> has been submitted for review and will be live in 72 hours.</p>
                                <br />
                                <p>What would you like to do with this course next?</p>
                                <div className="row">
                                    <div className="col-lg-6 mb-0">
                                    <button
                                            onClick={() => {
                                                document.getElementById('CourseToVacancyModalClose').click();
                                                if (typeof urlState?.associated_vacancy !== 'undefined' && urlState?.associated_vacancy !== '') {
                                                    navigate(`/dashboard/vacancy/edit/${urlState?.associated_vacancy}`)
                                                } else {
                                                    navigate(`/dashboard/vacancy/form`, { state: { course_slug: urlState?.course_slug } })
                                                }
                                            }}
                                            className="btn btn-circle btn-info mb-2 w-100"
                                        >
                                            Create vacancy & hire talent
                                        </button>
                                    </div>
                                    <div className="col-lg-6 mb-0">
                                        <button onClick={() => { document.getElementById('CourseToVacancyModalClose').click(); navigate(`/dashboard/vacancy`) }} className="btn btn-circle btn-info mb-2 w-100">Invite & Train employees</button>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                                <div className="text-center">
                                    <button onClick={() => { document.getElementById('CourseToVacancyModalClose').click(); navigate(`/dashboard/tutor/draft-courses`) }} className="btn btn-circle btn-success inline-block w-100 m-auto">Skip for now</button>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DraftCourses;
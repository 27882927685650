import { useEffect, useState } from "react";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import CircleBoxSkeleton from "../../../components/skeletons/CircleBoxSkeleton";
import { API_BASE } from "../../../constants/global";
import CustomPagination from "../../../entity/CustomPagination";
import CourseCard from "./CourseCard";

const CompletedCourses = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const _token = localStorage.getItem('lmstoken');
    const [list_courses, setListCourses] = useState('');
    const [total_count, setTotalCount] = useState(0);
    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        fetchCourse(currentPage);
    }, [location, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const fetchCourse = async (page = 0) => {
        setIsLoader(true);
        const response = await fetch(`${API_BASE}get-completed-courses?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token

            },
        });

        const data = await response.json();
        setIsLoader(false);
        if (data.status) {
            setListCourses(data.data.courses);
            setTotalCount(data.data.total_count);
        } else {
            setListCourses('');
            setTotalCount(0);
        }
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div className="blog-card">
                    <div className="row">
                        {
                            isLoader ?
                                <>
                                    <CircleBoxSkeleton count={4} />
                                </>
                                :
                                <>
                                    {
                                        list_courses !== ''
                                        &&
                                        list_courses.map((course, index) =>
                                            <div key={index} className="col-md-6 col-lg-6">
                                                <CourseCard course={course} is_completed={true} />
                                            </div>
                                        )
                                    }

                                    {
                                        list_courses === ''
                                        &&
                                        <div className="col-md-12 col-lg-12 text-center">
                                            <div className="empty_state">
                                                <h3>No completed courses</h3>
                                                <img alt="" src="images/no_courses.png" />
                                                <p>
                                                    Explore our course catalogue to enrol on a course and start your learning journey.
                                                    <br /><a href="/courses" className="link" >Explore Courses</a>
                                                </p>
                                            </div>
                                        </div>
                                    }
                                </>
                        }

                    </div>
                </div>

                {
                    total_count > 4
                    &&
                    <div className="custom-pagination">
                        <nav aria-label="Page navigation example">
                            <CustomPagination pageCount={total_count} perPageRecord={4} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                        </nav>
                    </div>
                }
            </div>
        </>
    );
}

export default CompletedCourses;
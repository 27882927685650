import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { API_BASE } from "../../constants/global";
import CustomPagination from "../../entity/CustomPagination";
import Skeleton from 'react-loading-skeleton'
import CircleBoxSkeleton from "../../components/skeletons/CircleBoxSkeleton";

const CircleICreated = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get('page')) || 0;
    const [reload, setReload] = useState(false);

    const _token = localStorage.getItem('lmstoken');
    const [groups, setGroups] = useState([]);
    const [total_count, setTotalCount] = useState(0);
    const [isLoader, setLoader] = useState(true);

    useEffect(() => {
        fetchGroups(currentPage);
        // eslint-disable-next-line 
    }, [location, reload]);

    const funReload = (page) => {
        setSearchParams({ ...searchParams, page: page });
        setReload(!reload);
    }

    const fetchGroups = async (page) => {
        setLoader(true);
        const response = await fetch(`${API_BASE}circles-i-have-create?page=${page}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });


        const data = await response.json();
        setLoader(false);
        if (data.status) {
            setGroups(data.data.groups);
            setTotalCount(data.data.total_count);
        }
    }

    return (
        <>
            <div className="tab-pane fade show active" id="pills-course" role="tabpanel" aria-labelledby="pills-course-tab">
                {
                    isLoader ?
                        <>
                            <p><Skeleton count={1} /></p>
                        </>
                        :
                        typeof groups !== 'undefined' && groups.length > 0
                        &&
                        <p>Start a Learning Circle of your own and learn more about a topic of your choice by networking with other professionals.</p>
                }
                <div className="blog-card">
                    <div className="row">
                        {
                            isLoader ?
                                <>
                                    <CircleBoxSkeleton count="4" />
                                </>
                                :
                                <>
                                    {
                                        typeof groups !== 'undefined' && groups.length > 0
                                        &&
                                        groups.map((group, index) =>
                                            <div key={index} className="col-md-6 col-lg-6">
                                                <div className="blog-card-detail">
                                                    <Link className="pos_link" to={`/view-learning-circle/${group.unique_id}/feeds`}></Link>
                                                    <div className="custom-card-img">
                                                        <img src={group.image} className="card-img-top" alt="Blog" />
                                                    </div>
                                                    <div className="blog-card-inner">
                                                        <h5 className="">{group.title}<span className="text-danger">{group.active === true ? '' : ' (Inactive)'}</span></h5>
                                                        <a className="ekellipsbtn ekellipsbtn-circle" id={`MaterialsMenuButton${group.id}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby={`MaterialsMenuButton${group.id}`}>
                                                            <Link to={'/dashboard/edit-learning-circle/'+group.unique_id} className="dropdown-item">Edit Circle</Link>
                                                        </div>
                                                        <ul>
                                                            {/* <li><strong>Created by:</strong> <Link to={`/profile-details/${group?.owner_slug}`}>{group?.owner}</Link></li> */}
                                                            <li><strong>Rating:</strong> {group?.average_rating ? group?.average_rating : 'No Ratings'}</li>
                                                            <li><strong>No. of People:</strong> {group.mebmers}</li>
                                                            <li className="text-right"><strong>{group.privacy}</strong></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        (typeof groups === 'undefined' || groups.length <= 0)
                                        &&
                                        <>
                                            <div className="col-12">
                                                <div className="empty_state">
                                                    <h3>No circles found!</h3>
                                                    <img alt="" src="images/no_courses.png" />
                                                    <p>
                                                        You Haven't Created Any Leaning Circles Yet.
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                        }
                    </div>
                </div>
                {
                    total_count > 4
                    &&
                    <div className="custom-pagination">
                        <nav aria-label="Page navigation example">
                            <CustomPagination pageCount={total_count} perPageRecord={4} handlePageClick={(data) => { funReload(data.selected) }} currentPage={currentPage} />
                        </nav>
                    </div>
                }
            </div>
        </>
    );
}

export default CircleICreated;
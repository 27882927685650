import { io } from "socket.io-client";
// LOCAL URL
//const API_BASE = 'http://localhost/smartlearning/public/api/';
//const FRONT_BASE = 'http://localhost:3000/';

// STAGING URL
// const API_BASE = 'https://smartlms.plusaim.co.uk/backend/public/api/';
// const FRONT_BASE = 'https://smartlms.plusaim.co.uk/';

// LIVE URL
const API_BASE = 'https://alpha.edukula.com/backend/public/api/';
const FRONT_BASE = 'https://alpha.edukula.com/';

// EDUKULA LIVE URL
// const API_BASE = 'https://edukula.com/backend/public/api/';
// const FRONT_BASE = 'https://edukula.com/';


const SOCKET_BASE = 'https://alpha-chat.edukula.com/';
// const SOCKET_BASE = 'http://localhost:3001';

//const GOOGLE_CLIENT_ID = '950040083271-pl5h6hpighkum1gj5n2qq5kppker5npa.apps.googleusercontent.com';
const GOOGLE_CLIENT_ID = '55255271553-3rdfjvp8qkpgatm0a9clhen2msc3viet.apps.googleusercontent.com';

const FACEBOOK_APP_ID = '1165717227343653';

const PDF_PREFIX = "https://cors-anywhere.herokuapp.com/";
const DEFAULT_UPLOAD_VIDEO_IMG = "images/upload_video.jpg";
const DEFAULT_UPLOAD_FILE_IMG = "images/add_file.png";
const DEFAULT_UPLOAD_LOGO_IMG = "images/add_logo_file.jpg";
const DEFAULT_UPLOAD_RESUME_IMG = "images/resume_upload.jpg";
const DEFAULT_UPDATE_RESUME_IMG = "images/resume_update.jpg";

const SMPLE_EMPLOYEE_CSV = "sample/Corporate_Employees.csv";

const IMAGE_EXT = /(\.jpg|\.jpeg|\.png|\.ico|\.webp|\.svg|\.jfif)$/i;
const IMAGE_TXT = "Please upload JPG, JPEG, PNG, ICO, WEBP, SVG OR JFIF image only";

const VIDEO_EXT = /(\.mp4|\.mkv)$/i;
const VIDEO_TXT = "Please upload MP4, MKV video only";
const socket = io.connect(SOCKET_BASE);

//const TINYMCE_KEY="i6w2urebv8aqyhba44za0hhqak3hlmoipo4lu9ct91uzw83l"; // DEMO
const TINYMCE_KEY="7ln7sqacw0leb6ler6mlccsy062q06d5vg5pzqbwhjzh43xj" //demo.edukula
// const TINYMCE_KEY="jewdbzj1p8xcrzfz0vnljoob2q8awepzwz8x55dox2ghnwfo"; // LIVE
// const socket = io.connect("http://localhost:3001");


//const LRS_ENDPOINT = "http://localhost:8080/api/v1.0/";
const LRS_ENDPOINT = "https://alpha.edukula.com/lrs/public/api/v1.0/";
//const LRS_ENDPOINT = "http://localhost/edukula-lrs/public/api/v1.0/";
const LRS_VERSION =  "1.0";
//const storedApiKey      = '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';
//const storedApiKey        = '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';
//const OPENAI_KEY='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';
const OPENAI_KEY='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';

export {
    API_BASE,
    FRONT_BASE,
    SOCKET_BASE,
    LRS_ENDPOINT,
    socket,
    GOOGLE_CLIENT_ID,
    FACEBOOK_APP_ID,
    PDF_PREFIX,
    DEFAULT_UPLOAD_VIDEO_IMG,
    DEFAULT_UPLOAD_FILE_IMG,
    DEFAULT_UPLOAD_LOGO_IMG,
    DEFAULT_UPLOAD_RESUME_IMG,
    DEFAULT_UPDATE_RESUME_IMG,
    SMPLE_EMPLOYEE_CSV,
    IMAGE_EXT,
    IMAGE_TXT,
    VIDEO_EXT,
    VIDEO_TXT,
    TINYMCE_KEY,
    OPENAI_KEY
};
import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { API_BASE } from '../../constants/global';
import { Toaster } from '../../entity/GlobalJS';

const ResumeBuilder = () => {
    const _token = localStorage.getItem('lmstoken');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('lmsuser')));
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState('');

    const saveResumeDetails = async (form) => {
        const formData = new FormData;
        formData.append('first_name', form.first_name);
        formData.append('last_name', form.last_name);
        formData.append('email', form.email);

        setSubmitting(true);
        setSuccess('');
        const response = await fetch(`${API_BASE}post-resume-details`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status && data.data.updated === 1) {
            setSuccess(data.message);
            setSubmitting(false);
        } else {
            Toaster.error(data.message);
            setSubmitting(false);
        }
    }

    return (
        <div className="tab-pane fade show active" id="v-pills-general" role="tabpanel" aria-labelledby="v-pills-general-tab">
            <div className="profile-dashboard-content">
                <div className="custom-learnig-circle-head">
                    <h4>Resume Builder(coming soon)</h4>
                </div>
                <div className="clearfix"></div>
                <div className="profile-dashboard-box">
                    <form onSubmit={handleSubmit(saveResumeDetails)}>
                        <div className="row">
                            <div className="col-lg-12 text-success pb-3 pt-0">
                                {success ?? ''}
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <input id="first_name" {...register("first_name", {
                                        required: "required",
                                        pattern: {
                                            value: /^[a-z ,.'-]+$/i,
                                            message: "Please add first name appropriately."
                                        },
                                        maxLength: {
                                            value: 80,
                                            message: "First Name can not be more than 80 charaters."
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "Please enter at least 3 characters."
                                        }
                                    })} onChange={(e) => { setUser({ ...user, first_name: e.target.value }) }} type="text" className="form-control" placeholder="First Name" defaultValue={user?.first_name} />
                                    <span className="add-on max-chr-counter chrchk-first_name" data-ele="first_name" data-limit="80" data-showcounter="false"></span>
                                    <span className="text-danger">
                                        {errors.first_name && errors.first_name.type === "required" && 'Please enter first name'}
                                        {errors.first_name && errors.first_name.type !== "required" && errors.first_name.message}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <input id="last_name" {...register("last_name", {
                                        required: "required",
                                        pattern: {
                                            value: /^[a-z ,.'-]+$/i,
                                            message: "Please add last name appropriately."
                                        },
                                        maxLength: {
                                            value: 80,
                                            message: "Last Name can not be more than 80 charaters."
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "Please enter at least 3 characters."
                                        }
                                    })} onChange={(e) => { setUser({ ...user, last_name: e.target.value }) }} type="text" className="form-control" placeholder="Last Name" defaultValue={user?.last_name} />
                                    <span className="add-on max-chr-counter chrchk-last_name" data-ele="last_name" data-limit="80" data-showcounter="false"></span>
                                    <span className="text-danger">
                                        {errors.last_name && errors.last_name.type === "required" && 'Please enter last name'}
                                        {errors.last_name && errors.last_name.type !== "required" && errors.last_name.message}
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <input {...register("email", {
                                        required: "required",
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "Entered value does not match email format"
                                        }
                                    })} type="text" className="form-control" placeholder="test@test.com" onChange={(e) => { setUser({ ...user, email: e.target.value }) }} defaultValue={user?.email} />
                                    <span className="text-danger">
                                        {errors.email && errors.email.type === "required" && 'Please enter email.'}
                                        {errors.email && errors.email.type !== "required" && errors.email.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button disabled={submitting} className="btn btn-success btn-circle btn-md">{submitting ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResumeBuilder
import { useEffect, useState } from "react";
import { API_BASE } from "../../constants/global";

const Sitemap = () => {
    const [sections, setSections] = useState('');

    useEffect(() => {
        fetchPages();
        //eslint-disable-next-line
    }, []);

    async function fetchPages() {
        const response = await fetch(`${API_BASE}get-sitemap`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        const data = await response.json();
        if (data.status) {
            setSections(data.data.pages);
        }
    }

    return (
        <>
            <section className="custom-section">
                <div className="contact-banner">
                    <div className="container text-light text-center">
                        <h1>Sitemap</h1>
                    </div>
                </div>
            </section>

            <section className="custom-section">
                <div className="course-list custom-blog-list">
                    <div className="container">

                        <div className="blog-card">
                            <div className="row">
                                <div className="col-12">
                                    <div id="accordion">
                                        {
                                            sections !== ''
                                            &&
                                            sections.map((section, index) => {
                                                if (section.pages.length > 0) {
                                                    if (section.section !== "Users") {
                                                        return (
                                                            <div key={index}>
                                                                <div className="card">
                                                                    <div className="card-header" id={"headingOne" + index}>
                                                                        <h5 className="mb-0">
                                                                            <button className="btn btn-link" data-toggle="collapse" data-target={"#collapseOne" + index} aria-expanded="true" aria-controls={"collapseOne" + index}>
                                                                                {section.section}
                                                                            </button>
                                                                        </h5>
                                                                    </div>

                                                                    <div id={"collapseOne" + index} className="collapse show" aria-labelledby={"headingOne" + index} data-parent="#accordion">
                                                                        <div className="card-body">
                                                                            <ul>
                                                                                {
                                                                                    section.pages.map((page, indexx) =>
                                                                                        <li className="site-map-link" key={indexx}><a className="link-hover-effect" href={page.url}>{page.title}</a></li>
                                                                                    )
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        );
                                                    }
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default Sitemap;
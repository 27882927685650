import { useDropzone } from "react-dropzone";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE } from "../../../../constants/global";
import { SwalBox, Toaster } from "../../../../entity/GlobalJS";
import DropdownSkeleton from "../../../../components/skeletons/DropdownSkeleton";
import ImageCropper from "../../../../personal_profile/Tabs/ImageCropper";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt.ts";
import Cookies from "js-cookie";

const Step1 = (props) => {
    const { register, handleSubmit, formState: { errors }, setValue, setError, clearErrors } = useForm();
    const navigate = useNavigate();

    const _token = localStorage.getItem('lmstoken');
    const course_id = props.cid;
    const [categories, setCategories] = useState('');
    const [sub_categories, setSubCategories] = useState('');
    const [fetching_sub, setFetchingSub] = useState(false);
    const [levels, setLevels] = useState('');
    const [languages, setLanguages] = useState('');
    const [fetching, setFetching] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [image, setImage] = useState('');
    const [image_src, setImageSrc] = useState('');
    const [image_up, setImageUp] = useState(false);
    const [image_changed, setImageChanged] = useState(true);
    const [imageValidationMsg, setImageValidationMsg] = useState('');

    const [is_continue, setContinue] = useState(true);
    const [showCatErr, setShowCatErr] = useState(false);
    const [fixing_remark, setFixingRemark] = useState('');

    const [prev, setPrev] = useState(false);
    const [new_photo, setNewPhoto] = useState(null);

    const [showDialog, setShowDialog] = useState(false);
    const [showPrompt, confirmNavigation] = useCallbackPrompt(showDialog);
    const [cropbtnstatus, setCropbtnstatus] = useState('');
    const [loadingdata, setLoadingdata] = useState(true);

    const [aiautofill, setAiAutoFill] = useState(null);
    const [ai_course_level, setAiCourseLevel] = useState(null);
    
    useEffect(() => {
        setAiAutofill();
        fetchCategories();
        fetchLevels();
        fetchLanguages();
        fetchCourse();
        // eslint-disable-next-line 
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (showPrompt) {
            ConfirmSaveChanges();
        }
    }, [showPrompt]);



    const setAiAutofill = async () => {
        try {
            let ai_course = Cookies.get('ai_course_structure');
            if(ai_course != null && ai_course != ''){
                var _parsed_course = JSON.parse(ai_course);
                setAiAutoFill(null);
                setAiAutoFill(_parsed_course);
                if(_parsed_course != null){
                    setValue('title', _parsed_course.course_title);
                    setValue('course_objective', _parsed_course.course_objective);
                    setValue('course_level',_parsed_course.course_level);
                }
            }
        }catch(err) { }
    }

    const ConfirmSaveChanges = () => {
        SwalBox.fire({
            title: 'Changes won\'t be saved!',
            text: 'To save the changes made, click on \'Save\'; or Click on \'Cancel\' to discard the changes.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonClass: 'button_confirm_class',
            cancelButtonColor: '#d33',
            cancelButtonClass: 'button_cancel_class',
            confirmButtonText: 'Save'
        }).then((result) => {
            if (result.isConfirmed) {
                saveNExit(null, true);
            } else {
                confirmNavigation();
            }
        });
    }

    const fetchCategories = async () => {
        setLoadingdata(true);
        const response = await fetch(`${API_BASE}get-category-list`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();

        if (data.status) {
            setCategories(data.data.course_categories);
            window.setupSelectControls();
            setLoadingdata(false);
        } else {
            // Toaster.error(data.message);
        }
    }

    const fetchLevels = async () => {
        setLoadingdata(true);
        const response = await fetch(`${API_BASE}get-course-levels`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });
        const data = await response.json();
        if (data.status) {
            setLevels(data.data.levels);
            setLoadingdata(false);
            setTimeout(() => {
                try {
                    let ai_course = Cookies.get('ai_course_structure');
                    if(ai_course != null && ai_course != ''){
                        var _parsed_course = JSON.parse(ai_course);
                        if(_parsed_course != null){
                            let level_element = document.getElementById('course_level');
                            level_element.value = _parsed_course.course_level;
                            setValue('course_level',_parsed_course.course_level);
                        }
                    }
                }catch(err) { }
            }, 2000);

        } else {
            // Toaster.error(data.message);
        }
    }

    const fetchLanguages = async () => {
        setLoadingdata(true);
        const response = await fetch(`${API_BASE}get-course-languages`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + _token
            },
        });

        const data = await response.json();

        if (data.status) {
            setLanguages(data.data.languages);
            setLoadingdata(false);
        }
    }

    const fetchSubCategories = async (cat_id, flag) => {
        setFetchingSub(true);
        // setFetching(true);
        const response = await fetch(`${API_BASE}get-sub-category-list/${cat_id}`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${_token}`
            },
        });

        const data = await response.json();

        if (data.status) {
            setSubCategories(data.data.course_sub_categories);
            window.setupSelectControls();
            if (flag) {
                setFetchingSub(false);
            }
            setLoadingdata(false);
        } else {
            //setFetching(false);
            //setSubCategories('');
            //setFetchingSub(false);
            //setLoadingdata(false);
        }
    }

    const fetchCourse = async () => {
        if (course_id !== '') {
            setFetching(true);
            setFetchingSub(true);
            setLoadingdata(true);
            
            const response = await fetch(`${API_BASE}get-course-form-data-stepOne/${course_id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + _token
                },
            });

            const data = await response.json();

            if (data.status) {
                const course = data.data;
                fetchSubCategories(course?.category.slug);
                setValue('title', course?.title);
                setValue('category_id', course?.category.slug);
                setValue('course_level', course?.study_level);
                setValue('course_objective', course?.course_objective);
                setValue('language_id', course?.language_id);
                setImageUp(course?.imageUp);
                setImageChanged(course?.imageUp ? false : true);
                setImageSrc(course?.imageUp ? course?.image : '');
                if (course?.review == "Need Fixing" && course?.review_remark !== "") {
                    setFixingRemark(course?.review_remark);
                }
                setFetchingSub(true);
                setTimeout(() => {
                    setFetchingSub(false);
                    setValue('sub_category_id', course?.sub_category?.slug);
                    setFetching(false);
                }, 3000);
            } else {
                Toaster.error(data.message);
            }
        }else{
            if(aiautofill != null){
                setValue('title', aiautofill.course_title);
                setValue('course_objective', aiautofill.course_objective);
                setValue('course_level', aiautofill.course_level);
            }
        }
    }

    const categoryHandler = (e) => {
        var cat_id = e.target.value;
        setValue('sub_category_id');

        if (cat_id.length > 0) {
            fetchSubCategories(cat_id, true);
            setShowCatErr(true);
        } else {
            setSubCategories('');
            setShowCatErr(false);
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg']
        },
        maxFiles: 1,
        onDrop: (acceptFile, fileRejections) => {
            fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                    if (err.code === "file-invalid-type") {
                        setImageValidationMsg(`${err.message}`);
                    }
                });
            });
            if (fileRejections.length == 0) {
                setImageSrc(URL.createObjectURL(acceptFile[0]));
                setImage(acceptFile[0]);
                setImageValidationMsg('');
                setShowDialog(true);
                moderateUploadedMedia(acceptFile[0]);
            }
            setPrev(true);
            clearErrors('course_photo');
        },
    });

    const removePhoto = () => {
        setImageSrc('');
        setImage('');
        setImageChanged(true);
        setPrev(false);
        if (course_id !== '') {
            setImageUp(false);
        }
    }

    const saveStepOne = async (form) => {
        const formData = new FormData();
        if (image_up === false && image_changed && image === '') {
            setError('course_photo', { type: "focus", message: 'Please choose course photo.' }, { shouldFocus: true });
            return false;
        }

        clearErrors('course_photo');

        if (image_up === false && image_changed && image !== '') {
            formData.append('image', new_photo ? new_photo : image);
        }

        formData.append('title', form.title);
        formData.append('category_id', form.category_id);
        formData.append('sub_category_id', form.sub_category_id);
        formData.append('course_objective', form.course_objective);
        formData.append('course_level', form.course_level);
        formData.append('imageUp', image_up);
        formData.append('image_changed', image_changed);
        formData.append('language_id', form.language_id);

        setSubmitting(true);
        var sURL = `${API_BASE}post-create-course-stepOne${(course_id !== '') ? `/${course_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            setShowDialog(false);
            if (is_continue) {
                props.setCourseId(data.data.course);
                props.setStep(2);
                navigate(`/dashboard/tutor/edit-course/${data.data.course}/2`);
            } else {
                navigate('/dashboard/tutor/draft-courses');
            }

            setSubmitting(false);
        } else {
            Toaster.error(data.message);
            setSubmitting(false);
        }
    }

    const saveNExit = async (e, toAnother = false) => {
        if (e !== null) e.preventDefault();
        setShowDialog(false);
        clearErrors();

        if (document?.getElementById("title")?.value.trim() === '') {
            setError('title', { type: "required" }, { shouldFocus: true });
            return false;
        }

        const formData = new FormData();

        if (image_up === false && image_changed && image !== '') {
            formData.append('image', new_photo ? new_photo : image);
        }

        formData.append('exit', 1);
        formData.append('title', document?.getElementById("title")?.value);
        formData.append('category_id', document?.getElementById("category_id")?.value);
        formData.append('sub_category_id', document?.getElementById("sub_category_id")?.value);
        formData.append('course_objective', document?.getElementById("course_objective")?.value);
        formData.append('course_level', document?.getElementById("course_level")?.value);
        formData.append('imageUp', image_up);
        formData.append('image_changed', image_changed);
        formData.append('language_id', document?.getElementById("language_id")?.value);

        setSubmitting(true);
        var sURL = `${API_BASE}post-create-course-stepOne${(course_id !== '') ? `/${course_id}` : ''}`;
        const response = await fetch(sURL, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${_token}`
            },
            body: formData
        });

        const data = await response.json();
        if (data.status) {
            Toaster.success(data.message);
            if (toAnother) {
                confirmNavigation();
            } else {
                navigate('/dashboard/tutor/draft-courses');
            }
            setSubmitting(false);
        } else {
            if (toAnother) {
                confirmNavigation();
            }

            Toaster.error(data.message);
            setSubmitting(false);
        }
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
    
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
    
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    
    const moderateUploadedMedia = async (logofile) => {
        try {
            var _token = '';
            setCropbtnstatus('disabled')
            const formData = new FormData();
            var fileField = logofile;
            const base64 = await convertBase64(fileField);
            formData.append("image", fileField);
            formData.append("base64", base64);
            formData.append("mediatype", fileField.type);
            const response = await fetch(API_BASE + 'validate-uploaded-image', {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + _token
                },
                body: formData
            });
            const data = await response.json();
            if (data.status) {
                var _is_valid = data.data;
                if(_is_valid != ""){
                    SwalBox.fire({
                        title: 'Inappropriate image',
                        text: 'Your image has been removed as it contains inappropriate content ('+_is_valid+ ') , please upload a valid image.',
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok'
                    });
                    removePhoto();
                }else{
                    setCropbtnstatus('');
                }
            } else {
                setCropbtnstatus('');
            }
        }catch(err) { }
        
    }
    return (
        <>
            {fixing_remark !== ''
                &&
                <>
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>Remark :</strong> {fixing_remark}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </>
            }

            <h4>Basic Course Details</h4>
            <form onSubmit={handleSubmit(saveStepOne)}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor={'title'}>Course Title</label>
                            <p>It's ok if you can't think of a good title now, you can change it from your drafts later before submitting for review.</p>
                            <input
                                id="title"
                                {...register("title", {
                                    required: "required",
                                    maxLength: {
                                        value: 80,
                                        message: "Course name can not be more than 80 charaters."
                                    }
                                })}
                                type="text"
                                className="form-control"
                                placeholder="e.g. Learn Photoshop CS6 from Scratch"
                                autoFocus
                                onChange={(e) => { setShowDialog(e.target.value.trim() !== ''); }}
                            />
                            <span className="add-on max-chr-counter chrchk-title" data-ele="title" data-limit="80" data-showcounter="true"></span>

                            <span className="text-danger">
                                {errors?.title?.type === "required" && 'Please enter title.'}
                                {errors?.title?.type !== "required" && errors?.title?.message}
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor={'category_id'}>Category</label>
                            <p>If you're not sure about the right category, you can change it from your drafts later before submitting for review.</p>
                            <select
                                id={'category_id'}
                                {...register("category_id", {
                                    required: "required"
                                })}
                                className="form-control"
                                onChange={(e) => {
                                    categoryHandler(e);
                                    setShowDialog(true);
                                }}
                            >
                                <option value=''>Choose a category</option>
                                {
                                    categories && categories.map((category, index) =>
                                        <option key={index + 1} value={category.slug}>{category.title}</option>
                                    )
                                }
                            </select>
                            <span className="text-danger">
                                {showCatErr || errors?.category_id?.type === "required" && 'Please select category.'}
                                {errors?.category_id?.type !== "required" && errors?.category_id?.message}
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor="sub_category_id">Sub Category</label>
                            <p>If you're not sure about the right sub category, you can change it from your drafts later before submitting for review.</p>
                            {
                                fetching_sub
                                    ?
                                    <DropdownSkeleton />
                                    :
                                    <select
                                        {...register("sub_category_id", {
                                            required: "required"
                                        })}
                                        onChange={(e) => {
                                            setShowDialog(true);
                                            if (e.target.value !== '') {
                                                setFetching(false);
                                            }
                                            clearErrors('sub_category_id');
                                        }}
                                        className="form-control"
                                        id="sub_category_id"
                                    >
                                        <option value=''>Choose a sub category</option>
                                        {
                                            sub_categories && sub_categories.map((category, index) =>
                                                <option key={index + 1} value={category.slug}>{category.title}</option>
                                            )
                                        }
                                    </select>
                            }
                            <span className="text-danger">
                                {errors?.sub_category_id?.type === "required" && 'Please select sub category.'}
                                {errors?.sub_category_id?.type !== "required" && errors?.sub_category_id?.message}
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label htmlFor='course_objective'>Course Objective</label>
                            <p>What is the main objective that the student will achieve through learning this course.</p>
                            <textarea
                                {...register("course_objective", {
                                    required: "required",
                                    maxLength: {
                                        value: 500,
                                        message: "Objective can not be more than 500 charaters."
                                    }
                                })}
                                id='course_objective'
                                type="text"
                                className="form-control"
                                placeholder="e.g. Students will learn the basic tools used in Photoshop to create and edit images."
                                onChange={() => {
                                    setShowDialog(true);
                                }}
                            ></textarea>
                            <span className="add-on max-chr-counter chrchk-course_objective" data-ele="course_objective" data-limit="500" data-showcounter="true"></span>
                            <span className="text-danger">
                                {errors.course_objective?.type === "required" && 'Please enter objective.'}
                                {errors.course_objective?.type !== "required" && errors.course_objective?.message}
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor={'course_level'}>Study Level</label>
                            <p>Choose the level of expertise that the student will achieve by completing this course.</p>
                            <select
                                {...register("course_level", {
                                    required: "required"
                                })}
                                className="form-control"
                                id="course_level"
                                onChange={() => {
                                    setShowDialog(true);
                                }}
                            >
                                <option value=''>Choose study level</option>
                                {
                                    levels && levels.map((level, index) =>
                                        <option key={index + 1} value={level.key}>{level.level}</option>
                                    )
                                }
                            </select>
                            <span className="text-danger">
                                {errors.course_level?.type === "required" && 'Please select level.'}
                                {errors.course_level?.type !== "required" && errors.course_level?.message}
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Upload Course Image</label>
                            <p className="text-danger">
                                {errors.course_photo?.type === "required" && 'Please select course photo.'}
                                {errors.course_photo?.type !== "required" && errors.course_photo?.message}
                            </p>
                            <p className="text-danger">{imageValidationMsg !== '' && imageValidationMsg}</p>
                            {
                                (image_up === false || image_changed)
                                &&
                                image_src === ''
                                &&
                                <>
                                    <div {...getRootProps()} className="cover-page-image cursor-pointer">
                                        <input id="ek-course-cvr-imginput" {...getInputProps()} />
                                        <a id="ek-course-cvr-img" className="profile-img"> Course Image<span><i className="fas fa-camera"></i></span> </a>
                                    </div>
                                </>
                            }

                            {
                                (image_src !== '')
                                &&
                                <>
                                    <div className="img_box">
                                        <img src={image_src} alt="course photo" />
                                        <div onClick={(e) => { removePhoto(); }} className="btnGrp">
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <ImageCropper cropbtnstatus={cropbtnstatus} photo={image_src} setPhotoToShow={setImageSrc} show_modal={prev} setPhotoToUpload={setNewPhoto} src={'Course'} />
                                </>
                            }
                        </div>
                    </div>
                    <div className="col-lg-12" style={{ display: "none" }}>
                        <div className="form-group">
                            <label htmlFor={'language_id'}>Course Language</label>
                            <p>Choose the primary language in which the course is taught.</p>
                            {/* 
                            <select {...register("language_id", { required: "required" })} className="form-control" id="language_id">
                                {
                                    languages && languages.map((language, index) =>
                                        <option key={index + 1} value={language.id}>{language.name}</option>
                                    )
                                }
                            </select>
                            */}
                            <select {...register("language_id", { required: "required" })} className="form-control" id="language_id">
                                <option value="1">English</option>
                            </select>
                            <span className="text-danger">
                                {errors.language_id?.type === "required" && 'Please select language.'}
                                {errors.language_id?.type !== "required" && errors.language_id?.message}
                            </span>
                        </div>
                    </div>
                </div>
                <button type="submit" disabled={fetching || submitting || loadingdata} className="btn btn-circle btn-md submit-pink-btn mr-2">{(fetching || submitting || loadingdata) ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : 'Continue'}</button>
                <button type="submit" disabled={fetching || submitting || loadingdata} onClick={(e) => { saveNExit(e); /*setContinue(false);*/ }} className="btn btn-circle btn-md cancel-btn">Save and Exit</button>
            </form>
        </>
    );
}

export default Step1;
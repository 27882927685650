import { useForm } from "react-hook-form";
import { API_BASE } from "../constants/global";
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { CircleBoxSkeleton } from "../components/skeletons";
const ComingSoon = () => {
    const [homePage, setHomePageContent] = useState('');
    const [search, setSearch] = useState('');
    const [isLoader, setIsLoader] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchHomePage();
        //eslint-disable-next-line
    }, []);

    const fetchHomePage = async () => {
        setIsLoader(true);
        const response = await fetch(API_BASE + 'pages/home', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });

        const data = await response.json();
        if (data.status) {
            setIsLoader(false);
            var homeData = data.data;
            setHomePageContent(homeData);
        } else {
            setIsLoader(false);
        }
    }

    const homeSearch = () => {
        if (search != "") {
            navigate('/search/' + search);
        }
    }

    return (
        <>
            {/* Banner */}
            <section className="custom-section m-0 new_home_banner">
                <div className="banner-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-10">
                                <div className="banner-info">
                                    <h2 className="coming-soon-title">A Futuristic Collaborative Learning Platform<span> Co-piloted by AI</span> is Coming Soon</h2>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* top image */}
            </section>



        </>
    );
}

export default ComingSoon;